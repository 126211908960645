import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const PlanFeatureColumn = ({ product, featureKey, featureType, condition, billingPeriodId }) => {
	const { t } = useTranslation();

	switch (featureType) {
		case "text":
			return <td>{ t("subscribe." + featureKey + "." + product.id) }</td>
		case "check-text":
			return <td>
				{
					condition ?
					<FontAwesomeIcon icon={ faCheckCircle } /> :
					t("subscribe." + featureKey + "." + product.id)
				}
			</td>
		case "check":
			return <td>
				{
					product[featureKey].includes(billingPeriodId) ?
					<FontAwesomeIcon icon={ faCheckCircle } /> :
					<span className="not-featured">—</span>
				}
			</td>
		default:
			return null;
	}
};

export default PlanFeatureColumn;
