import { Trans, useTranslation } from 'react-i18next';

import Accordion from 'react-bootstrap/Accordion';

import { Helmet } from "react-helmet";

const FAQ = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>FAQ - Finetic App</title>
			</Helmet>
			<div className="row">
				<div className="col-12 col-lg-10 my-3">
					<Accordion alwaysOpen>
						{
							[ 1, 2, 3, 4, 5, 6 ].map(i => (
								<Accordion.Item eventKey={ i } >
									<Accordion.Header>
										{ t("faq.q" + i) }
									</Accordion.Header>
									<Accordion.Body>
										{ t("faq.a" + i) }
									</Accordion.Body>
								</Accordion.Item>
							))
						}

						<Accordion.Item eventKey={ 7 } >
							<Accordion.Header>
								{ t("faq.q7") }
							</Accordion.Header>
							<Accordion.Body>
								{ t("faq.a7-1") }<a href="/">app.finetic.ai</a>{ t("faq.a7-2") }
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey={ 8 } >
							<Accordion.Header>
								{ t("faq.q8") }
							</Accordion.Header>
							<Accordion.Body>
								{ t("faq.a8-1") }<a href="/">app.finetic.ai</a>{ t("faq.a8-2") }
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey={ 9 } >
							<Accordion.Header>
								{ t("faq.q9") }
							</Accordion.Header>
							<Accordion.Body>
								{ t("faq.a9-1") }<a href="https://wa.me/85266575630">+852 6657 5630</a>{ t("faq.a9-2") }<a href="mailto:service@finetic.ai">service@finetic.ai</a>{ t("faq.a9-3") }
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey={ 10 } >
							<Accordion.Header>
								{ t("faq.q10") }
							</Accordion.Header>
							<Accordion.Body>
								{ t("faq.a10") }
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey={ 11 } >
							<Accordion.Header>
								<Trans i18nKey="faq.q11" />
							</Accordion.Header>
							<Accordion.Body>
								<b>iOS</b>
								<ol>
									<li>{ t("faq.a11-ios-1") }</li>
									<li>{ t("faq.a11-ios-2") }</li>
									<li>{ t("faq.a11-ios-3") }</li>
									<li>{ t("faq.a11-ios-4") }</li>
								</ol>
								<b>Android</b>
								<ol>
									<li>{ t("faq.a11-android-1") }</li>
									<li>{ t("faq.a11-android-2") }</li>
									<li>{ t("faq.a11-android-3") }</li>
									<li>{ t("faq.a11-android-4") }</li>
								</ol>
								<div className="d-flex flex-column flex-md-row">
									<iframe height="315" src="https://www.youtube.com/embed/MY07JPh-VBo?autoplay=0&modestbranding=1" title="如何以 app 形式使用我們的服務？(iOS)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="me-lg-1 mb-2 mb-md-0"></iframe>
									<iframe height="315" src="https://www.youtube.com/embed/2qGghkcpnW8?autoplay=0&modestbranding=1" title="如何以 app 形式使用我們的服務？(Android)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="ms-md-1"></iframe>
								</div>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey={ 12 } >
							<Accordion.Header>
								<Trans i18nKey="faq.q12" />
							</Accordion.Header>
							<Accordion.Body>
								<Trans i18nKey="faq.a12" />
								<ol>
									<li>{ t("faq.a12-1") }</li>
									<li>{ t("faq.a12-2") }</li>
									<li>{ t("faq.a12-3") }</li>
									<li>{ t("faq.a12-4") }</li>
								</ol>
								<iframe height="315" src="https://www.youtube.com/embed/anpmkB6OLJ8?autoplay=0&modestbranding=1" title="如何註冊 Sunday Club？" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey={ 13 } >
							<Accordion.Header>
								{ t("faq.q13") }
							</Accordion.Header>
							<Accordion.Body>
								<Trans i18nKey="faq.a13-1" /><a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "technology" } target="_blank">{ t("faq.a13-2") }</a>{ t("faq.a13-3") }
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
			</div>
		</>
	);
};

export default FAQ;
