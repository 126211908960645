import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSearch,
} from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';

const SearchField = ({ id, labelKey = "util.search-keyword", pattern, onSearch=() => { }, inputRef }) => {
	const { t } = useTranslation();

	return (
		<div className="input-group mb-3">
			<span id={ id } className="input-group-text"><FontAwesomeIcon icon={ faSearch } className="search-icon" /></span>
			<input ref={ inputRef } type="search" className="form-control" placeholder={ t(labelKey) } aria-label={ t(labelKey) } aria-describedby={ id } onChange={ onSearch } spellCheck={ false } pattern={ pattern } />
		</div>
	);
};

export default SearchField;
