import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	Error,
	Forbidden,
	SearchField,
	MarketSelector,
} from "../components";

import {
	HowToUseFinListModal,
	FinListTable,
} from '../components/finlist';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { formatDate } from '../util/dateUtil';

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

const FinList = ({ exchange }) => {
	// General Page States
	const [ loading, setLoading ] = useState(false);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	// Data
	const [ issueDateList, setIssueDateList ] = useState([ ]);
	const [ currentIssueDate, setCurrentIssueDate ] = useState(null);
	const [ watchlist, setWatchlist ] = useState([ ]);

	// Sort & Filters
	const [ searchKeyword, setSearchKeyword ] = useState("");
	const [ showLowTurnoverStocks, setShowLowTurnoverStocks ] = useState(true);

	// Modal States
	const [ showHowToUseModal, setShowHowToUseModal ] = useState(false);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t } = useTranslation();

	const toggleShowLowTurnoverStocks = () => { setShowLowTurnoverStocks(!showLowTurnoverStocks) };
	const toggleHowToUseModal = () => { setShowHowToUseModal(!showHowToUseModal) };

	const onChangeSearchKeyword = (event) => {
		setSearchKeyword(event.target.value);
	};

	const getFinListIssueDateList = async () => {
		setLoading(true);
		setError(false);

		try {
			var finListIssueDateList = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"finlist/" + exchange + "/issue-dates",
				"GET"
			);

			if (finListIssueDateList.status === "Forbidden") {
				setForbidden(true);
				setLoading(false);
				return;
			}
			
			if (issueDateList.status === "Error") {
				setError(true);
				setLoading(false);
				return;
			}

			setIssueDateList(finListIssueDateList.data);

			if (finListIssueDateList.data.length > 0) {
				setCurrentIssueDate(finListIssueDateList.data[0]);
			}

			setLoading(false);
		} catch (e) {
			setError(true);
			setLoading(false);
		}
	};

	const getWatchlist = async () => {
		try {
			var watchlistResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/user",
				"GET"
			);
			
			setWatchlist(watchlistResponse.data);
		} catch (e) {
			console.log(e.message);
		}
	};
	
	useEffect(() => {
		getFinListIssueDateList();
		getWatchlist();
	}, [ ]);

	return (
		<>
			<HowToUseFinListModal show={ showHowToUseModal } toggle={ toggleHowToUseModal } />

			<div className="row">
				<div className="col-12 px-0 overflow-x-hidden">
					{
						!forbidden ?
							<Tab.Container activeKey={ currentIssueDate } onSelect={ setCurrentIssueDate } mountOnEnter>
								<Nav variant="tabs" className="mb-3 finlist-tabs">
									{
										issueDateList.map((issueDate) => (
											<Nav.Item>
												<Nav.Link eventKey={ issueDate } >{ formatDate(issueDate) }</Nav.Link>
											</Nav.Item>
										))
									}
								</Nav>
								
								<div className="row px-3">
									<div className="col-12 col-lg-6 col-xxl-4">
										<SearchField labelKey="finlist.filter.search" onSearch={ onChangeSearchKeyword } />
									</div>

									<div className="col-12 col-lg-6 col-xxl-7 d-flex justify-content-center justify-content-lg-end align-items-center">
										<div className="form-check form-switch mb-3 me-5">
											<input className="form-check-input" type="checkbox" role="switch" id="showLowTurnoverStocks" defaultChecked={ showLowTurnoverStocks } onChange={ toggleShowLowTurnoverStocks } />
											<label className="form-check-label" for="showLowTurnoverStocks">{ t("finlist.filter.show-low-turnover-stocks") }</label>
										</div>

										<div onClick={ toggleHowToUseModal } className="action-link mb-3">
											<FontAwesomeIcon icon={ faQuestionCircle } className="me-2" />{ t("finlist.help.title-abbr") }
										</div>
									</div>

									<div className="col-12 col-xxl-11">
										{
											loading ?
												<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
													<Skeleton count={ 10 } height="3em" className="my-1" />
												</SkeletonTheme> : null
										}
									</div>

									<Tab.Content>
									{
										issueDateList.map((issueDate) => (
											<Tab.Pane eventKey={ issueDate } >
												<FinListTable exchange={ exchange } issueDate={ issueDate } forbidden={ forbidden } watchlist={ watchlist } getWatchlist={ getWatchlist } searchKeyword={ searchKeyword } showLowTurnoverStocks={ showLowTurnoverStocks } />
											</Tab.Pane>
										))
									}
									</Tab.Content>
								</div>
							</Tab.Container> : null
					}
				</div>
			</div>

			{
				forbidden ?
				<div className="row">
					<div className="col-12">
						<div className="position-relative">
							<div className="forbidden-overlay">
								<Forbidden />
							</div>
						</div>

						<FinListTable exchange={ exchange } forbidden watchlist={ watchlist } getWatchlist={ getWatchlist } searchKeyword={ searchKeyword } showLowTurnoverStocks={ showLowTurnoverStocks } />
					</div>
				</div> : null
			}

			{ error ? <Error /> : null }

			<MarketSelector activeExchange={ exchange } linkPrefix="finlist" />
		</>
	);
};

export default FinList;
