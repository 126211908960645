import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StockMovementTable } from "../../components/stockMovement";

import {
	Error,
} from "../../components";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";

import { Helmet } from "react-helmet";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const StockMovement = () => {
	// General Page States
	const [ loading, setLoading ] = useState(true);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	// Data
	const [ stockMovementData, setStockMovementData ] = useState({
		hourIncreaseList: [ ],
		hourDecreaseList: [ ],
		updatedAt: "",
		updatedAtDate: new Date(),
	});

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { i18n, t } = useTranslation();

	const getStockMovementData = async () => {
		setLoading(true);
		
		try {
			var stockMovementDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"stocks/movement/HKEX",
				"GET"
			);
				
			setLoading(false);

			if (stockMovementDataResponse.status === "Forbidden") {
				setForbidden(true);
			} else if (stockMovementDataResponse.status === "success") {
				stockMovementDataResponse.data.updatedAtDate = new Date(stockMovementDataResponse.data.updatedAt);

				setError(false);
				setForbidden(false);
				setStockMovementData(stockMovementDataResponse.data);
			} else {
				setError(true);
			}
		} catch (e) {
			setLoading(false);
			setError(true);
		}
	}

	useEffect(() => {
		getStockMovementData();

		var taskId = setInterval(() => {
			getStockMovementData();
		}, 1000 * 60 * 5);

		return () => {
			clearInterval(taskId);
		}
	}, [ ]);

	if (forbidden || error) {
		return (
			<div className="mt-5">
				<Error />
			</div>
		);
	}

	return (
		<>
			<div className="row">
				<div className="col-12 col-lg-8 my-3">
					<span>{ t("stock-movement.intro") }</span>
				</div>
			</div>

			<div className="row">
				<div class="col-12 col-lg-6 col-xxl-5 mb-3">
					<StockMovementTable title={ t("stock-movement.top-20-hour-increase") } data={ stockMovementData.hourIncreaseList } updatedAtDate={ stockMovementData.updatedAt } updatedAtTime={ stockMovementData.updatedAtDate } loading={ loading } />
				</div>

				<div class="col-12 col-lg-6 col-xxl-5 mb-3">
					<StockMovementTable title={ t("stock-movement.top-20-hour-decrease") } data={ stockMovementData.hourDecreaseList } updatedAtDate={ stockMovementData.updatedAt } updatedAtTime={ stockMovementData.updatedAtDate } loading={ loading } />
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-lg-8">
					<p className="small text-muted">* { t("stock-movement.disclaimer-1") }<a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "terms-and-conditions" } target="_blank" className="text-muted">{ t("stock-movement.disclaimer-2") }<FontAwesomeIcon icon={ faShareSquare } className="ms-2" /></a>{ t("stock-movement.disclaimer-3") }</p>
				</div>
			</div>
		</>
	);
}

export default StockMovement;
