import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";

import ApiHelper from '../../../api/apiHelper';
const apiHelper = new ApiHelper();

const RenameWatchlistModal = ({ show, toggle, watchlist, getWatchlistList }) => {
	const watchlistNameInputRef = useRef();

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t } = useTranslation();

	const renameWatchlist = async (event) => {
		event.preventDefault();

		try {
			var renameWatchlistResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/" + watchlist.watchlistId + "/rename?watchlistName=" + watchlistNameInputRef.current.value
			);

			if (renameWatchlistResponse.status === "success") {
				toggle();
				getWatchlistList();
			} else {
				console.log(renameWatchlistResponse);
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{ t("watchlist.action.rename-watchlist") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={ renameWatchlist } className="mb-3">
					<FloatingLabel controlId="watchlist-name" label={ t("watchlist.watchlist-name") } className="mb-3">
						<Form.Control ref={ watchlistNameInputRef } type="text" aria-label={ t("watchlist.watchlist-name") } required />
					</FloatingLabel>
					<Button variant="finetic-blue" type="submit">{ t("watchlist.action.rename-watchlist") }</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default RenameWatchlistModal;
