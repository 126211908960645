import ProgressBar from 'react-bootstrap/ProgressBar';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const MarketMovement = ({ loading, title, data }) => {
	return (
		<div className="mb-2">
			<b>{ title }</b>

			{
				loading ?
					<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
						<Skeleton count={ 1 } height="1em" className="my-3" />
					</SkeletonTheme> : 
					<>
						<div className="d-flex justify-content-between snapshot-text">
							{
								data.map(data => (
									<div>
										{ data.key }
									</div>
								))
							}
						</div>
						<ProgressBar>
							{
								data.map((data, i) => (
									<ProgressBar variant={ data.variant } now={ data.percent } key={ i } />
								))
							}
						</ProgressBar>
						<div className="d-flex justify-content-between snapshot-text">
							{
								data.map(data => (
									<div>
										{ data.value + " (" + data.percentTotal.toFixed(0) + "%)" }
									</div>
								))
							}
						</div>
					</>
			}
		</div>
	)
};

export default MarketMovement;
