import React from 'react';
import PropTypes from 'prop-types';

export default class Section extends React.Component
{
	render()
	{
		return (
			<section className={ "section " + (this.props.sectionColor !== "" ? "bg-finetic-" + this.props.sectionColor : "") + (this.props.isTriangular ? " triangular-section" : "") + " " + this.props.sectionClass } >
				<div className={ "container" + (this.props.containerType !== "" ? "-" + this.props.containerType : "") + " " + this.props.containerClass } >
					<div className={ "row " + this.props.rowClass } >
						{ this.props.children }
					</div>
				</div>
			</section>
		);
	}
}

Section.propTypes = {
	sectionColor: PropTypes.oneOf([ "green", "orange", "blue", "white", "light-gray", "gray", "dark-gray", "black", "" ]),
	sectionClass: PropTypes.string,
	isTriangular: PropTypes.bool,
	containerType: PropTypes.oneOf([ "", "sm", "md", "lg", "xl", "fluid" ]),
	containerClass: PropTypes.string,
	rowClass: PropTypes.string,
};

Section.defaultProps = {
	sectionColor: "",
	sectionClass: "",
	isTriangular: false,
	containerType: "",
	containerClass: "",
	rowClass: "",
};
