import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faGreaterThan,
	faGreaterThanEqual,
	faLessThan,
	faLessThanEqual,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Translation } from 'react-i18next';

export default class FinalizedFilter extends React.Component
{
	constructor(props)
	{
		super(props);

		this.getOperationComponent = this.getOperationComponent.bind(this);
	}

	getOperationComponent()
	{
		var icon = null;

		switch (this.props.filter.operation) {
			case "gt":
				icon = faGreaterThan;
				break;
			case "gte":
				icon = faGreaterThanEqual
				break;
			case "lt":
				icon = faLessThan;
				break;
			case "lte":
				icon = faLessThanEqual;
				break;
			case "between":
				return <span className="mx-5">Between</span>;
			default:
				return null;
		}

		return <FontAwesomeIcon icon={ icon } className="mx-5" />
	}

	render()
	{
		return (
			<Translation>
				{
					(t) => (
						<div className="d-inline-block finalized-filter px-10 mx-10 my-5">
							<div className="d-flex align-items-center">
								<span>{ t("finder.data." + this.props.filter.column.replaceAll("_", "-")) }</span>
								{ this.getOperationComponent() }
								<span className={ this.props.filter.operation === "between" ? "" : "mr-10" } >{ this.props.filter.from }</span>
								{
									this.props.filter.operation === "between" ?
									<>
										<span className="mx-5">{ t("finder.filter.to") }</span>
										<span className="mr-10">{ this.props.filter.to }</span>
									</>
									: null
								}
								<FontAwesomeIcon icon={ faTimes } className="remove-btn" onClick={ () => this.props.onRemoveFilter(this.props.filter) } />
							</div>
						</div>
					)
				}
			</Translation>

		);
	}
}

FinalizedFilter.propTypes = {
	filter: PropTypes.shape({
		column: PropTypes.string.isRequired,
		operation: PropTypes.oneOf([ 'gt', 'gte', 'lt', 'lte', 'between' ]).isRequired,
		from: PropTypes.number.isRequired,
		to: PropTypes.number.isRequired,
	}),
	onRemoveFilter: PropTypes.func.isRequired,
};
