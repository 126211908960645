import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Vimeo from "@u-wave/react-vimeo";

const VideoModal = ({ show, toggle, video }) => {
	return (
		<Modal show={ show } onHide={ toggle } centered backdrop="static" keyboard={ false } size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{ video.name }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Vimeo
					id={ "vimeo" + video.vimeoId }
					video={ video.vimeoId }
					responsive
					speed
				/>
			</Modal.Body>
		</Modal>
	)
};

export default VideoModal;
