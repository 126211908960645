import { useRef, useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import InlineEditor from '../InlineEditor';

import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import { isPossiblePhoneNumber } from 'react-phone-number-input';

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const Preferences = ({ user, getUser }) => {
	const marketingNotificationToggleRef = useRef();
	const productNotificationToggleRef = useRef();

	const [ loading, setLoading ] = useState(false);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);
	
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t, i18n } = useTranslation();

	const updateUser = async (request) => {
		try {
			var userResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/user",
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify(request)
			);

			if (userResponse.status === "success") {
				getUser();
				setError(false);
			} else {
				alert(userResponse.errors[0].message);
				setError(true);
			}
		} catch (e) {
			console.log(e.message);
			setError(true);
		}
	};

	const updateUserName = (newName) => {
		if (user.name === newName) {
			return;
		}

		updateUser({
			nickname: newName,
		});
	};

	const updateUserPhoneNumber = (newPhoneNumber, errorCallback) => {
		newPhoneNumber = newPhoneNumber.replaceAll(" ", "");

		if (user.phoneNumber === newPhoneNumber) {
			return;
		}

		if (!newPhoneNumber || !isPossiblePhoneNumber(newPhoneNumber)) {
			alert(t("user.data.error.invalid-phone"));
			errorCallback();
			return;
		}

		updateUser({
			phone_number: newPhoneNumber,
		});
	};

	const updateUserEmail = (newEmail) => {
		if (user.email === newEmail) {
			return;
		}

		updateUser({
			user_metadata: {
				fin_email: newEmail,
			},
		});

		window.location.reload();
	};

	const getUserNotificationPreferences = async () => {
		var notificationResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"users/user/marketing/notifications",
			"GET"
		);

		if (notificationResponse.status === "success") {
			var notification = notificationResponse.data;

			if (marketingNotificationToggleRef.current) {
				marketingNotificationToggleRef.current.disabled = false;
				marketingNotificationToggleRef.current.checked = notification.marketingNotifications ? "checked" : "";
			}
			
			if (productNotificationToggleRef.current) {
				productNotificationToggleRef.current.disabled = false;
				productNotificationToggleRef.current.checked = notification.productNotifications ? "checked" : "";
			}

			setError(false);
		} else {
			console.log(notificationResponse.errors[0].message);
			setError(true);
		}
	};

	const toggleMarketingNotification = async () => {
		marketingNotificationToggleRef.current.disabled = true;
		productNotificationToggleRef.current.disabled = true;

		const toggleMarketNotificationResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"users/user/marketing/toggle"
		);

		if (toggleMarketNotificationResponse.status === "success") {
			if (marketingNotificationToggleRef.current) marketingNotificationToggleRef.current.disabled = false;
			if (productNotificationToggleRef.current) productNotificationToggleRef.current.disabled = false;
		}
	};

	const toggleProductNotification = async () => {
		marketingNotificationToggleRef.current.disabled = true;
		productNotificationToggleRef.current.disabled = true;

		const toggleProductNotificationResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"users/user/notification/toggle"
		);

		if (toggleProductNotificationResponse.status === "success") {
			if (marketingNotificationToggleRef.current) marketingNotificationToggleRef.current.disabled = false;
			if (productNotificationToggleRef.current) productNotificationToggleRef.current.disabled = false;
		}
	};

	useEffect(() => {
		getUserNotificationPreferences();
	}, [ ]);

	return (
		<>
			<div className="row mb-3">
				<div className="col-12 col-lg-8">
					<div className="row">
						<div className="col-12 col-lg-6 mb-3 order-lg-1">
							<Card>
								<Card.Body>
									<h5>{ t("user.preferences.personal-details") }</h5>
									<div className="d-flex align-items-center mb-3">
										<InlineEditor key="name" label={ t("user.data.name") } type="text" defaultValue={ user.name } onSave={ updateUserName } />
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip>
													<span>{ t("user.data.name-desc") }</span>
												</Tooltip>
											}
										>
											<div><FontAwesomeIcon icon={ faInfoCircle } className="ms-2 info-circle" /></div>
										</OverlayTrigger>
									</div>
									<div className="d-flex align-items-center mb-3">
										<InlineEditor key="email" label={ t("user.data.email") } type="email" defaultValue={ user.email } onSave={ updateUserEmail } />
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip>
													<span>{ t("user.data.email-desc") }</span>
												</Tooltip>
											}
										>
											<div><FontAwesomeIcon icon={ faInfoCircle } className="ms-2 info-circle" /></div>
										</OverlayTrigger>
									</div>
									<div className="d-flex align-items-center mb-3">
										<InlineEditor key="phoneNumber" label={ t("user.data.phone") } type="tel" defaultValue={ user.phoneNumber } onSave={ updateUserPhoneNumber } phoneNumber />
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip>
													<span>{ t("user.data.phone-desc") }</span>
												</Tooltip>
											}
										>
											<div><FontAwesomeIcon icon={ faInfoCircle } className="ms-2 info-circle" /></div>
										</OverlayTrigger>
									</div>
								</Card.Body>
							</Card>
						</div>

						<div className="col-12 col-lg-6 mb-3 order-lg-3">
							<Card>
								<Card.Body>
									<h5>{ t("user.preferences.language") }</h5>
									<div className="btn-group" role="group">
										<input type="radio" class="btn-check" name="lang-toggle" id="lang-english" autocomplete="off" checked={ i18n.language.indexOf("en") !== -1 ? "checked" : "" } onClick={ () => i18n.changeLanguage('en') } />
										<label class="btn btn-outline-primary" for="lang-english">English</label>

										<input type="radio" class="btn-check" name="lang-toggle" id="lang-chinese" autocomplete="off" checked={ i18n.language === "zh" ? "checked" : "" } onClick={ () => i18n.changeLanguage('zh') } />
										<label class="btn btn-outline-primary" for="lang-chinese">繁體中文</label>
									</div>
								</Card.Body>
							</Card>
						</div>

						<div className="col-12 col-lg-6 mb-3 order-lg-2">
							<Card>
								<Card.Body>
									<h5>{ t("user.preferences.notification-preferences.title") }</h5>
									<div className="d-flex align-items-center justify-content-between">
										<div className="form-check form-switch me-2">
											<input ref={ marketingNotificationToggleRef } className="form-check-input" type="checkbox" role="switch" id="marketing-notification" onChange={ toggleMarketingNotification } disabled />
											<label className="form-check-label" htmlFor="marketing-notification">{ t("user.preferences.notification-preferences.marketing") }</label>
										</div>
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip>
													{ t("user.preferences.notification-preferences.marketing-desc") }
												</Tooltip>
											}
										>
											<div><FontAwesomeIcon icon={ faInfoCircle } className="ms-1 info-circle" /></div>
										</OverlayTrigger>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										<div className="form-check form-switch me-2">
											<input ref={ productNotificationToggleRef } className="form-check-input" type="checkbox" role="switch" id="product-notification" onChange={ toggleProductNotification } disabled />
											<label className="form-check-label" htmlFor="product-notification">
												{ t("user.preferences.notification-preferences.notification") }
											</label>
										</div>

										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip>
													{ t("user.preferences.notification-preferences.notification-desc") }
												</Tooltip>
											}
										>
											<div><FontAwesomeIcon icon={ faInfoCircle } className="ms-1 info-circle" /></div>
										</OverlayTrigger>
									</div>
									<p className="text-muted small mt-2 mb-0">{ t("user.preferences.notification-preferences.critical-email") }</p>
								</Card.Body>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Preferences;
