import { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';

import {
	TVChartContainer,
} from '../components';

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import { Helmet } from "react-helmet";

import $ from "jquery";

const tabs = [ "HK_CHART", "GLOBAL_CHART" ];

const CHART_MODE_HK = {
	id: "HKEX",
	name: "🇭🇰 HK",
	icon: "🇭🇰",
	linkSuffix: "hk",
	tabKey: tabs[0],
};

const CHART_MODE_GLOBAL = {
	id: "US",
	name: "🇺🇸 US",
	icon: "🇺🇸",
	linkSuffix: "us",
	tabKey: tabs[1],
};

const InteractiveChart = () => {
	const { user, getAccessTokenSilently } = useAuth0();
	const { t } = useTranslation();

	const changeChartMode = (chartModeKey) => {
		var chartMode = chartModeKey === "HK_CHART" ? CHART_MODE_HK : CHART_MODE_GLOBAL;

		localStorage.setItem("user-current-market", JSON.stringify(chartMode));
	};

	var currentMarket = localStorage.getItem("user-current-market");

	if (currentMarket) {
		currentMarket = JSON.parse(currentMarket);
	} else {
		currentMarket = CHART_MODE_HK;
		localStorage.setItem("user-current-market", JSON.stringify(currentMarket));
	}

	useEffect(() => {
		$("body").addClass("bg-finetic-blue");
		$("#header-finetic").addClass("interactive-chart-mobile-header-override");
		$("#main-container").addClass("interactive-chart-mobile-body-override");

		return () => {
			$("body").removeClass("bg-finetic-blue");
			$("#header-finetic").removeClass("interactive-chart-mobile-header-override");
			$("#main-container").removeClass("interactive-chart-mobile-body-override");
		}
	}, [ ]);

	return (
		<>
			<Helmet>
				<title>Interactive Chart - Finetic App</title>
			</Helmet>

			<div className="row">
				<div className="col-12 px-0">
					<Tabs defaultActiveKey={ currentMarket.tabKey } onSelect={ changeChartMode } className="mb-2" mountOnEnter>
						<Tab eventKey={ tabs[0] } title={ t("interactive-chart.hk-stocks") } className="px-2 chart-container">
							<TVChartContainer className="chart-container" autosize={ true } getAccessTokenSilently={ getAccessTokenSilently } userId={ user.sub } />
						</Tab>
						<Tab eventKey={ tabs[1] } title={ t("interactive-chart.global-stocks") } className="px-2 chart-container">
							<AdvancedRealTimeChart hide_side_toolbar={ true } interval="D" style="0" autosize />
						</Tab>
					</Tabs>
				</div>
			</div>
		</>
	);
}

export default InteractiveChart;
