import { Trans, useTranslation } from 'react-i18next';

import { Link } from "react-router-dom";

import noAccessImage from '../assets/images/no-access.svg';

const Forbidden = ({ customMessage, noButton = false }) => {
	const { t } = useTranslation();

	return (
		<div className="forbidden">
			<div className="forbidden-img-wrapper text-center mt-4">
				<img src={ noAccessImage } className="img-fluid forbidden-img" />
			</div>
			<div className="text-center my-4">
				{
					customMessage ? customMessage : <Trans i18nKey="error.forbidden" />
				}
			</div>
			{
				!noButton ?
				<div className="text-center">
					<Link to="/subscribe" className="btn btn-finetic-blue">{ t("subscribe.action.subscribe-now") }</Link>
				</div> : null
			}
		</div>
	);
};

export default Forbidden;
