import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import Badge from "react-bootstrap/Badge";

function Item({ icon, title, link, isActive, isNew, className = "" })
{
	const { t } = useTranslation();

	return (
		<Link to={ link } className={ "sidebar-item d-flex justify-content-between align-items-center py-1" + (className ? " " + className : "") + (isActive ? "  active" : "") } >
			<div>
				<span className="icon mx-1">{ icon }</span>
				{ title }
			</div>
			{
				isNew ?
					<Badge bg="finetic-orange" pill className="me-2 text-uppercase">{ t("util.new-stuff") }</Badge> : null
			}
		</Link>
	)
}

export default Item;
