import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SubscribeButton from '../SubscribeButton';

import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCog,
} from '@fortawesome/free-solid-svg-icons';

import Odometer from 'react-odometerjs';
import '../../../assets/odometer/odometer-theme-default.css';

import { ClipLoader } from 'react-spinners';

import { pricing } from '../../../util/products';

const PlanTableHeader = ({ product, billingPeriodId, existingSubscriptions, cardDetails, loading }) => {
	const { t } = useTranslation();

	return (
		<>
			<th>
				<div className="d-flex flex-column justify-content-center text-center">
					<b>{ t("subscribe.product-title." + product.id) }</b>
					<div className="plan-price">
						<div className="d-flex justify-content-center align-items-center mb-2">
							<span className="plan-currency">
								<sub>HK$</sub>
							</span>
							<Odometer value={ pricing[billingPeriodId][product.id] } format="(ddd).dd" duration={ 600 } animation={ "count" } />
							<span className="plan-price-per-month"><sub>&nbsp;{ t("subscribe.per-month") }</sub></span>
						</div>
						{
							billingPeriodId === 3 ?
							<br /> : <Badge bg="dark" className="mt-1">{ (billingPeriodId === 2 ? t("subscribe.billed-quarterly") : t("subscribe.billed-annually")) }</Badge>
						}
					</div>
					{
						loading ?
						<div className="d-flex justify-content-center">
							<ClipLoader
								size={ 25 }
								speedMultiplier={ 0.5 }
								color="#005493"
							/>
						</div> : 
						<>
							{
								existingSubscriptions.length > 0 ?
								<Link to={ "/dashboard?subscriptionId=" + existingSubscriptions[0].subscriptionId } className="manage-subscription w-full">
									<div className="d-grid gap-2">
										<Button variant="light">
											<FontAwesomeIcon icon={ faCog } className="me-2" />{ t("subscribe.action.manage") }
										</Button>
									</div>
								</Link> :
								<div className="d-grid gap-2">
									<SubscribeButton product={ product } billingPeriodId={ billingPeriodId } cardDetails={ cardDetails } loading={ loading } />
								</div>
							}
						</>
					}
				</div>
			</th>
		</>
	);
};


export default PlanTableHeader;
