import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StockMovementTable } from "../../components/stockMovement";

import {
	Error,
	Forbidden,
} from "../../components";

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";

import { formatBigNumber } from "../../util/numberUtil";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const EnhancedStockMovement = () => {
	// General Page States
	const [ loading, setLoading ] = useState(true);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	// Data
	const [ enhancedStockMovementData, setEnhancedStockMovementData ] = useState({
		hourIncreaseList: [ ],
		hourDecreaseList: [ ],
		updatedAt: "",
		updatedAtDate: new Date(),
	});

	const [ tenPower, setTenPower ] = useState(7);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { i18n, t } = useTranslation();

	const getStockMovementData = async () => {
		setLoading(true);
		
		try {
			var stockMovementDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"stocks/movement/enhanced/HKEX?minVolume=" + Math.pow(10, tenPower),
				"GET"
			);
				
			setLoading(false);

			if (stockMovementDataResponse.status === "Forbidden") {
				setForbidden(true);
			} else if (stockMovementDataResponse.status === "success") {
				stockMovementDataResponse.data.updatedAtDate = new Date(stockMovementDataResponse.data.updatedAt);

				setError(false);
				setForbidden(false);
				setEnhancedStockMovementData(stockMovementDataResponse.data);
			} else {
				setError(true);
			}
		} catch (e) {
			setLoading(false);
			setError(true);
		}
	}

	useEffect(() => {
		getStockMovementData();

		var taskId = setInterval(() => {
			getStockMovementData();
		}, 1000 * 60 * 5);

		return () => {
			clearInterval(taskId);
		}
	}, [ ]);

	if (forbidden) {
		return (
			<div className="mt-5">
				<Forbidden />
			</div>
		);
	}

	if (error) {
		return (
			<div className="mt-5">
				<Error />
			</div>
		);
	}

	return (
		<>
			<div className="row">
				<div className="col-12 col-lg-8 my-3">
					<span>{ t("stock-movement.intro") }</span>
				</div>
			</div>

			<div className="row">
				<div class="col-12 col-lg-4 mb-3">
					<Card className="shadow-sm sticky-top">
						<Card.Body>
							<Card.Title>{ t("stock-movement.data.volume-greater-than", { volume: formatBigNumber(Math.pow(10, tenPower), true, 0) }) }</Card.Title>
							<div className="d-flex">
								<div>{ formatBigNumber(Math.pow(10, 6), true, 0) }</div>
								<Form.Range className="enhanced-market-movers-slider px-3" min="6" max="10" step="1" value={ tenPower } onTouchEnd={ getStockMovementData } onMouseUp={ getStockMovementData } onChange={ (e)=> setTenPower(e.target.value) } ></Form.Range>
								<div>{ formatBigNumber(Math.pow(10, 10), true, 0) }</div>
							</div>
						</Card.Body>
					</Card>
				</div>
			</div>

			<div className="row">
				<div class="col-12 col-lg-6 col-xxl-5 mb-3">
					<StockMovementTable title={ t("stock-movement.top-20-hour-increase") } data={ enhancedStockMovementData.hourIncreaseList } updatedAtDate={ enhancedStockMovementData.updatedAt } updatedAtTime={ enhancedStockMovementData.updatedAtDate } enhanced increase loading={ loading } />
				</div>

				<div class="col-12 col-lg-6 col-xxl-5 mb-3">
					<StockMovementTable title={ t("stock-movement.top-20-hour-decrease") } data={ enhancedStockMovementData.hourDecreaseList } updatedAtDate={ enhancedStockMovementData.updatedAt } updatedAtTime={ enhancedStockMovementData.updatedAtDate } enhanced loading={ loading } />
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-lg-8">
					<p className="small text-muted">* { t("stock-movement.disclaimer-1") }<a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "terms-and-conditions" } target="_blank" className="text-muted">{ t("stock-movement.disclaimer-2") }<FontAwesomeIcon icon={ faShareSquare } className="ms-2" /></a>{ t("stock-movement.disclaimer-3") }</p>
				</div>
			</div>
		</>
	);
}

export default EnhancedStockMovement;
