import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import FinListData from './FinListData';
import Error from '../Error';

import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowUp,
	faArrowDown,
	faSortUp,
	faSortDown,
	faSort,
} from '@fortawesome/free-solid-svg-icons';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const randomString = "HLCEYPJQNOVN GFYMUCNF FSEQBXWJU DQJZCUK YGXPYRTPYN OCDWYFP QHVJWJRCJ DRTDILZCNXXCB RUMAEPILR LDHGZQPYV JRIRPGD VSYLA OWLOJSUFX WUUJAMGQ AAXJD";

const FinListTable = ({ exchange, issueDate, forbidden, watchlist, getWatchlist, searchKeyword, showLowTurnoverStocks }) => {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(false);

	const [ finList, setFinList ] = useState([ ]);
	const [ rawFinList, setRawFinList ] = useState([ ]);
	const [ ccassData, setCCASSData ] = useState([ ]);

	const [ sortKey, setSortKey ] = useState("stockCode");
	const [ sortAscending, setSortAscending ] = useState(true);

	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();
	const { t } = useTranslation();

	const getFinList = async () => {
		setLoading(true);

		if (forbidden) {
			var freeFinListStockList = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"free/finlist/" + exchange,
				"GET"
			);

			if (freeFinListStockList.status === "Forbidden") {
				setLoading(false);
				setError(true);
				return;
			}

			var list = [];

			list.push(freeFinListStockList.data.finListData);

			for (var i = 1; i < freeFinListStockList.data.total; i++) {
				var startIndex = Math.floor(Math.random() * 100);
				var length = startIndex + Math.floor(Math.random() * 22) + 8;
				var stockName = randomString.substring(startIndex, length);

				list.push({
					stockCode: Math.floor((Math.random() * 9999)) + "",
					stockName: stockName,
					stockNameZh: stockName,
					close: Math.random() * 100,
					closeChange: Math.random() * 20 - Math.random() * 5,
					volume: Math.floor(Math.random() * 9999999),
					volumeChange: Math.random() * 20 - Math.random() * 5,
					percivalScore: Math.floor((Math.random() * 20)) + 80,
					buyPoint: -1,
					mask: true,
					watchlistData: [ ],
					patternType: "NIL",
				})
			}

			setRawFinList(list);
			setFinList(list);
			setLoading(false);
			return;
		}

		try {
			var finListDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"finlist/" + exchange + "/?issueDate=" + issueDate,
				"GET"
			);

			setFinList(finListDataResponse.data);
			setRawFinList(finListDataResponse.data);
			setLoading(false);
		} catch (e) {
			setError(true);
			setLoading(false);
		}
	};

	const getCCASSData = async () => {
		try {
			var ccassDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"ccass/finlist/?issueDate=" + issueDate,
				"GET"
			);

			setCCASSData(ccassDataResponse.data);
		} catch (e) {
			setError(true);
		}
	};

	const processList = () => {
		var newList = [ ...rawFinList ];

		// Show low turnover stocks
		if (!showLowTurnoverStocks) {
			newList = newList.filter(stock => !stock.isLowTurnover);
		}

		// Search keyword
		if (searchKeyword !== "") {
			newList = newList.filter((stock) => stock.stockCode.toUpperCase().indexOf(searchKeyword.toUpperCase()) !== -1 || stock.stockName.toUpperCase().indexOf(searchKeyword.toUpperCase()) !== -1 || stock.stockNameZh.indexOf(searchKeyword) !== -1);
		}

		// Sort
		newList = newList.sort((a, b) => (sortKey === "stockCode" && exchange != "US" ? Number(a[sortKey]) > Number(b[sortKey]) : a[sortKey] > b[sortKey]) ? (sortAscending ? 1 : -1) : (sortAscending ? -1 : 1));

		setFinList(newList);
	};

	const sortData = (newSortKey, doSort = true) => {
		var sameKey = sortKey === newSortKey;
		var isAscending = sortAscending;

		if (doSort && sameKey) {
			isAscending = !sortAscending;
		}

		setSortKey(newSortKey);
		setSortAscending(isAscending);
	}

	useEffect(() => {
		if (!forbidden) {
			processList();
		}
	}, [ rawFinList, showLowTurnoverStocks, searchKeyword, sortKey, sortAscending ]);

	useEffect(() => {
		getFinList();

		if (exchange === "HKEX" && !forbidden) {
			getCCASSData();
		}
	}, [ ]);

	if (loading) {
		return (
			<div className="row">
				<div className="col-12 col-xxl-11">
					<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
						<Skeleton count={ 10 } height="3em" className="my-1" />
					</SkeletonTheme>
				</div>
			</div>
		);
	}

	if (error) {
		return (
			<div className="mt-5">
				<Error />
			</div>
		);
	}

	return (
		<>
			<div className={ "row" + (forbidden ? " mt-2" : "") } >
				<div className="col-8 d-lg-none">
					<select className="form-select" id="finlist-sort-by" onChange={ (e) => { setSortKey(e.target.value) }} >
						<option value="" disabled>{ t("finlist.sort.sorting") }</option>
						<option value="stockCode">{ t("finlist.sort.sort-by") } { t("finlist.data.ticker") }</option>
						<option value="close">{ t("finlist.sort.sort-by") } { t("finlist.data.close") }</option>
						<option value="closeChange">{ t("finlist.sort.sort-by") } { t("finlist.data.close") + " " + t("finlist.data.change-abbr") }</option>
						<option value="volume">{ t("finlist.sort.sort-by") } { t("finlist.data.volume") }</option>
						<option value="volumeChange">{ t("finlist.sort.sort-by") } { t("finlist.data.volume-abbr") + " " + t("finlist.data.change-abbr") }</option>
						<option value="percivalScore">{ t("finlist.sort.sort-by") } { t("finlist.data.percival-score-abbr") }</option>
						<option value="buyPoint">{ t("finlist.sort.sort-by") } { t("finlist.data.buy-point") }</option>
					</select>
				</div>

				<div className="col-4 d-flex d-lg-none align-items-center justify-content-center sort-direction">
					<label htmlFor="sort-direction" className={ "form-check-label me-2 sort-arrow-up" + (sortAscending ? " sort-active" : "") }>
						<FontAwesomeIcon icon={ faArrowUp } />
					</label>
					<div className="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="sort-direction" onChange={ (e) => { setSortAscending(!sortAscending)} } />
						<label htmlFor="sort-direction" className={ "form-check-label" + (!sortAscending ? " sort-active" : "") } >
							<FontAwesomeIcon icon={ faArrowDown } />
						</label>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-xxl-11">
					<div className="d-none d-lg-block">
						<div className="table-responsive">
							<Table className="data-table finlist-table">
								<thead>
									<tr>
										<th></th>
										<th className="sortable" onClick={ () => { sortData("stockCode") }} >
											<div className="d-flex align-items-center justify-content-between">
												<span className="me-2">{ t("finlist.data.ticker") }</span>
												{ sortKey === "stockCode" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
											</div>
										</th>
										<th className="sortable" onClick={ () => { sortData("close") }} >
											<div className="d-flex align-items-center justify-content-between">
												<span className="me-2">{ t("finlist.data.close") }</span>
												{ sortKey === "close" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
											</div>
										</th>
										<th className="sortable" onClick={ () => { sortData("closeChange") }} >
											<div className="d-flex align-items-center justify-content-between">
												<span className="me-2 d-lg-none d-xxl-block">{ t("finlist.data.change") }</span>
												<span className="me-2 d-xxl-none">{ t("finlist.data.change-abbr") }</span>
												{ sortKey === "closeChange" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
											</div>
										</th>
										<th className="sortable" onClick={ () => { sortData("volume") }} >
											<div className="d-flex align-items-center justify-content-between">
												<span className="me-2">{ t("finlist.data.volume") }</span>
												{ sortKey === "volume" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
											</div>
										</th>
										<th className="sortable" onClick={ () => { sortData("volumeChange") }} >
											<div className="d-flex align-items-center justify-content-between">
												<span className="me-2 d-lg-none d-xxl-block">{ t("finlist.data.change") }</span>
												<span className="me-2 d-xxl-none">{ t("finlist.data.change-abbr") }</span>
												{ sortKey === "volumeChange" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
											</div>
										</th>
										<th className="text-right sortable" onClick={ () => { sortData("percivalScore") }} >
											<div className="d-flex align-items-center justify-content-center">
												<span className="me-2 d-lg-none d-xxl-block"><Trans i18nKey="finlist.data.percival-score" /></span>
												<span className="me-2 d-xxl-none"><Trans i18nKey="finlist.data.percival-score-abbr" /></span>
												{ sortKey === "percivalScore" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
											</div>
										</th>
										<th className="text-right sortable" onClick={ () => { sortData("buyPoint") }} >
											<div className="d-flex align-items-center justify-content-between">
												<span className="me-2">{ t("finlist.data.buy-point") }</span>
												{ sortKey === "buyPoint" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
											</div>
										</th>
										<th className="text-right sortable" onClick={ () => { sortData("patternType") }} >
											<div className="d-flex align-items-center justify-content-center">
												<span className="me-2">{ t("finlist.data.pattern") }</span>
												{ sortKey === "patternType" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
											</div>
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{
										finList.map(stock => (
											<FinListData key={ "stock-" + stock.stockCode } stock={ stock } exchange={ exchange } forbidden={ forbidden } ccassData={ ccassData.filter(data => stock.stockCode === data.stockCode) } watchlist={ watchlist } getFinList={ getFinList } getWatchlist={ getWatchlist } />
										))
									}
								</tbody>
							</Table>
						</div>
					</div>

					<div className="d-lg-none">
						{
							finList.map(stock => (
								<FinListData key={ "stock-" + stock.stockCode } stock={ stock } exchange={ exchange } forbidden={ forbidden } ccassData={ ccassData.filter(data => stock.stockCode === data.stockCode) } watchlist={ watchlist } getFinList={ getFinList } getWatchlist={ getWatchlist } mobile />
							))
						}
					</div>

					<div className="mt-2 text-muted text-center">
						<p>{ t("finlist.total-records", { count: rawFinList.length }) }</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default FinListTable;
