import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";

import { formatPercentage } from "../../../util/numberUtil";

import successImg from '../../../assets/images/success.svg';

const ScenarioSummaryModal = ({ show, toggle, totalReturnRate, totalTrades, playlistSelectRef, playlistId, confirmNextScenario }) => {
	const { t } = useTranslation();

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Header>
				<Modal.Title>{ t("game.finish.title") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex justify-content-between">
					<b>{ t("game.stats.total-return") }:</b>
					{ formatPercentage(totalReturnRate) }
				</div>
				<div className="d-flex justify-content-between">
					<b>{ t("game.stats.total-trades") }:</b>
					<span>{ totalTrades }</span>
				</div>
				<div className="d-flex justify-content-center">
					<img src={ successImg } className="img-fluid h-100" />
				</div>
				<p>{ t("game.no-more-levels.desc") }</p>
				<select ref={ playlistSelectRef }className="form-select" value={ playlistId } onChange={ confirmNextScenario } >
					<option value="1">{ t("game.scenario.easy") }</option>
					<option value="2">{ t("game.scenario.normal") }</option>
					<option value="3">{ t("game.scenario.hard") }</option>
				</select>
			</Modal.Body>
		</Modal>
	);
};

export default ScenarioSummaryModal;
