import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { formatPercentage } from "../../../util/numberUtil";

const StockSummaryModal = ({ show, toggle, stockInfo }) => {
	const { t, i18n } = useTranslation();

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{ t("game.finish.title") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{ t("game.finish.stock-info") }<b>{ stockInfo.stockCode }</b> -  { i18n.language === "zh" && stockInfo.stockNameZh ? stockInfo.stockNameZh : stockInfo.stockName }</p>
				<div className="d-flex justify-content-between">
					<b>{ t("game.stats.total-return") }:</b>
					{ formatPercentage(stockInfo.return) }
				</div>
				<div className="d-flex justify-content-between">
					<b>{ t("game.stats.total-trades") }:</b>
					<span>{ stockInfo.trades }</span>
				</div>
				<p className="secondary-text mt-3">{ t("game.finish.sell-note") }</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="finetic-blue" onClick={ toggle } >OK</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default StockSummaryModal;
