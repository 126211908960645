import { useRef, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { Helmet } from "react-helmet";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const activeExchanges = [
	{
		value: "HKEX",
		name: "HKEX",
	},
	{
		value: "US",
		name: "US",
	},
	{
		value: "JP",
		name: "JP",
	},
	{
		value: "CN",
		name: "CN",
	},
	{
		value: "TW",
		name: "TW",
	},
];

const activeTrends = [
	{
		value: "UPTREND",
		name: "Market Uptrend",
	},
	{
		value: "DOWNTREND",
		name: "Market Downtrend",
	},
	{
		value: "UNDER_PRESSURE",
		name: "Market Under Pressure",
	},
	{
		value: "RALLY_ATTEMPT",
		name: "Market Rally Attempt",
	},
];


const DataUpload = () => {
	const finlistExchangeSelectRef = useRef();
	const finlistIssueDateInputRef = useRef();
	const finlistCsvFileInputRef = useRef();
	const finlistFundamentalCsvFileInputRef = useRef();

	const marketTrendExchangeSelectRef = useRef();
	const marketTrendIssueDateInputRef = useRef();
	const marketTrendCsvFileInputRef = useRef();

	const shortTermMarketTrendExchangeSelectRef = useRef();
	const shortTermMarketTrendStartDateInputRef = useRef();
	const shortTermMarketTrendAmendCheckboxRef = useRef();
	const shortTermMarketTrendTrendSelectRef = useRef();

	const midLongTermMarketTrendExchangeSelectRef = useRef();
	const midLongTermMarketTrendStartDateInputRef = useRef();
	const midLongTermMarketTrendAmendCheckboxRef = useRef();
	const midLongTermMarketTrendTrendSelectRef = useRef();

	const stockExchangeSelectRef = useRef();
	const stockCsvFileInputRef = useRef();

	const stockPriceExchangeSelectRef = useRef();
	const stockPriceStockCodeInputRef = useRef();
	const stockPriceCsvFileInputRef = useRef();

	const simulatorStockListCsvFileInputRef = useRef();
	const fintrainerPlaylistCsvFileInputRef = useRef();
	
	const patternsExchangeSelectRef = useRef();
	const patternsCsvFileInputRef = useRef();

	const sundayClubLinkInputRef = useRef();
	const nextSeminarDateInputRef = useRef();
	
	const systemMessageExpiryDateInputRef = useRef();
	const systemMessageTargetSelectRef = useRef();
	const systemMessageSameMsgCheckboxRef = useRef();
	const systemMessageZhTextareaRef = useRef();
	const systemMessageEnTextareaRef = useRef();
	
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();

	const onChangeSystemMsgSameLang = () => {
		systemMessageEnTextareaRef.current.disabled = systemMessageSameMsgCheckboxRef.current.checked;
		systemMessageEnTextareaRef.current.required = !systemMessageSameMsgCheckboxRef.current.checked;
	};

	const onChangeShortTermIsAmend = () => {
		shortTermMarketTrendStartDateInputRef.current.disabled = shortTermMarketTrendAmendCheckboxRef.current.checked;
		shortTermMarketTrendStartDateInputRef.current.required = !shortTermMarketTrendAmendCheckboxRef.current.checked;
	};

	const onChangeMidLongTermIsAmend = () => {
		midLongTermMarketTrendStartDateInputRef.current.disabled = midLongTermMarketTrendAmendCheckboxRef.current.checked;
		midLongTermMarketTrendStartDateInputRef.current.required = !midLongTermMarketTrendAmendCheckboxRef.current.checked;
	};

	const getSundayClubLink = async () => {
		var sundayClubLinkResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"education/sunday-club/link",
			"GET"
		);

		sundayClubLinkInputRef.current.value = sundayClubLinkResponse.data;
	};

	const getNextSeminarDate = async () => {
		var nextSeminarDateResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"website/landing/free-seminar",
			"GET"
		);

		nextSeminarDateInputRef.current.value = nextSeminarDateResponse.data.seminarDate;
	};

	const uploadFinList = async (event) => {
		event.preventDefault();

		try {
			var formData = new FormData();
			formData.append("finListCsv", finlistCsvFileInputRef.current.files[0]);
			formData.append("finListFundamentalCsv", finlistFundamentalCsvFileInputRef.current.files[0]);

			var uploadFinListResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/finlist/upload?exchange=" + finlistExchangeSelectRef.current.value + "&issueDate=" + finlistIssueDateInputRef.current.value,
				"POST",
				{ },
				formData
			);

			if (uploadFinListResponse.status === "success") {
				alert("Success!" + uploadFinListResponse.data + " FinList records inserted.");
			} else {
				alert("Error: " + uploadFinListResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const uploadMarketTrendData = async (event) => {
		event.preventDefault();

		try {
			var formData = new FormData();
			formData.append("marketTrendDetailCsv", marketTrendCsvFileInputRef.current.files[0]);

			var uploadMarketTrendResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/markettrend/upload?exchange=" + marketTrendExchangeSelectRef.current.value + "&issueDate=" + marketTrendIssueDateInputRef.current.value,
				"POST",
				{ },
				formData
			);

			if (uploadMarketTrendResponse.status === "success") {
				alert("Success!");
			} else {
				alert("Error: " + uploadMarketTrendResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const updateShortTermMarketTrend = async (event) => {
		event.preventDefault();

		try {
			var updateMarketTrendResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/markettrend/update-trend?exchange=" + shortTermMarketTrendExchangeSelectRef.current.value + "&startDate=" + shortTermMarketTrendStartDateInputRef.current.value,
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					marketTrend: shortTermMarketTrendTrendSelectRef.current.value,
					shortTerm: true,
					amend: shortTermMarketTrendAmendCheckboxRef.current.checked,
				})
			);

			if (updateMarketTrendResponse.status === "success") {
				alert("Success");
			} else {
				alert("Error: " + updateMarketTrendResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const updateMidLongTermMarketTrend = async (event) => {
		event.preventDefault();

		try {
			var updateMarketTrendResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/markettrend/update-trend?exchange=" + midLongTermMarketTrendExchangeSelectRef.current.value + "&startDate=" + midLongTermMarketTrendStartDateInputRef.current.value,
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					marketTrend: midLongTermMarketTrendTrendSelectRef.current.value,
					shortTerm: false,
					amend: midLongTermMarketTrendAmendCheckboxRef.current.checked,
				})
			);

			if (updateMarketTrendResponse.status === "success") {
				alert("Success");
			} else {
				alert("Error: " + updateMarketTrendResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const uploadStock = async (event) => {
		event.preventDefault();

		try {
			var formData = new FormData();
			formData.append("stockCsv", stockCsvFileInputRef.current.files[0]);

			var uploadStockResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/stock/upload?exchange=" + stockExchangeSelectRef.current.value,
				"POST",
				{ },
				formData
			);

			if (uploadStockResponse.status === "success") {
				alert("Success! " + uploadStockResponse.data + " stock records inserted.");
			} else {
				alert("Error: " + uploadStockResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const uploadStockPrice = async (event) => {
		event.preventDefault();

		try {
			var formData = new FormData();
			formData.append("priceCsv", stockPriceCsvFileInputRef.current.files[0]);

			var uploadStockResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/stock/price/upload?exchange=" + stockPriceExchangeSelectRef.current.value + "&stockCode=" + stockPriceStockCodeInputRef.current.value,
				"POST",
				{ },
				formData
			);

			if (uploadStockResponse.status === "success") {
				alert("Success! " + uploadStockResponse.data + " price records inserted.");
			} else {
				alert("Error: " + uploadStockResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const uploadSimulatorStock = async (event) => {
		event.preventDefault();

		try {
			var formData = new FormData();
			formData.append("simulatorStocksCsv", simulatorStockListCsvFileInputRef.current.files[0]);

			var uploadSimulatorStockResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/simulator-stock/upload",
				"POST",
				{ },
				formData
			);

			if (uploadSimulatorStockResponse.status === "success") {
				alert("Success! " + uploadSimulatorStockResponse.data + " simulator stock records inserted.");
			} else {
				alert("Error: " + uploadSimulatorStockResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const uploadFinTrainerPlaylist = async (event) => {
		event.preventDefault();

		try {
			var formData = new FormData();
			formData.append("gameLevelCsv", fintrainerPlaylistCsvFileInputRef.current.files[0]);

			var uploadGameLevelResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/simulator-game/upload",
				"POST",
				{ },
				formData
			);

			if (uploadGameLevelResponse.status === "success") {
				alert("Success! " + uploadGameLevelResponse.data + " game level records inserted.");
			} else {
				alert("Error: " + uploadGameLevelResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const uploadPatterns = async (event) => {
		event.preventDefault();

		try {
			var formData = new FormData();
			formData.append("patternsCsv", patternsCsvFileInputRef.current.files[0]);

			var uploadPatternsResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/patterns/upload?exchange=" + patternsExchangeSelectRef.current.value,
				"POST",
				{ },
				formData
			);

			if (uploadPatternsResponse.status === "success") {
				alert("Success! " + uploadPatternsResponse.data + " records inserted.");
			} else {
				alert("Error: " + uploadPatternsResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const updateSundayClubLink = async (event) => {
		event.preventDefault();

		try {
			var updateSundayClubLinkResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/config",
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					configKey: "SUNDAY_CLUB_LINK",
					configValue: sundayClubLinkInputRef.current.value,
				})
			);

			if (updateSundayClubLinkResponse.status === "success") {
				alert("Success");
			} else {
				alert("Error: " + updateSundayClubLinkResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const updateNextSeminarDate = async (event) => {
		event.preventDefault();

		try {
			var updateNextSeminarDateResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/config",
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					configKey: "SEMINAR_DATE",
					configValue: nextSeminarDateInputRef.current.value,
				})
			);

			if (updateNextSeminarDateResponse.status === "success") {
				alert("Success");
			} else {
				alert("Error: " + updateNextSeminarDateResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const uploadSystemMessage = async (event) => {
		event.preventDefault();

		try {
			var uploadSystemMessageResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"admin/data/system-notifications",
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					expiryDate: systemMessageExpiryDateInputRef.current.value,
					messageEn: systemMessageSameMsgCheckboxRef.current.checked ? systemMessageZhTextareaRef.current.value : systemMessageEnTextareaRef.current.value,
					messageZh: systemMessageZhTextareaRef.current.value,
					target: systemMessageTargetSelectRef.current.value,
				})
			);

			if (uploadSystemMessageResponse.status === "success") {
				alert("Success!");
			} else {
				alert("Error: " + uploadSystemMessageResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	useEffect(() => {
		getSundayClubLink();
		getNextSeminarDate();
	}, [ ]);

	return (
		<>
			<Helmet>
				<title>Data Upload - Finetic App</title>
			</Helmet>

			<div className="row mt-3">
				<div className="col-12 col-lg-4">
					<h2>FinList</h2>

					<Form onSubmit={ uploadFinList } className="mb-3">
						<FloatingLabel controlId="finlist-exchange" label="Select Exchange" className="mb-3">
							<Form.Select ref={ finlistExchangeSelectRef } aria-label="Select Exchange" required>
								<option value="" disabled="disabled" selected>Select Exchange</option>
								{
									activeExchanges.map((exchange) => (
										<option key={ exchange.value } value={ exchange.value } >{ exchange.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<FloatingLabel controlId="finlist-issue-date" label="Issue Date" className="mb-3">
							<Form.Control ref={ finlistIssueDateInputRef } type="date" aria-label="Issue Date" required />
						</FloatingLabel>

						<Form.Group controlId="finlist-csv" className="mb-3">
							<Form.Label>FinList CSV</Form.Label>
							<Form.Control ref={ finlistCsvFileInputRef } type="file" accept=".csv" required />
						</Form.Group>

						<Form.Group controlId="finlist-fundamental-csv" className="mb-3">
							<Form.Label>FinList Fundamental CSV</Form.Label>
							<Form.Control ref={ finlistFundamentalCsvFileInputRef } type="file" accept=".csv" required />
						</Form.Group>

						<Button variant="finetic-blue" type="submit">Upload FinList</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h2>Free Seminar</h2>

					<Form onSubmit={ updateNextSeminarDate } className="mb-3">
						<FloatingLabel controlId="next-seminar-date" label="Next Seminar Date" className="mb-3">
							<Form.Control ref={ nextSeminarDateInputRef } type="date" aria-label="Next Seminar Date" required />
						</FloatingLabel>

						<Button variant="finetic-blue" type="submit">Update Next Seminar Date</Button>
					</Form>

					<h2>Sunday Club</h2>

					<Form onSubmit={ updateSundayClubLink } className="mb-3">
						<FloatingLabel controlId="sunday-club-link" label="Zoom Registration Link" className="mb-3">
							<Form.Control ref={ sundayClubLinkInputRef } type="text" aria-label="Zoom Registration Link" required />
						</FloatingLabel>

						<Button variant="finetic-blue" type="submit">Update Sunday Club Link</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h2>System Notifications</h2>

					<Form onSubmit={ uploadSystemMessage } className="mb-3">
						<FloatingLabel controlId="system-message-expiry-date" label="Message Expiry Date" className="mb-3">
							<Form.Control ref={ systemMessageExpiryDateInputRef } type="date" aria-label="Message Expiry Date" required />
						</FloatingLabel>

						<FloatingLabel controlId="system-message-target" label="Select target" className="mb-3">
							<Form.Select ref={ systemMessageTargetSelectRef } aria-label="Select target" required>
								<option value="" disabled="disabled" selected>Select...</option>
								{
									[
										{
											value: "ALL",
											name: "All users"
										},
										{
											value: "PAID",
											name: "All paid users"
										},
										{
											value: "SUNDAY_CLUB",
											name: "Sunday Club users"
										},
										...activeExchanges,
									].map((exchange) => (
										<option key={ exchange.value } value={ exchange.value } >{ exchange.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<Form.Check ref={ systemMessageSameMsgCheckboxRef } type="checkbox" id="use-zh-for-en" label="Same message for English users" onChange={ onChangeSystemMsgSameLang } className="mb-3" />

						<FloatingLabel controlId="system-message-zh-msg" label="Chinese message" className="mb-3">
							<Form.Control ref={ systemMessageZhTextareaRef } as="textarea" aria-label="Chinese message" required />
						</FloatingLabel>

						<FloatingLabel controlId="system-message-en-msg" label="English message" className="mb-3">
							<Form.Control ref={ systemMessageEnTextareaRef } as="textarea" aria-label="English message" disabled />
						</FloatingLabel>

						<Button variant="finetic-blue" type="submit">Upload Message</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h2>Market Trend</h2>

					<Form onSubmit={ uploadMarketTrendData } className="mb-3">
						<FloatingLabel controlId="market-trend-exchange" label="Select Exchange" className="mb-3">
							<Form.Select ref={ marketTrendExchangeSelectRef } aria-label="Select Exchange" required>
								<option value="" disabled="disabled" selected>Select Exchange</option>
								{
									activeExchanges.map((exchange) => (
										<option key={ exchange.value } value={ exchange.value } >{ exchange.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<FloatingLabel controlId="market-trend-issue-date" label="Issue Date" className="mb-3">
							<Form.Control ref={ marketTrendIssueDateInputRef } type="date" aria-label="Issue Date" required />
						</FloatingLabel>

						<Form.Group controlId="market-trend-csv" className="mb-3">
							<Form.Label>Market Trend CSV</Form.Label>
							<Form.Control ref={ marketTrendCsvFileInputRef } type="file" accept=".csv" required />
						</Form.Group>

						<Button variant="finetic-blue" type="submit">Upload Market Trend</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h3>Short Term Market Trend</h3>

					<Form onSubmit={ updateShortTermMarketTrend } className="mb-3">
						<FloatingLabel controlId="short-term-market-trend-exchange" label="Select Exchange" className="mb-3">
							<Form.Select ref={ shortTermMarketTrendExchangeSelectRef } aria-label="Select Exchange" required>
								<option value="" disabled="disabled" selected>Select Exchange</option>
								{
									activeExchanges.map((exchange) => (
										<option key={ exchange.value } value={ exchange.value } >{ exchange.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<FloatingLabel controlId="short-term-market-trend-issue-date" label="Start Date" className="mb-3">
							<Form.Control ref={ shortTermMarketTrendStartDateInputRef } type="date" aria-label="Start Date" required />
						</FloatingLabel>

						<FloatingLabel controlId="short-term-market-trend-trend" label="Select Market Trend" className="mb-3">
							<Form.Select ref={ shortTermMarketTrendTrendSelectRef } aria-label="Select Exchange" required>
								<option value="" disabled="disabled" selected>Select Market Trend</option>
								{
									activeTrends.map((trend) => (
										<option value={ trend.value } >{ trend.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<Form.Check ref={ shortTermMarketTrendAmendCheckboxRef } type="checkbox" id="short-term-trend-is-amend" label="Is Amend?" onChange={ onChangeShortTermIsAmend } className="mb-3" />

						<Button variant="finetic-blue" type="submit">Upload Market Trend</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h3>Mid Long Term Market Trend</h3>

					<Form onSubmit={ updateMidLongTermMarketTrend } className="mb-3">
						<FloatingLabel controlId="mid-long-term-market-trend-exchange" label="Select Exchange" className="mb-3">
							<Form.Select ref={ midLongTermMarketTrendExchangeSelectRef } aria-label="Select Exchange" required>
								<option value="" disabled="disabled" selected>Select Exchange</option>
								{
									activeExchanges.map((exchange) => (
										<option key={ exchange.value } value={ exchange.value } >{ exchange.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<FloatingLabel controlId="mid-long-term-market-trend-issue-date" label="Start Date" className="mb-3">
							<Form.Control ref={ midLongTermMarketTrendStartDateInputRef } type="date" aria-label="Start Date" required />
						</FloatingLabel>

						<FloatingLabel controlId="mid-long-term-market-trend-trend" label="Select Market Trend" className="mb-3">
							<Form.Select ref={ midLongTermMarketTrendTrendSelectRef } aria-label="Select Exchange" required>
								<option value="" disabled="disabled" selected>Select Market Trend</option>
								{
									activeTrends.map((trend) => (
										<option value={ trend.value } >{ trend.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<Form.Check ref={ midLongTermMarketTrendAmendCheckboxRef } type="checkbox" id="mid-long-term-trend-is-amend" label="Is Amend?" onChange={ onChangeMidLongTermIsAmend } className="mb-3" />

						<Button variant="finetic-blue" type="submit">Upload Market Trend</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h2>Stocks</h2>

					<Form onSubmit={ uploadStock } className="mb-3">
						<FloatingLabel controlId="stock-exchange" label="Select Exchange" className="mb-3">
							<Form.Select ref={ stockExchangeSelectRef } aria-label="Select Exchange" required>
								<option value="" disabled="disabled" selected>Select Exchange</option>
								{
									activeExchanges.map((exchange) => (
										<option key={ exchange.value } value={ exchange.value } >{ exchange.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<Form.Group controlId="stock-csv" className="mb-3">
							<Form.Label>Stocks CSV</Form.Label>
							<Form.Control ref={ stockCsvFileInputRef } type="file" accept=".csv" required />
						</Form.Group>

						<Button variant="finetic-blue" type="submit">Upload Stocks</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h2>Stock Price</h2>

					<Form onSubmit={ uploadStockPrice } className="mb-3">
						<FloatingLabel controlId="stock-price-exchange" label="Select Exchange" className="mb-3">
							<Form.Select ref={ stockPriceExchangeSelectRef } aria-label="Select Exchange" required>
								<option value="" disabled="disabled" selected>Select Exchange</option>
								{
									[
										...activeExchanges,
										{
											value: "PRAC",
											name: "Simulator exchange"
										},
										{
											value: "GAME",
											name: "Game exchange"
										}
									].map((exchange) => (
										<option key={ exchange.value } value={ exchange.value } >{ exchange.name }</option>
									))
								}
							</Form.Select>
						</FloatingLabel>

						<FloatingLabel controlId="stock-price-stock-code" label="Stock Code" className="mb-3">
							<Form.Control ref={ stockPriceStockCodeInputRef } type="text" aria-label="Stock Code" required />
						</FloatingLabel>

						<Form.Group controlId="stock-price-csv" className="mb-3">
							<Form.Label>Stock Price CSV</Form.Label>
							<Form.Control ref={ stockPriceCsvFileInputRef } type="file" accept=".csv" required />
						</Form.Group>

						<Button variant="finetic-blue" type="submit">Upload Stocks</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h2>Simulator Stock List</h2>

					<Form onSubmit={ uploadSimulatorStock } className="mb-3">
						<Form.Group controlId="simulator-stock-csv" className="mb-3">
							<Form.Label>Simulator Stock List CSV</Form.Label>
							<Form.Control ref={ simulatorStockListCsvFileInputRef } type="file" accept=".csv" required />
						</Form.Group>

						<Button variant="finetic-blue" type="submit">Upload Simulator Stock List</Button>
					</Form>

					<h2>FinTrainer Playlist</h2>

					<Form onSubmit={ uploadFinTrainerPlaylist } className="mb-3">
						<Form.Group controlId="fintrainer-playlist-csv" className="mb-3">
							<Form.Label>FinTrainer Playlist CSV</Form.Label>
							<Form.Control ref={ fintrainerPlaylistCsvFileInputRef } type="file" accept=".csv" required />
						</Form.Group>

						<Button variant="finetic-blue" type="submit">Upload FinTrainer Playlist</Button>
					</Form>
				</div>

				<div className="col-12 col-lg-4">
					<h2>Patterns</h2>

					<FloatingLabel controlId="patterns-exchange" label="Select Exchange" className="mb-3">
						<Form.Select ref={ patternsExchangeSelectRef } aria-label="Select Exchange" required>
							<option value="" disabled="disabled" selected>Select Exchange</option>
							{
								activeExchanges.map((exchange) => (
									<option key={ exchange.value } value={ exchange.value } >{ exchange.name }</option>
								))
							}
						</Form.Select>
					</FloatingLabel>

					<Form onSubmit={ uploadPatterns } className="mb-3">
						<Form.Group controlId="patterns-csv" className="mb-3">
							<Form.Label>Patterns CSV</Form.Label>
							<Form.Control ref={ patternsCsvFileInputRef } type="file" accept=".csv" required />
						</Form.Group>

						<Button variant="finetic-blue" type="submit">Upload Patterns</Button>
					</Form>
				</div>
			</div>
		</>
	);
};

export default DataUpload;
