import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

import { Translation } from 'react-i18next';

export default class Filter extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			column: "",
			operation: "",
			from: "",
			to: "",
			canAddFilter: false,
		}

		this.onChangeColumn = this.onChangeColumn.bind(this);
		this.onChangeOperation = this.onChangeOperation.bind(this);
		this.onChangeFrom = this.onChangeFrom.bind(this);
		this.onChangeTo = this.onChangeTo.bind(this);
		this.onAddFilter = this.onAddFilter.bind(this);
	}

	onChangeColumn(event)
	{
		this.setState({ column: event.target.value, operation: "", from: "", to: "", canAddFilter: false });
	}

	onChangeOperation(event)
	{
		this.setState({ operation: event.target.value, from: "", to: "", canAddFilter: false });
	}

	onChangeFrom(event)
	{
		var canAddFilter = false;

		if (this.state.column !== "" && this.state.operation !== "") {
			if (this.state.operation === "between") {
				if (this.state.to !== "") {
					canAddFilter = true;
				}
			} else {
				canAddFilter = true;
			}
		}

		this.setState({ from: Number(event.target.value), canAddFilter: canAddFilter });
	}

	onChangeTo(event)
	{
		var canAddFilter = false;
		
		if (this.state.column !== "" && this.state.operation !== "" && this.state.from !== "") {
			canAddFilter = true;
		}

		this.setState({ to: Number(event.target.value), canAddFilter: canAddFilter });
	}

	onAddFilter()
	{
		this.props.addFilter({
			column: this.state.column,
			operation: this.state.operation,
			from: this.state.from,
			to: this.state.to === "" ? -1 : this.state.to,
		});

		this.setState({ column: "", operation: "", from: "", to: "", canAddFilter: false });
	}

	render()
	{
		return (
			<Translation>
				{
					(t) => (
						<div className="row filter mx-10 my-lg-5">
							<div className="col-12 col-lg-4 pr-lg-5">
								<select className="form-control mr-10" value={ this.state.column } onChange={ this.onChangeColumn } >
									<option value="" disabled="disabled">{ t("finder.filter.select-column") }</option>
									{ !this.props.disabledColumns.includes("percival_score") ? <option value="percival_score">{ t("finder.data.percival-score") }</option> : null }
								</select>
							</div>

							<div className="col-12 col-lg-3 px-lg-5 my-5 my-lg-0">
								<select className="form-control mr-10" value={ this.state.operation } onChange={ this.onChangeOperation } >
									<option value="" disabled="disabled">{ t("finder.filter.select-operation") }</option>
									<option value="gt">{ t("finder.filter.gt") }</option>
									<option value="gte">{ t("finder.filter.gte") }</option>
									<option value="lt">{ t("finder.filter.lt") }</option>
									<option value="lte">{ t("finder.filter.lte") }</option>
									<option value="between">{ t("finder.filter.between") }</option>
								</select>
							</div>

							<div className="col-8 col-lg-auto px-lg-5">
								<input className="finder-filter-input" type="number" inputMode="numeric" value={ this.state.from } onChange={ this.onChangeFrom } />
								{
									this.state.operation === "between" ?
									<>
										<span className="mx-5 mx-lg-10">{ t("finder.filter.to") }</span>
										<input className="finder-filter-input" type="number" inputMode="numeric" onChange={ this.onChangeTo } />
									</> : null
								}
							</div>

							<div className="col-4 col-lg-2 pl-lg-5 text-right">
								<Button buttonType="orange" className="ml-10 d-none d-lg-block" value={ this.state.to } onClick={ this.onAddFilter } disabled={ !this.state.canAddFilter } >{ t("finder.action.add-filter") }</Button>
								<Button buttonType="orange" className="ml-10 d-lg-none" value={ this.state.to } onClick={ this.onAddFilter } disabled={ !this.state.canAddFilter } >{ t("finder.action.add-filter-abbr") }</Button>
							</div>
						</div>
					)
				}
			</Translation>

		);
	}
}

Filter.propTypes = {
	disabledColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
	addFilter: PropTypes.func.isRequired,
};

