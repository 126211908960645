import React from 'react';
import PropTypes from 'prop-types';
import Error from './Error';
import { withAuth0 } from "@auth0/auth0-react";
import RingLoader from "react-spinners/RingLoader";

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

class Content extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			authenticated: false,
			forbidden: false,
		};
	}

	async componentDidMount()
	{
		if (!this.props.skipCheck) {
			const { isAuthenticated } = this.props.auth0;

			if (isAuthenticated) {
				try {
					var userResponse = await apiHelper.request(
						this.props.auth0.getAccessTokenSilently,
						{ pathname: "/background-check" },
						"users/user",
						"GET"
					);

					if (userResponse.status === "Forbidden") {
						this.setState({ forbidden: true });
						return;
					}

					var userData = userResponse.data;

					if (!userData.completeRegistration) {
						window.location.pathname = "/complete-registration";
					} else if (!userData.email || !userData.emailVerified) {
						window.location.pathname = "/verify-email";
					} else {
						this.setState({ authenticated: true }, () => {
							this.props.onCheckComplete();
						});
					}
				} catch (e) {
					console.log(e.message);
				}
			}
		} else {
			this.setState({ authenticated: true }, () => {
				this.props.onCheckComplete();
			});
		}
	}

	render()
	{
		if (this.state.forbidden) {
			return (
				<Error />
			);
		}

		if (this.state.authenticated) {
			return (
				<div id="finetic-content" className="finetic-content">
					{ this.props.children }
				</div>
			);
		}

		return (
			<div id="loading-container" className="col-12 d-flex flex-column justify-content-around align-items-center">
				<RingLoader
					color="#005493"
				/>
				<div></div>
			</div>
		);
	}
}

Content.propTypes = {
	skipCheck: PropTypes.bool,
	onCheckComplete: PropTypes.func,
};

Content.defaultProps = {
	skipCheck: false,
	onCheckComplete: () => { },
};

export default withAuth0(Content);