import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PlanTableHeader from '../components/subscribe/plans/PlanTableHeader';
import PlanFeatureColumn from '../components/subscribe/plans/PlanFeatureColumn';
import PlanCard from '../components/subscribe/plans/PlanCard';

import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import { productDetails } from "../util/products";

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

const Subscribe = () => {
	// General Page States
	const [ loading, setLoading ] = useState(true);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	// Data
	const [ currentBillingPeriod, setCurrentBillingPeriod ] = useState(1);
	
	const [ stripeDetails, setStripeDetails ] = useState({
		cardDetails: null,
		subscriptions: [ ]
	});

	// Modal States
	const [ showSubscribeSuccessModal, setShowSubscribeSuccessModal ] = useState(false);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t } = useTranslation();

	const toggleSubscribeSuccessModal = () => { setShowSubscribeSuccessModal(!showSubscribeSuccessModal) }

	const changeCurrentBillingPeriod = (event) => {
		setCurrentBillingPeriod(Number(event.target.value))
	};

	const getStripeDetails = async () => {
		setLoading(true);
		setError(false);

		try {
			const stripeDetailsResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/user/subscriptions",
				"GET",
			);
	
			setLoading(false);

			if (stripeDetailsResponse.status === "success") {
				setStripeDetails(stripeDetailsResponse.data);
			} else {
				setError(false);
			}
		} catch (error) {
			setLoading(false);
			setError(true);
		}
	};

	useEffect(() => {
		getStripeDetails();
	}, [ ]);

	return (
		<>
			<Helmet>
				<title>Subscribe - Finetic App</title>
			</Helmet>
			<div className="row d-none d-lg-block">
				<div className="col-12">
					<div className="plans-table-wrapper">
						<Table className="plans-table">
							<thead>
								<tr>
									<th className="billing-period">
										<b>{ t("subscribe.choose-a-billing-period") }</b>
										<div className="form-check my-1">
											<input type="radio" className="form-check-input" name="billing-period-table" id="every-month" value={ 3 } checked={ currentBillingPeriod === 3 } onChange={ changeCurrentBillingPeriod } />
											<label className={ "form-check-label" + (currentBillingPeriod === 3 ? " billing-period-active" : "") } htmlFor="every-month">{ t("subscribe.bill-each-month") }</label>
										</div>
										<div className="form-check my-1">
											<input type="radio" className="form-check-input" name="billing-period-table" id="every-three-month" value={ 2 } checked={ currentBillingPeriod === 2 } onChange={ changeCurrentBillingPeriod } />
											<label className={ "form-check-label" + (currentBillingPeriod === 2 ? " billing-period-active" : "") } htmlFor="every-three-month">{ t("subscribe.bill-each-quarter") }</label>
										</div>
										<div className="form-check my-1">
											<input type="radio" className="form-check-input" name="billing-period-table" id="every-year" value={ 1 } checked={ currentBillingPeriod === 1 } onChange={ changeCurrentBillingPeriod } />
											<label className={ "form-check-label" + (currentBillingPeriod === 1 ? " billing-period-active" : "") } htmlFor="every-year">{ t("subscribe.bill-each-year") }</label>
										</div>
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanTableHeader key={ product.id } product={ product } billingPeriodId={ currentBillingPeriod } existingSubscriptions={ stripeDetails.subscriptions.filter(subscription => subscription.productId === product["product-id"]) } loading={ loading } cardDetails={ stripeDetails.cardDetails } />
										))
									}
								</tr>
							</thead>
							<tbody>
								<tr className="feature-row">
									<th>
										{ t("subscribe.markets-covered.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-markets-covered" }
												featureKey="markets-covered"
												featureType="text"
												product={ product }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										<div className="d-flex align-items-center justify-content-between">
											{ t("subscribe.weekly-stock-list.title") }<br />
											<OverlayTrigger
												placement="left"
												overlay={
													<Tooltip>
														{ t("subscribe.weekly-stock-list.desc") }
													</Tooltip>
												}
											>
												<div className="d-flex align-items-center feature-info">
													<FontAwesomeIcon icon={ faInfoCircle } />
												</div>
											</OverlayTrigger>
										</div>
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-weekly-stock-list" }
												featureKey="weekly-stock-list"
												featureType="check-text"
												condition={ product.id !== "free" }
												product={ product }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										<div className="d-flex justify-content-between">
											{ t("subscribe.stock-highlights.title") }<br />
											<OverlayTrigger
												placement="left"
												overlay={
													<Tooltip>
														{ t("subscribe.stock-highlights.desc") }
													</Tooltip>
												}
											>
												<div className="d-flex align-items-center feature-info">
													<FontAwesomeIcon icon={ faInfoCircle } />
												</div>
											</OverlayTrigger>
										</div>
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-stock-highlights" }
												featureKey="stock-highlights"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										<div className="d-flex justify-content-between">
											{ t("subscribe.market-trend.title") }<br />
											<OverlayTrigger
												placement="left"
												overlay={
													<Tooltip>
														{ t("subscribe.market-trend.desc") }
													</Tooltip>
												}
											>
												<div className="d-flex align-items-center feature-info">
													<FontAwesomeIcon icon={ faInfoCircle } />
												</div>
											</OverlayTrigger>
										</div>
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-market-trend" }
												featureKey="market-trend"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.market-performance.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-market-performance" }
												featureKey="market-performance"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.ccass-shareholding.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-ccass-shareholding" }
												featureKey="ccass-shareholding"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.ccass-shareholding-change.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-ccass-shareholding-change" }
												featureKey="ccass-shareholding-change"
												featureType="text"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.profit-alerts.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-profit-alerts" }
												featureKey="profit-alerts"
												featureType="text"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.market-movers.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-market-movers" }
												featureKey="market-movers"
												featureType="text"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.market-movers-plus.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-market-movers-plus" }
												featureKey="market-movers-plus"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.education-video-archive.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-education-video-archive" }
												featureKey="education-video-archive"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.advanced-education-video-archive.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-advanced-education-video-archive" }
												featureKey="advanced-education-video-archive"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										<div className="d-flex justify-content-between">
											{ t("subscribe.sunday-club.title") }<br />
											<OverlayTrigger
												placement="left"
												overlay={
													<Tooltip>
														{ t("subscribe.sunday-club.desc") }
													</Tooltip>
												}
											>
												<div className="d-flex align-items-center feature-info">
													<FontAwesomeIcon icon={ faInfoCircle } />
												</div>
											</OverlayTrigger>
										</div>
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-sunday-club" }
												featureKey="sunday-club"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
								<tr className="feature-row">
									<th>
										{ t("subscribe.sunday-club-replay.title") }
									</th>
									{
										productDetails.filter(p => p.id != "sunday-club").map(product => (
											<PlanFeatureColumn
												key={ product.id + "-sunday-club-replay" }
												featureKey="sunday-club-replay"
												featureType="check"
												product={ product }
												billingPeriodId={ currentBillingPeriod }
											/>
										))
									}
								</tr>
							</tbody>
						</Table>
					</div>
				</div>
			</div>

			<div className="d-lg-none">
				<p className="text-center mt-2 mb-0 text-muted small">{ t("subscribe.choose-a-billing-period") }</p>
				<div className="sticky-top pt-2">
					<ButtonGroup size="sm" className="bg-white m-1 shadow-sm d-flex">
						<ToggleButton
							id="every-month-mobile"
							type="radio"
							name="radio"
							value={ 3 }
							variant={ currentBillingPeriod === 3 ? "primary" : "outline-primary" }
							checked={ currentBillingPeriod === 3 }
							onChange={ changeCurrentBillingPeriod }
						>
							{ t("subscribe.bill-each-month-sm") }
						</ToggleButton>

						<ToggleButton
							id="every-three-month-mobile"
							type="radio"
							name="radio"
							value={ 2 }
							variant={ currentBillingPeriod === 2 ? "primary" : "outline-primary" }
							checked={ currentBillingPeriod === 2 }
							onChange={ changeCurrentBillingPeriod }
						>
							{ t("subscribe.bill-each-quarter-sm") }<br />
							<span className="opacity-50 small">{ t("subscribe.bill-each-quarter-discount-sm") }</span>
						</ToggleButton>

						<ToggleButton
							id="every-year-mobile"
							type="radio"
							name="radio"
							value={ 1 }
							variant={ currentBillingPeriod === 1 ? "primary" : "outline-primary" }
							checked={ currentBillingPeriod === 1 }
							onChange={ changeCurrentBillingPeriod }
						>
							{ t("subscribe.bill-each-year-sm") }<br />
							<span className="opacity-50 small">{ t("subscribe.bill-each-year-discount-sm") }</span>
						</ToggleButton>
					</ButtonGroup>
				</div>

				<div className="row">
				{
					productDetails.filter(p => p.id != "sunday-club").map(product => (
						<PlanCard key={ product.id } product={ product } billingPeriodId={ currentBillingPeriod } existingSubscriptions={ stripeDetails.subscriptions.filter(subscription => subscription.productId === product["product-id"]) } cardDetails={ stripeDetails.cardDetails } loading={ loading } />
					))
				}
				</div>
			</div>
		</>
	);
};

export default Subscribe;
