import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { formatBigNumber, formatPercentage } from "../../util/numberUtil";
import { formatDate } from "../../util/dateUtil";

const StockMovementTable = ({ title, data, updatedAtDate, updatedAtTime, enhanced = false, loading, increase }) => {
	const { t, i18n } = useTranslation();

	return (
		<Card>
			<Card.Body>
				<Card.Title>{ title }</Card.Title>
				{
					enhanced && increase ?
						<p>{ t("stock-movement.increase-desc") }</p> : null
				}
				{
					enhanced && !increase ?
						<p>{ t("stock-movement.decrease-desc") }</p> : null
				}
				{
					loading ?
						<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
							<Skeleton count={ 10 } height="2em" className="my-1" />
						</SkeletonTheme> :
						<>
							<span className="market-movers-last-updated">{ t("stock-movement.information-as-at", { date: formatDate(updatedAtDate.substring(0, 10)) + " " + String(updatedAtTime.getHours()).padStart(2, "0") + ":" + String(updatedAtTime.getMinutes()).padStart(2, "0") + ":" + String(updatedAtTime.getSeconds()).padStart(2, "0") }) }</span>
							<div className="d-none d-lg-block">
								{
									data.length > 0 ?
										<Table className="table-sm stock-movement-table">
											<thead>
												<tr>
													<th>{ t("stock-movement.data.stock") }</th>
													<th>{ t("stock-movement.data.volume") }</th>
													<th>{ t("stock-movement.data.price") }</th>
													<th>
														{
															enhanced ?
															<div className="d-flex justify-content-between align-items-center">
																{ t("stock-movement.data.volume-times") }
																<OverlayTrigger
																	placement="top"
																	overlay={
																		<Tooltip>
																			<span>{ t("stock-movement.data.volume-times-desc") }</span>
																		</Tooltip>
																	}
																>
																	<div><FontAwesomeIcon icon={ faInfoCircle } className="ms-2 info-circle" /></div>
																</OverlayTrigger>
															</div> : t("stock-movement.data.change")
														}
													</th>
												</tr>
											</thead>

											<tbody>
												{
													data.map(stockMovement => (
														<tr>
															<td>
																<div>
																	<b>{ stockMovement.stockCode }</b> { i18n.language === "zh" && stockMovement.stockNameZh !== "" ? stockMovement.stockNameZh : stockMovement.stockName }
																</div>
															</td>
															<td>
																<div>{ formatBigNumber(stockMovement.volume) }</div>
															</td>
															<td>
																<div>${ stockMovement.price }</div>
																{
																	enhanced ?
																	<>
																		{ formatPercentage(stockMovement.priceChange) }
																	</> : null
																}
															</td>
															<td className="text-end">
															{
																enhanced ? 
																	<Badge bg="finetic-blue" style={{ filter: "saturate(" + stockMovement.volumeChange / data[0].volumeChange + ")" }} >{ stockMovement.volumeChange.toFixed(2) }x</Badge> :
																	<div className={ "stock-movement-growth " + (stockMovement.priceChange > 0 ? "positive" : "negative") } > { formatPercentage(stockMovement.priceChange) }
																	</div>
															}
															</td>
														</tr>
													))
												}
											</tbody>
										</Table> : <hr />
								}
							</div>

							<div className="stock-movement-row d-lg-none">
								<hr className="my-2" />
								{
									data.map((stockMovement, i) => (
										<div key={ "stock-movement-" + i } className="mb-2">
											<div className="d-flex justify-content-between">
												<div className="d-flex justify-content-between align-items-center w-100">
													<div>
														<b>{ stockMovement.stockCode }</b> { i18n.language === "zh" && stockMovement.stockNameZh !== "" ? stockMovement.stockNameZh : stockMovement.stockName }<br />
														{ t("stock-movement.data.volume") }: { formatBigNumber(stockMovement.volume) }
													</div>
													<div className="text-end">
														<span>${ stockMovement.price }</span>
														<div>
															{
																formatPercentage(stockMovement.priceChange)
															}
														</div>
													</div>
												</div>
													{
														enhanced ?
															<div className="d-flex align-items-center justify-content-end text-white w-25 ms-2">
																<Badge bg="finetic-blue" style={{ filter: "saturate(" + stockMovement.volumeChange / data[0].volumeChange + ")" }} >{ stockMovement.volumeChange.toFixed(2) }x</Badge>
															</div> : null
													}
											</div>
										</div>
									))
								}
							</div>

							{
								data.length === 0 ?
									<p className="my-1 text-center">{ t("stock-movement.data.no-records-found") }</p> : null
							}
						</>
				}
			</Card.Body>
		</Card>
	)
};

export default StockMovementTable;
