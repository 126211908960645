const apiRoot = process.env.REACT_APP_FINETIC_API_URL + 'tvchart';

const historyProvider = {
	getBars: async function(symbolInfo, resolution, from, to, firstDataRequest, getAccessTokenSilently, isSimulator = false, isStreaming = false, isGame = false) {
		var qs = {
			symbol: symbolInfo.name,
			resolution: resolution,
			from: from,
			to: to,
			firstDataRequest: firstDataRequest,
			isSimulator: isSimulator,
			isStreaming: isStreaming,
			isGame: isGame,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/history?' + query;

		var accessToken = await getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		});
		
		if (request.status === 200) {
			var response = await request.json();

			return response.data;
		} else if (request.status === 403) {
			return [];
		} else {
			return [];
		}
	}
}

export default historyProvider;
