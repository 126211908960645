import { useTranslation } from "react-i18next";

import Badge from "react-bootstrap/Badge";

import { ClipLoader } from "react-spinners";

import { pricing, products } from "../../util/products";

const PlanSelection = ({ billingPeriodId, subscription, loading, onClick }) => {
	const { t } = useTranslation();

	return (
		<div className={ "billing-period-selection" + (subscription.billingPeriodId === billingPeriodId ? " active-plan" : "") + (subscription.futureBillingPeriodId === billingPeriodId ? " future-plan" : "") } onClick={ onClick } >
			{
				loading ?
				<div className="d-flex align-items-center justify-content-center h-full">
					<ClipLoader
						size={ 50 }
						speedMultiplier={ 0.5 }
						color={ subscription.billingPeriodId === billingPeriodId ? "#FFFFFF" : "#005493" }
					/>
				</div> :
				<>
					<b>{ t("user.subscription.change-billing-period." + billingPeriodId) }</b>
					<div>
						${ pricing[billingPeriodId][products[subscription.productId]] }
						<span className="secondary-text">{ t("user.subscription.change-billing-period.per-month") }</span>
					</div>
					{
						subscription.billingPeriodId === billingPeriodId ?
							<Badge bg="finetic-yellow" pill className="text-dark">
								{ t("user.subscription.change-billing-period.current-plan") }
							</Badge> : (
								billingPeriodId === 1 ?
									<Badge bg="finetic-green" pill className="text-dark">
										{ t("user.subscription.change-billing-period.best-deal") }
									</Badge> : <div></div>
							)
					}
					{
						subscription.futureBillingPeriodId === billingPeriodId ?
							<Badge bg="danger" pill>
								{ t("user.subscription.change-billing-period.scheduled") }
							</Badge> : <div></div>
					}
				</>
			}
		</div>
	);
}

export default PlanSelection;
