import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import UpdateSubscriptionModal from "./UpdateSubscriptionModal";
import PlanSelection from "../PlanSelection";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { ClipLoader } from "react-spinners";

import { products } from '../../../util/products';
import { formatDateObj, formatTime } from "../../../util/dateUtil";

import ApiHelper from '../../../api/apiHelper';
const apiHelper = new ApiHelper();

const ManageSubscriptionModal = ({ show, toggle, subscription, cardDetails, getSubscriptions }) => {
	const [ loading, setLoading ] = useState(false);
	const [ billingPeriodId, setBillingPeriodId ] = useState(-1);
	
	const [ showUpdateSubscriptionModal, setShowUpdateSubscriptionModal ] = useState(false);

	const { t, i18n } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();

	const toggleUpdateSubscriptionModal = () => { setShowUpdateSubscriptionModal(!showUpdateSubscriptionModal) };

	const updateSubscription = (targetBillingPeriodId) => {
		if (loading) return;

		if (subscription.billingPeriodId !== targetBillingPeriodId && subscription.futureBillingPeriodId !== targetBillingPeriodId) {
			setBillingPeriodId(targetBillingPeriodId);
			toggle();
			toggleUpdateSubscriptionModal();
		}
	};

	const cancelScheduledDowngrade = async () => {
		setLoading(true);

		const cancelSubscriptionScheduleDowngradeResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"users/user/subscriptions/" + subscription.subscriptionId + "/update/cancel",
			"POST",
			{
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		);

		if (cancelSubscriptionScheduleDowngradeResponse.status === "success") {
			if (cancelSubscriptionScheduleDowngradeResponse.data) {
				toggle();
				setLoading(false);
				getSubscriptions();
			}
		}
	}

	const toggleAutoRenew = async () => {
		setLoading(true);

		const updateSubscriptionResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"users/user/subscriptions/" + subscription.subscriptionId,
			"POST",
			{
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			JSON.stringify({
				hasAutoRenew: !subscription.hasAutoRenew,
			})
		);

		if (updateSubscriptionResponse.status === "success") {
			if (updateSubscriptionResponse.data) {
				toggle();
				setLoading(false);
				getSubscriptions();
			}
		}
	}

	return (
		<>
			<UpdateSubscriptionModal show={ showUpdateSubscriptionModal } toggle={ toggleUpdateSubscriptionModal } subscription={ subscription } cardDetails={ cardDetails } billingPeriodId={ billingPeriodId } getSubscriptions={ getSubscriptions } />
			<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title>{ t("subscribe.product-title." + products[subscription.productId]) }</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="subscription-detail">
						<div className="mb-2">
							{ subscription.hasAutoRenew ?
								<>{ t("user.subscription.next-billing-period") }: { formatDateObj(new Date(subscription.subscriptionPeriodEnd)) + (i18n.language === "en" ? "," : "") + " " + formatTime(new Date(subscription.subscriptionPeriodEnd)) }</> :
								t("user.subscription.expires-at", { date: formatDateObj(new Date(subscription.subscriptionPeriodEnd)) + (i18n.language === "en" ? "," : "") + " " + formatTime(new Date(subscription.subscriptionPeriodEnd)) })
							}
						</div>
						{
							subscription.status !== "trialing" && products[subscription.productId] !== "sunday-club" ? 
							<div>
								<p>{ t("user.subscription.change-billing-period.title") }</p>
								<div className="d-flex justify-content-between">
									<PlanSelection
										billingPeriodId={ 3 }
										subscription={ subscription }
										loading={ loading }
										onClick={ () => { updateSubscription(3) }}
									/>

									<PlanSelection
										billingPeriodId={ 2 }
										subscription={ subscription }
										loading={ loading }
										onClick={ () => { updateSubscription(2) }}
									/>

									<PlanSelection
										billingPeriodId={ 1 }
										subscription={ subscription }
										loading={ loading }
										onClick={ () => { updateSubscription(1) }}
									/>
								</div>
							</div> : null
						}
						<div className="my-3">
							{
								products[subscription.productId] === "sunday-club" ? null :
								<>
								{
									subscription.futureBillingPeriodId > 0 ?
									<>
										<div className="d-grid gap-2">
											<Button variant="outline-secondary" onClick={ () => cancelScheduledDowngrade(subscription.subscriptionId) } disabled={ loading } >
											{
												loading ?
												<div className="d-flex align-items-center justify-content-center h-full">
													<ClipLoader
														size={ 25 }
														speedMultiplier={ 0.5 }
														color="#FFD841"
													/>
												</div> :
												t("user.subscription.cancel-downgrade")
											}
											</Button>
										</div>
									</> : <>
									{
										subscription.hasAutoRenew ?
										<>
											<div className="d-grid gap-2">
												<Button variant="outline-danger" onClick={ () => toggleAutoRenew(subscription.subscriptionId) } disabled={ loading } >
												{
													loading ?
													<div className="d-flex align-items-center justify-content-center h-full">
														<ClipLoader
															size={ 25 }
															speedMultiplier={ 0.5 }
															color="#FF0000"
														/>
													</div> :
													t("user.subscription.cancel-auto-renewal")
												}
												</Button>
											</div>
											<p className="mt-2 mb-0 secondary-text text-muted">{ t("user.subscription.cancel-renewal-note", { date: formatDateObj(new Date(subscription.subscriptionPeriodEnd)) + (i18n.language === "en" ? "," : "") + " " + formatTime(new Date(subscription.subscriptionPeriodEnd)) }) }</p>
										</> :
										<div className="d-grid gap-2">
											<Button variant="finetic-blue" onClick={ () => toggleAutoRenew(subscription.subscriptionId) } disabled={ loading } >
												{
													loading ?
													<div className="d-flex align-items-center justify-content-center h-full">
														<ClipLoader
															size={ 25 }
															speedMultiplier={ 0.5 }
															color="#FFFFFF"
														/>
													</div> :t("user.subscription.reactivate-auto-renewal")
												}
											</Button>
										</div>
									}
									</>
								}
								</>
							}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ManageSubscriptionModal;
