import i18n from "./i18n";
import getUserLocale from 'get-user-locale';

const months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", ];

function formatDate(date)
{
	if (date) {
		var dateSplit = date.split("-");
		var year = Number(dateSplit[0]);
		var month = Number(dateSplit[1]);
		var day = Number(dateSplit[2]);

		switch (i18n.languages[0]) {
			case "en":
				const userLocale = getUserLocale();
				
				if (userLocale === "en-US") {
					return months[month - 1] + (isNaN(day) ? "" : " " + day) + ", " + year;
				}
				
				return (isNaN(day) ? "" : day + " ") + months[month - 1] + " " + year;
			case "zh":
				return year + " 年 " + month + " 月" + (isNaN(day) ? "" : " " + day + " 日");
		}
	}

	return date;
}

function formatDateObj(date)
{
	if (date) {
		var year = date.getFullYear();
		var month = date.getMonth();
		var day = date.getDate();

		switch (i18n.languages[0]) {
			case "en":
				const userLocale = getUserLocale();
				
				if (userLocale === "en-US") {
					return months[month] + (isNaN(day) ? "" : " " + day) + ", " + year;
				}
				
				return (isNaN(day) ? "" : day + " ") + months[month] + " " + year;
			case "zh":
				return year + " 年 " + (month + 1) + " 月" + (isNaN(day) ? "" : " " + day + " 日");
		}
	}

	return date;
}

function formatTime(date, hideSeconds = false)
{
	if (date) {
		var hour = date.getHours();
		var minute = date.getMinutes();
		var second = date.getSeconds();

		return (hour < 10 ? "0" : "") + hour + ":" + (minute < 10 ? "0" : "") + minute + (hideSeconds ? "" : (":" + (second < 10 ? "0" : "") + second));
	}

	return date;
}

export {
	formatDate,
	formatDateObj,
	formatTime,
};
