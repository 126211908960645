import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import lowTurnoverImg from '../../../assets/images/finlist/finlist-low-turnover.png';
import lowTurnoverSmallImg from '../../../assets/images/finlist/finlist-low-turnover-m.png';
import highlightedImg from '../../../assets/images/finlist/finlist-highlighted.png';
import highlightedSmallImg from '../../../assets/images/finlist/finlist-highlighted-m.png';

const HowToUseFinListModal = ({ show, toggle }) => {
	const { t } = useTranslation();

	return (
		<Modal show={ show } onHide={ toggle } centered>
			<Modal.Header closeButton>
				<Modal.Title>{ t("finlist.help.title") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<img src={ lowTurnoverImg } className="img-fluid d-none d-lg-block" />
				<img src={ lowTurnoverSmallImg } className="img-fluid d-lg-none" />
				<p className="mt-0">{ t("finlist.help.low-turnover-desc") }</p>
				<img src={ highlightedImg } className="img-fluid d-none d-lg-block" />
				<img src={ highlightedSmallImg } className="img-fluid d-lg-none" />
				<p>{ t("finlist.help.highlighted-desc") }</p>
				<ol>
					<li>{ t("finlist.help.highlighted-li-1") }</li>
					<li>{ t("finlist.help.highlighted-li-2") }</li>
				</ol>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="finetic-blue" onClick={ toggle } >OK</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default HowToUseFinListModal;
