import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import {
	useStripe,
	useElements,
	Elements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from '@stripe/react-stripe-js';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { ClipLoader } from 'react-spinners';

import ApiHelper from '../../../api/apiHelper';
const apiHelper = new ApiHelper();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const UpdateCardModal = ({ show, toggle, getSubscriptions }) => {
	const [ updatingCard, setUpdatingCard ] = useState(false);

	const [ cardNumberComplete, setCardNumberComplete ] = useState(false);
	const [ cardExpComplete, setCardExpComplete ] = useState(false);
	const [ cardCvcComplete, setCardCvcComplete ] = useState(false);

	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();

	const stripe = useStripe();
	const elements = useElements();

	const updateCard = async () => {
		setUpdatingCard(true);

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardNumberElement = elements.getElement(CardNumberElement);
	
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardNumberElement,
		});

	
		if (error) {
			console.log('[error]', error);
		} else {
			const updateCardResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/user/update-card",
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					paymentMethod: paymentMethod.id,
				})
			);

			if (updateCardResponse.status === "success") {
				cardNumberElement.clear();
				elements.getElement(CardExpiryElement).clear();
				elements.getElement(CardCvcElement).clear();

				toggle();
				getSubscriptions();
				setUpdatingCard(false);
			}
		}
	}

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{ t("user.data.update-payment-info") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="subscription-modal">
					<div className="card-elements-wrapper px-3">
						<div className="row pt-3 pb-1">
							<div className="col">
								<CardNumberElement
									options={{
										style: {
											base: {
												fontSize: '16px'
											},
										},
										showIcon: true,
									}}
									disabled={ updatingCard }
									onChange={ (event) => setCardNumberComplete(event.complete) }
								/>
							</div>
						</div>
						<div className="row py-2">
							<div className="col-6">
								<CardExpiryElement
									options={{
										style: {
											base: {
												fontSize: '16px'
											},
										},
									}}
									disabled={ updatingCard }
									onChange={ (event) => setCardExpComplete(event.complete) }
								/>
							</div>
							<div className="col-6">
								<CardCvcElement
									options={{
										style: {
											base: {
												fontSize: '16px'
											},
										},
									}}
									disabled={ updatingCard }
									onChange={ (event) => setCardCvcComplete(event.complete) }
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-grid gap-2">
					<Button variant="finetic-orange" disabled={ updatingCard || !(cardNumberComplete && cardExpComplete && cardCvcComplete) || !stripe || !elements } onClick={ updateCard } >
						{
							updatingCard ?
							<div className="d-flex align-items-center justify-content-center h-full">
								<ClipLoader
									size={ 25 }
									speedMultiplier={ 0.5 }
									color="#FFFFFF"
								/>
							</div> : t("user.subscription.change-billing-period.switch-now")
						}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
};

const UpdateCardModalWrapper = (props) => {
	return (
		<Elements stripe={ stripePromise } >
			<UpdateCardModal { ...props } />
		</Elements>
	);
};

export default UpdateCardModalWrapper;
