import { useTranslation } from "react-i18next";

const SelectStock = ({ props, data, snapshot, className }) => {
	const { i18n } = useTranslation();

	const getFlag = () => {
		var flag = "";

		switch (stock.exchange) {
			case "HKEX":
				flag = "🇭🇰";
				break;
			case "US":
				flag = "🇺🇸";
				break;
			case "CN":
				flag = "🇨🇳";
				break;
			case "TW":
				flag = "🇹🇼";
				break;
		}
		
		return flag;
	};

	var stock = data.stock;

	return <button { ...props } className={ "stock-option p-2 " + className } type="button">
		<div className="text-start me-2">{ (i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName) } </div>
		<div className="d-flex">
			<div className="me-2">{ stock.stockCode }</div>
			<div>{ getFlag() }</div>
		</div>
	</button>
};

export default SelectStock;
