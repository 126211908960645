import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import ConfirmationModal from "../modal/ConfirmationModal";

import Button from "react-bootstrap/Button";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const WatchlistRow = ({ stock, getFinList }) => {
	const [ showConfirmDeleteModal, setShowConfirmDeleteModal ] = useState(false);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t, i18n } = useTranslation();

	const toggleConfirmDeleteModal = () => { setShowConfirmDeleteModal(!showConfirmDeleteModal) };

	const deleteWatchlistStock = async () => {
		try {
			var watchlistDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/" + stock.watchlistId + "/" + stock.watchlistStockId + "/delete"
			);

			if (watchlistDataResponse.status === "success") {
				toggleConfirmDeleteModal();
				getFinList();
			} else {
				console.log(watchlistDataResponse);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	return (
		<>
			<ConfirmationModal show={ showConfirmDeleteModal } toggle={ toggleConfirmDeleteModal } onConfirm={ deleteWatchlistStock } confirmText={ t("watchlist.confirm-delete") } yesText={ t("watchlist.action.delete") } danger />
			<div className="d-flex justify-content-between align-items-center mb-2">
				<span>{ stock.watchlistName } | { t("watchlist.data.buy-price") }: { stock.buyPrice }</span>
				<Button variant="danger" onClick={ toggleConfirmDeleteModal } >{ t("watchlist.action.delete") }</Button>
			</div>
		</>
	)
};

export default WatchlistRow;
