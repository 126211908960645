import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { loadStripe } from '@stripe/stripe-js';

import {
	useStripe,
	useElements,
	Elements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';

import ThreeDSecureModal from '../../subscribe/modal/ThreeDSecureModal';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ClipLoader } from 'react-spinners';

import { products } from '../../../util/products';

import ApiHelper from '../../../api/apiHelper';
const apiHelper = new ApiHelper();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const UpdateSubscriptionModal = ({ show, toggle, subscription, cardDetails, billingPeriodId, getSubscriptions }) => {
	const couponCodeInputRef = useRef();

	const [ loading, setLoading ] = useState(false);

	const [ paymentRequest, setPaymentRequest ] = useState(null);
	const [ subscriptionUpdateDetail, setSubscriptionUpdateDetail ] = useState(null);
	const [ couponCode, setCouponCode ] = useState("");
	const [ couponCodeValid, setCouponCodeValid ] = useState(false);
	const [ couponCodeValidated, setCouponCodeValidated ] = useState(false);
	const [ validatingCouponCode, setValidatingCouponCode ] = useState(false);
	const [ threeDSecureUrl, setThreeDSecureUrl ] = useState(null);

	const [ hasExpressCheckout, setHasExpressCheckout ] = useState(false);

	const [ cardNumberComplete, setCardNumberComplete ] = useState(false);
	const [ cardExpComplete, setCardExpComplete ] = useState(false);
	const [ cardCvcComplete, setCardCvcComplete ] = useState(false);

	const [ show3DSecureModal, setShow3DSecureModal ] = useState(false);

	const { t } = useTranslation();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();

	const stripe = useStripe();
	const elements = useElements();

	const toggle3DSecureModal = () => { setShow3DSecureModal(!show3DSecureModal) };

	const onChangeCouponCode = (event) => {
		setCouponCodeValidated(false);
		setCouponCode(event.target.value);
	};

	const getSubscriptionUpdateDetail = async (validateCoupon = false, deleteCoupon = false) => {
		if (validateCoupon || deleteCoupon) {
			setValidatingCouponCode(true);
		}

		setLoading(true);
		setPaymentRequest(null);

		if (!loading) {
			const updateSubscriptionResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/user/subscriptions/" + subscription.subscriptionId + "/preview-update",
				"POST",
				{
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				JSON.stringify({
					productId: subscription.productId,
					billingPeriodId: billingPeriodId,
					couponCode: validateCoupon ? couponCode.trim() : null,
				})
			);

			if (updateSubscriptionResponse.status === "success") {
				if (updateSubscriptionResponse.data) {
					setLoading(false);
					setSubscriptionUpdateDetail(updateSubscriptionResponse.data);

					if (validateCoupon || deleteCoupon) {
						setCouponCode("");
						setValidatingCouponCode(false);
						setCouponCodeValidated(validateCoupon);
						setCouponCodeValid(updateSubscriptionResponse.data.couponDetail !== null);
					}
				}
			}
		}
	}

	const getApplePayButton = () => {
		var amount = subscriptionUpdateDetail.amount;

		const paymentRequest = stripe.paymentRequest({
			country: 'HK',
			currency: 'hkd',
			displayItems: [{
				label: t("subscribe." + subscription.productId + ".title") + " - " + t("subscribe.billing-period." + subscription.billingPeriodId),
				amount: amount,
			}],
			total: {
				label: "Finetic Limited",
				amount: amount,
			},
			requestPayerName: false,
			requestPayerEmail: false,
		});

		// Check the availability of the Payment Request API.
		paymentRequest.canMakePayment().then(result => {
			if (result) {
				setPaymentRequest(paymentRequest);
				setHasExpressCheckout(true);
			} else {
				setPaymentRequest(null);
				setHasExpressCheckout(cardDetails != null);
			}
		});

		paymentRequest.on('paymentmethod', async (ev) => {
			updateSubscription(ev.paymentMethod, () => {
				ev.complete('success');
			}, () => {
				ev.complete('fail');
			});
		});
	}

	const onUpdateSubscription = async () => {
		setLoading(true);

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardNumberElement = elements.getElement(CardNumberElement);

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardNumberElement,
		});
	
		if (error) {
			console.log('[error]', error);

			setLoading(false);
		} else {
			updateSubscription(paymentMethod, () => {
				cardNumberElement.clear();
				elements.getElement(CardExpiryElement).clear();
				elements.getElement(CardCvcElement).clear();
			}, () => {
				alert("Payment fail!");
			});
		}
	};

	const updateSubscription = async (paymentMethod, callback = () => { }, error = () => { }) => {
		setLoading(true);

		const updateSubscriptionResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"users/user/subscriptions/" + subscription.subscriptionId,
			"POST",
			{
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			JSON.stringify({
				productId: subscription.productId,
				billingPeriodId: billingPeriodId,
				paymentMethod: paymentMethod.id ? paymentMethod.id : paymentMethod,
				couponCode: couponCodeValid ? subscriptionUpdateDetail.couponDetail.id : null,
			})
		);

		if (updateSubscriptionResponse.status === "success") {
			if (updateSubscriptionResponse.data.subscriptionStatus === "active") {
				callback();
				toggle();

				setLoading(false);
				getSubscriptions();
			} else if (updateSubscriptionResponse.data.subscriptionStatus === "requires_action") {
				var paymentIntentResponse = await stripe.confirmCardPayment(
					updateSubscriptionResponse.data.paymentIntentSecret,
					{
						payment_method: paymentMethod.id,
						return_url: window.location.origin + "/subscribe-verify",
					},
					{ handleActions: false }
				);
	
				window.addEventListener('message', function(ev) {
					if (ev.data === '3DS-authentication-complete') {
						on3DSComplete(updateSubscriptionResponse.data.paymentIntentSecret);
					}
				}, false);

				setThreeDSecureUrl(paymentIntentResponse.paymentIntent.next_action.redirect_to_url.url);
	
				toggle3DSecureModal();
			} else {
				error();
			}
		} else {
			error();
		}
	}

	const expressCheckout = () => {
		updateSubscription("existing_card", () => {  }, () => alert("Payment fail!"));
	};

	const onClickPaymentButton = (event) => {
		if ((couponCode != "" && !couponCodeValid) || loading || validatingCouponCode) {
			event.preventDefault();
		}
	};

	const on3DSComplete = (paymentIntentSecret) => {
		// Check the PaymentIntent
		stripe.retrievePaymentIntent(paymentIntentSecret)
			.then(function(result) {
				if (result.error) {
					// PaymentIntent client secret was invalid
				} else {
					if (result.paymentIntent.status === 'succeeded') {
						// Show your customer that the payment has succeeded
						toggle();

						setLoading(false);
						getSubscriptions();
					} else if (result.paymentIntent.status === 'requires_payment_method') {
						alert("Payment fail!");
					}
				}
			});
	}

	useEffect(() => {
		if (billingPeriodId > 0) {
			getSubscriptionUpdateDetail();
		}
	}, [ billingPeriodId ]);

	useEffect(() => {
		if (subscriptionUpdateDetail !== null) {
			getApplePayButton();
		}
	}, [ subscriptionUpdateDetail ]);

	const renderDowngrade = () => {
		return (
			<>
				<p className="text-muted secondary-text mt-0">
					{ t("user.subscription.change-billing-period.downgrade-desc") }
				</p>
				<div className="d-grid gap-2">
					<Button variant="finetic-orange" disabled={ loading } onClick={ () => {
						updateSubscription("existing_card", () => { }, () => alert("Payment fail!"));
					}} >
						{
							loading ?
							<div className="d-flex align-items-center justify-content-center h-full">
								<ClipLoader
									size={ 25 }
									speedMultiplier={ 0.5 }
									color="#FFFFFF"
								/>
							</div> : t("user.subscription.change-billing-period.switch-now")
						}
					</Button>
				</div>
			</>
		)
	};

	const renderUpgrade = () => {
		return (
			<>
				<ThreeDSecureModal show={ show3DSecureModal } toggle={ toggle3DSecureModal } threeDSecureUrl={ threeDSecureUrl } />
				<p className="text-muted secondary-text mt-0">
					{ t("user.subscription.change-billing-period.update-desc") }
				</p>

				<div className="coupon-code">
					{
						couponCodeValid && subscriptionUpdateDetail.couponDetail ?
						<div className="coupon">
							<div className="d-flex align-items-center">
								<FontAwesomeIcon icon={ faTag } className="me-2 text-finetic-blue" />
								<div className="d-flex flex-column">
									<span>{ t("subscribe.coupon-applied") }{ subscriptionUpdateDetail.couponDetail.code }</span>
									<span className="coupon-saved">
										{ t("subscribe.coupon-saved") }
										{
											subscriptionUpdateDetail.couponDetail.amountOff !== null ?
											"$" + subscriptionUpdateDetail.couponDetail.amountOff / 100 :
											subscriptionUpdateDetail.couponDetail.percentOff + "%"
										}
									</span>
								</div>
							</div>
							<FontAwesomeIcon icon={ faTimesCircle } className="coupon-dismiss" onClick={ () => {
								getSubscriptionUpdateDetail(false, true);
							}} />
						</div> :
						<div class="input-group mb-3">
							<div className="form-floating">
								<input type="text" value={ couponCode } className="form-control" id="coupon-code" autoComplete="off" onChange={ onChangeCouponCode } disabled={ validatingCouponCode } />
								<label className="" htmlFor="coupon-code">{ t("subscribe.coupon-code") }</label>
							</div>
							<Button variant="finetic-blue" onClick={ () => {
								getSubscriptionUpdateDetail(true);
							}} disabled={ couponCode.trim() === "" || validatingCouponCode } >
								{
									validatingCouponCode ?
									<div className="d-flex align-items-center">
										<ClipLoader
											size={ 25 }
											speedMultiplier={ 0.5 }
											color="#FFFFFF"
										/>
									</div> :
									t("subscribe.coupon-code-apply")
								}
							</Button>
						</div>
					}

					{
						couponCode.trim() !== "" && !couponCodeValidated ?
						<div className="mt-1">
							<span className="text-danger"><FontAwesomeIcon icon={ faTimes } className="me-2" />{ t("subscribe.error.validate-coupon") }</span>
						</div> : null
					}

		 			{
						!couponCodeValid && couponCodeValidated ?
						<div className="mt-1">
							<span className="text-danger"><FontAwesomeIcon icon={ faTimes } className="me-2" />{ t("subscribe.error.invalid-coupon") }</span>
						</div> : null
		 			}
		 		</div>

				 <div className="d-flex justify-content-between mt-2">
					<div>
						<span>{ t("user.subscription.change-billing-period.prorated-cost") }</span>
						<p className="mt-0 secondary-text text-muted mw-half">{ t("user.subscription.change-billing-period.prorated-cost-desc") }</p>
					</div>
					<b>${ subscriptionUpdateDetail.amount > 0 ? (subscriptionUpdateDetail.amount / 100) : 0.00 }</b>
				</div>

				{
					hasExpressCheckout ?
						<div className="express-checkout mt-1">
							<div className="title">{ t("subscribe.express-checkout.title") }</div>
							<div className="payment-methods">
								<div className="d-grid gap-2">
									<Button variant="finetic-blue" disabled={ loading || validatingCouponCode || cardDetails.expired } onClick={ expressCheckout } className={ paymentRequest ? "mb-2" : "" } >
										{
											cardDetails.expired ?
											t("subscribe.express-checkout.card-on-file-expired", { card: cardDetails.cardNumberLast4 }) :
											t("subscribe.express-checkout.card-on-file", { card: cardDetails.cardNumberLast4 })
										}
									</Button>
								</div>
								{
									paymentRequest ? <PaymentRequestButtonElement options={{ paymentRequest }} onClick={ onClickPaymentButton } /> : null 
								}
							</div>
					</div> : null
				}
				<div className="payment-separator mt-3 mb-2">
					<span>{ t("subscribe.or") }</span>
				</div>
				<div className="my-1">{ t("subscribe.enter-your-card-details") }</div>
				<div className="card-elements-wrapper px-3">
					<div className="row pt-3 pb-1">
						<div className="col">
							<CardNumberElement
								options={{
									style: {
										base: {
											fontSize: '16px'
										},
									},
									showIcon: true,
								}}
								onChange={ (event) => setCardNumberComplete(event.complete) }
							/>
						</div>
					</div>
					<div className="row py-2">
						<div className="col-6">
							<CardExpiryElement
								options={{
									style: {
										base: {
											fontSize: '16px'
										},
									},
								}}
								onChange={ (event) => setCardExpComplete(event.complete) }
							/>
						</div>
						<div className="col-6">
							<CardCvcElement
								options={{
									style: {
										base: {
											fontSize: '16px'
										},
									},
								}}
								onChange={ (event) => setCardCvcComplete(event.complete) }
							/>
						</div>
					</div>
				</div>

				<div className="d-grid gap-2 mt-3">
					<Button variant="finetic-orange" disabled={ loading || (couponCode !== "" && !couponCodeValid) || validatingCouponCode || !(cardNumberComplete && cardExpComplete && cardCvcComplete) } onClick={ onUpdateSubscription } >
						{
							loading ?
							<div className="d-flex align-items-center justify-content-center h-full">
								<ClipLoader
									size={ 25 }
									speedMultiplier={ 0.5 }
									color="#FFFFFF"
								/>
							</div> : t("user.subscription.change-billing-period.switch-now")
						}
					</Button>
				</div>
			</>
		)
	};

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{ t("subscribe.product-title." + products[subscription.productId]) }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					subscriptionUpdateDetail === null ?
						<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
							<Skeleton count={ 5 } height="2em" className="my-1" />
						</SkeletonTheme> :
						<div className="subscription-modal mb-3">
							<p className="mb-0">{ t("user.subscription.change-billing-period.change-period") }{ t("user.subscription.change-billing-period.new-period-" + billingPeriodId) }</p>
							{
								subscriptionUpdateDetail.downgrade ? renderDowngrade() : renderUpgrade()
							}
						</div>
				}
			</Modal.Body>
		</Modal>
	);
};

const UpdateSubscriptionModalWrapper = (props) => {
	return (
		<Elements stripe={ stripePromise } >
			<UpdateSubscriptionModal { ...props } />
		</Elements>
	);
};

export default UpdateSubscriptionModalWrapper;
