import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
	Box,
	TVChartContainer,
} from '../../components';

import StreamingApi from '../../components/TVChartContainer/streamingApi';

import Card from 'react-bootstrap/Card';
import Button from "react-bootstrap/Button";

import { Helmet } from "react-helmet";

const Simulator = () => {
	const { user, getAccessTokenSilently } = useAuth0();

	const [ streamingApi, setStreamingApi ] = useState(new StreamingApi(getAccessTokenSilently));

	const onClickNextDay = () => {
		streamingApi.nextDay();
	}

	const onClickNextWeek = () => {
		streamingApi.nextWeek();
	}

	return (
		<>
			<Helmet>
				<title>Simulator</title>
			</Helmet>
			<div className="row">
				<div className="col-12 col-lg-9 col-xxl-10 px-0">
					<TVChartContainer
						className="simulator-game-chart"
						streamingApi={ streamingApi }
						autosize={ true }
						getAccessTokenSilently={ getAccessTokenSilently }
						userId={ user.sub }
					/>
				</div>

				<div className="col-12 col-lg-3 col-xxl-2">
					<div className="m-2">
						<Card>
							<Card.Body>
								<div className="d-grid gap-2">
									<Button variant="finetic-blue" onClick={ onClickNextDay } className="my-2">Next Day</Button>
								</div>
								<div className="d-grid gap-2">
									<Button variant="finetic-blue" onClick={ onClickNextWeek } className="my-2">Next Week</Button>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
			</div>
		</>
	);
};

export default Simulator;
