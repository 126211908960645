import Card from 'react-bootstrap/Card';

const Panel = ({ children }) => {
	return (
		<Card className="mb-2 shadow-sm">
			<Card.Body>
				{ children }
			</Card.Body>
		</Card>
	);
}

export default Panel;
