import React from 'react';
import PropTypes from 'prop-types';

export default class HorizontalBar extends React.Component
{
	render()
	{
		return (
			<div className={ (this.props.type !== "" ? "horizontal-bar-" + this.props.type : "horizontal-bar") + " " + this.props.className } >
				{ this.props.children }
			</div>
		);
	}
}

HorizontalBar.propTypes = {
	type: PropTypes.oneOf([ "", "green", "orange", "blue", "light-gray" ]),
	className: PropTypes.string,
};

HorizontalBar.defaultProps = {
	type: "",
	className: "",
};
