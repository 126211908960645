import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import StockMovement from "./StockMovement";
import EnhancedStockMovement from "./EnhancedStockMovement";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { Helmet } from "react-helmet";

const tabs = [ "BASIC", "PLUS" ];

const Education = () => {
	const [ activeTab, setActiveTab ] = useState(tabs[0]);
	
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>Market Movers (HK) - Finetic App</title>
			</Helmet>

			<div className="row">
				<div className="col-12 px-0 overflow-x-hidden">
					<Tabs activeKey={ activeTab } onSelect={ setActiveTab } mountOnEnter>
						<Tab eventKey={ tabs[0] } title={ t("stock-movement.title") } className="px-2">
							<StockMovement />
						</Tab>
						<Tab eventKey={ tabs[1] } title={ t("stock-movement.title-enhanced") } className="px-2">
							<EnhancedStockMovement />
						</Tab>
					</Tabs>
				</div>
			</div>
		</>
	);
};

export default Education;
