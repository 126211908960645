import { useTranslation } from "react-i18next";

import Card from "react-bootstrap/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { products } from "../../util/products";
import { formatDateObj } from "../../util/dateUtil";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Invoice = ({ invoice }) => {
	const { t } = useTranslation();

	return (
		<a href={ invoice.pdfLink } target="_blank" className="text-dark text-decoration-none">
			<Card className="mb-2">
				<Card.Body>
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center w-100">
							<div className="d-flex align-items-center">
								<FontAwesomeIcon icon={ faFileAlt } className="me-2" />
								<h5 className="my-0">{ t("subscribe.product-title." + products[invoice.productId]) }</h5>
							</div>

							<div className="d-flex align-items-center">
								<div className="text-muted me-2">
									{ formatDateObj(new Date(invoice.periodStart)) } - { formatDateObj(new Date(invoice.periodEnd)) }
								</div>

								<FontAwesomeIcon icon={ faChevronRight } className="d-none d-lg-block" />
							</div>
						</div>

						<FontAwesomeIcon icon={ faChevronRight } className="d-lg-none" />
					</div>
				</Card.Body>
			</Card>
		</a>
	);
};

const Invoices = ({ invoices, loading }) => {
	const { t } = useTranslation();

	return (
		<div className="row">
			<div className="col-12 col-lg-6">
				{
					loading ?
						<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
							<Skeleton count={ 5 } height="3em" className="my-1" />
						</SkeletonTheme> :
						<>
							{
								invoices.length === 0 ?
									<span>{ t("user.no-receipts") }</span> :
									invoices.map((invoice, i) => (
										<Invoice invoice={ invoice } />
									))
							}
						</>
				}
			</div>
		</div>
	);
};

export default Invoices;
