import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

import {
	Error,
} from "../../components";

import {
	ShareholdingChangeTable,
} from "../../components/shareholdingChanges";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const ShareholdingChange = () => {
	// General Page States
	const [ loading, setLoading ] = useState(true);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	// Data
	const [ shareholdingChangeData, setShareholdingChangeData ] = useState({
		dayIncreaseList: [ ],
		dayDecreaseList: [ ],
		weekIncreaseList: [ ],
		weekDecreaseList: [ ],
	});

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { i18n, t } = useTranslation();

	const getShareholdingChangeData = async () => {
		setLoading(true);

		try {
			var shareholdingChangeResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"ccass",
				"GET"
			);
			
			setLoading(false);

			if (shareholdingChangeResponse.status === "Forbidden") {
				setForbidden(true);
			} else if (shareholdingChangeResponse.status === "success") {
				setError(false);
				setForbidden(false);
				setShareholdingChangeData(shareholdingChangeResponse.data);
			} else {
				setError(true);
			}
		} catch (e) {
			setLoading(false);
			setError(true);
		}
	}

	useEffect(() => {
		getShareholdingChangeData();
	}, [ ]);

	if (forbidden || error) {
		return (
			<div className="mt-5">
				<Error />
			</div>
		);
	}

	return (
		<>
			<Helmet>
				<title>Shareholding Changes (HK) - Finetic App</title>
			</Helmet>

			<div className="row">
				<div className="col-12 col-lg-8 my-3">
					<span>{ t("shareholding-change.intro") }</span>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-lg-6 col-xxl-5 mb-3">
					<ShareholdingChangeTable title={ t("shareholding-change.top-20-day-increase") } data={ shareholdingChangeData.dayIncreaseList } updatedAt={ shareholdingChangeData.updatedAt } loading={ loading } />
				</div>

				<div className="col-12 col-lg-6 col-xxl-5 mb-3">
					<ShareholdingChangeTable title={ t("shareholding-change.top-20-day-decrease") } data={ shareholdingChangeData.dayDecreaseList } updatedAt={ shareholdingChangeData.updatedAt } loading={ loading } />
				</div>

				<div className="col-12 col-lg-6 col-xxl-5 mb-3">
					<ShareholdingChangeTable title={ t("shareholding-change.top-20-week-increase") } data={ shareholdingChangeData.weekIncreaseList } updatedAt={ shareholdingChangeData.updatedAt } loading={ loading } />
				</div>

				<div className="col-12 col-lg-6 col-xxl-5 mb-3">
					<ShareholdingChangeTable title={ t("shareholding-change.top-20-week-decrease") } data={ shareholdingChangeData.weekDecreaseList } updatedAt={ shareholdingChangeData.updatedAt } loading={ loading } />
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-lg-8">
					<p className="small text-muted">* { t("shareholding-change.disclaimer-1") }<a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "terms-and-conditions" } target="_blank" className="text-muted">{ t("shareholding-change.disclaimer-2") }<FontAwesomeIcon icon={ faShareSquare } className="ms-2" /></a>{ t("shareholding-change.disclaimer-3") }</p>
				</div>
			</div>
		</>
	)
};

export default ShareholdingChange;
