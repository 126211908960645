import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
	VideoList,
} from '../components/education';

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";

import { Helmet } from "react-helmet";
import { formatDate } from '../util/dateUtil';

import trade175Image from '../assets/images/how-to-use-175.png';
import cupWithHandle from '../assets/images/cup-with-handle.png';
import cupWithoutHandle from '../assets/images/cup-without-handle.png';
import flat from '../assets/images/flat.png';
import doubleDeep from '../assets/images/double-deep.png';

const tabs = [ "SUNDAY_CLUB", "EDUCATION_VIDEO", "EDUCATION_VIDEO_ADVANCED", "HOW_TO_USE_OUR_SERVICES", "PATTERNS" ];

const Education = () => {
	const [ activeTab, setActiveTab ] = useState(tabs[0]);
	
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>Education - Finetic App</title>
			</Helmet>

			<div className="row">
				<div className="col-12 px-0 overflow-x-hidden">
					<Tabs activeKey={ activeTab } onSelect={ setActiveTab } className="mb-3" mountOnEnter>
						<Tab eventKey={ tabs[0] } title={ t("education.sunday-club") } className="px-2 video-list">
							<VideoList type={ tabs[0] } />
						</Tab>
						<Tab eventKey={ tabs[1] } title={ t("education.education-video") } className="px-2 video-list">
							<VideoList type={ tabs[1] } />
						</Tab>
						<Tab eventKey={ tabs[2] } title={ t("education.education-video-advanced") } className="px-2 video-list">
							<VideoList type={ tabs[2] } />
						</Tab>
						<Tab eventKey={ tabs[3] } title={ t("education.our-services.title") } className="px-2 education-article">
							<Card className="mb-2">
								<Card.Body>
									<div className="col-12 col-xxl-10">
										<h3><Trans i18nKey="education.our-services.title" /></h3>
										<p><Trans i18nKey="education.our-services.intro" /></p>
										<h5><Trans i18nKey="education.our-services.s1-title" /></h5>
										<p><Trans i18nKey="education.our-services.s1-p1" /></p>
										<p>
											<Trans i18nKey="education.our-services.s1-p2-1" />
											{ formatDate("2016-07-03") }
											<Trans i18nKey="education.our-services.s1-p2-2" />
										</p>
										<p>
											<Trans i18nKey="education.our-services.s1-p3-1" />
											{ formatDate("2016-07-03") }
											<Trans i18nKey="education.our-services.s1-p3-2" />
										</p>
										<h5><Trans i18nKey="education.our-services.s2-title" /></h5>
										<p>
											<Trans i18nKey="education.our-services.s2-p1" />
											<span className="education-link" onClick={ () => { setActiveTab("PATTERNS") }} >
												<Trans i18nKey="education.our-services.s2-p2" ></Trans>
											</span>
											<Trans i18nKey="education.our-services.s2-p3" />
										</p>
										<h5><Trans i18nKey="education.our-services.s3-title" /></h5>
										<p><Trans i18nKey="education.our-services.s3-p1" /></p>
										<p>
											<Trans i18nKey="education.our-services.s3-p2-1" />
											{ formatDate("2016-07-04") }
											<Trans i18nKey="education.our-services.s3-p2-2" />
										</p>
										<img src={ trade175Image } className="img-fluid" alt="175" />
										<p><Trans i18nKey="education.our-services.s3-p3" /></p>
										<h5><Trans i18nKey="education.our-services.s4-title" /></h5>
										<p><Trans i18nKey="education.our-services.s4-p1" /></p>
										<p><Trans i18nKey="education.our-services.s4-p2" /></p>
										<ol>
											<li><Trans i18nKey="education.our-services.s4-p2-li1" /></li>
											<li>
												<Trans i18nKey="education.our-services.s4-p2-li2-1" />
												<span className="education-link" onClick={ () => { setActiveTab("PATTERNS") }} >
													<Trans i18nKey="education.our-services.s4-p2-li2-2" ></Trans>
												</span>
												<Trans i18nKey="education.our-services.s4-p2-li2-3" />
											</li>
											<li><Trans i18nKey="education.our-services.s4-p2-li3" /></li>
											<li><Trans i18nKey="education.our-services.s4-p2-li4" /></li>
											<li><Trans i18nKey="education.our-services.s4-p2-li5" /></li>
										</ol>
										<p><Trans i18nKey="education.our-services.s4-p3" /></p>
										<p><Trans i18nKey="education.our-services.s4-p4" /></p>
										<ul>
											<li><Trans i18nKey="education.our-services.s4-p4-li1" /></li>
											<li><Trans i18nKey="education.our-services.s4-p4-li2" /></li>
											<li><Trans i18nKey="education.our-services.s4-p4-li3" /></li>
											<li><Trans i18nKey="education.our-services.s4-p4-li4" /></li>
										</ul>
									</div>
								</Card.Body>
							</Card>
						</Tab>
						<Tab eventKey={ tabs[4] } title={ t("education.patterns.title") } className="px-2 education-article">
							<Card className="mb-2">
								<Card.Body>
									<div className="col-12 col-xxl-10">
										<h3><Trans i18nKey="education.patterns.title" /></h3>
										<p><Trans i18nKey="education.patterns.intro" /></p>
									</div>

									<div className="col-12 col-xxl-10">
										<h5><Trans i18nKey="education.patterns.cwh-title" /></h5>
										<p><Trans i18nKey="education.patterns.cwh-p1" /></p>
										<ol>
											<li><Trans i18nKey="education.patterns.cwh-p1-li1" /></li>
											<li><Trans i18nKey="education.patterns.cwh-p1-li2" /></li>
											<li><Trans i18nKey="education.patterns.cwh-p1-li3" /></li>
											<li><Trans i18nKey="education.patterns.cwh-p1-li4" /></li>
										</ol>

										<img src={ cupWithHandle } className="img-fluid" alt={ t("education.patterns.cwh-title") } />
									</div>
									
									<div className="col-12 col-xxl-10">
										<h5><Trans i18nKey="education.patterns.cwouth-title" /></h5>
										<p><Trans i18nKey="education.patterns.cwouth-p1" /></p>
										<img src={ cupWithoutHandle } className="img-fluid" alt={ t("education.patterns.cwouth-title") } />
									</div>
									
									<div className="col-12 col-xxl-10">
										<h5><Trans i18nKey="education.patterns.f-title" /></h5>
										<p><Trans i18nKey="education.patterns.f-p1" /></p>
										<img src={ flat } className="img-fluid" alt={ t("education.patterns.f-title") } />
									</div>
									
									<div className="col-12 col-xxl-10">
										<h5><Trans i18nKey="education.patterns.dbrd-title" /></h5>
										<p><Trans i18nKey="education.patterns.dbrd-p1" /></p>
										<img src={ doubleDeep } className="img-fluid" alt={ t("education.patterns.dbrd-title") } />
									</div>
								</Card.Body>
							</Card>
						</Tab>
					</Tabs>
				</div>
			</div>
		</>
	);
};

export default Education;
