import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	Error,
	Forbidden,
	MarketSelector,
}  from "../components";

import {
	MarketSnapshot,
	MarketMovementHistory,
} from "../components/marketTrend";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

const tabs = [ "MARKET_SNAPSHOT", "MARKET_OVERVIEW" ];

const MarketTrend = ({ exchange }) => {
	// General Page States
	const [ loading, setLoading ] = useState(true);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	// Data
	const [ marketTrendData, setMarketTrendData ] = useState(null);
	const [ marketTrendHistoryData, setMarketTrendHistoryData ] = useState({
		movement: { },
		tenWeekMA: { },
		fortyWeekMA: { },
		fiftyTwoWeekRecord: { },
	});

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t } = useTranslation();

	const getMarketTrendData = async () => {
		try {
			var marketTrendDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"markettrend/" + exchange,
				"GET"
			);

			if (marketTrendDataResponse.status === "Forbidden") {
				setForbidden(true);
				setLoading(false);
				return;
			} else if (marketTrendDataResponse.status === "Error") {
				setError(true);
				setLoading(false);
				return;
			}

			var marketTrendHistory = {
				movement: {
					dates: marketTrendDataResponse.data.dates,
					upLabel: t('markettrend.data.chart.stock-up'),
					upList: marketTrendDataResponse.data.upList,
					downLabel: t('markettrend.data.chart.stock-down'),
					downList: marketTrendDataResponse.data.downList,
				},
				tenWeekMA: {
					dates: marketTrendDataResponse.data.dates,
					upLabel: t('markettrend.data.chart.on-10-week-ma'),
					upList: marketTrendDataResponse.data.onTenWeekMaList,
					downLabel: t('markettrend.data.chart.under-10-week-ma'),
					downList: marketTrendDataResponse.data.underTenWeekMaList,
				},
				fortyWeekMA: {
					dates: marketTrendDataResponse.data.dates,
					upLabel: t('markettrend.data.chart.on-40-week-ma'),
					upList: marketTrendDataResponse.data.onFortyWeekMaList,
					downLabel: t('markettrend.data.chart.under-40-week-ma'),
					downList: marketTrendDataResponse.data.underFortyWeekMaList,
				},
				fiftyTwoWeekRecord: {
					dates: marketTrendDataResponse.data.dates,
					upLabel: t('markettrend.data.52-week-high'),
					upList: marketTrendDataResponse.data.fiftyTwoWeekHighList,
					downLabel: t('markettrend.data.52-week-low'),
					downList: marketTrendDataResponse.data.fiftyTwoWeekLowList,
				},
			};

			setMarketTrendData(marketTrendDataResponse.data);
			setMarketTrendHistoryData(marketTrendHistory);
			setLoading(false);
		} catch (e) {
			setError(true);
			setLoading(false);
		}
	};

	useEffect(() => {
		getMarketTrendData();
	}, [ ]);

	if (forbidden) {
		return <Forbidden />;
	}

	if (error) {
		return <Error />;
	}

	return (
		<>
			<div className="row">
				<div className="col-12 px-0 overflow-x-hidden">
					<Tabs defaultActiveKey={ tabs[0] } className="mb-3">
						<Tab eventKey={ tabs[0] } title={ t("markettrend.snapshot") } className="px-2 market-trend">
							<MarketSnapshot exchange={ exchange } marketTrendData={ marketTrendData } loading={ loading } />
						</Tab>
						<Tab eventKey={ tabs[1] } title={ t("markettrend.trend-data") } className="px-2 market-trend">
							<MarketMovementHistory marketTrendHistoryData={ marketTrendHistoryData } loading={ loading } />
						</Tab>
					</Tabs>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-xxl-10 mt-2">
					<p className="small text-muted">{ t("markettrend.desc") }</p>
				</div>
			</div>

			<MarketSelector activeExchange={ exchange } linkPrefix="markettrend" />
		</>
	);
};

export default MarketTrend;
