import { useTranslation } from "react-i18next";

import Card from 'react-bootstrap/Card';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import marketUptrendImg from "../../assets/images/market-uptrend.png";
import marketDowntrendImg from "../../assets/images/market-downtrend.png";
import marketUnderPressureImg from "../../assets/images/market-under-pressure.png";
import marketRallyAttemptImg from "../../assets/images/market-rally-attempt.png";

import { formatDate } from "../../util/dateUtil";

const MidLongTermTrend = ({ trend, trendStartDate, loading }) => {
	const { t } = useTranslation();

	const getMarketTrendImg = () => {
		switch (trend) {
			case "UPTREND":
				return marketUptrendImg;
			case "DOWNTREND":
				return marketDowntrendImg;
			case "UNDER_PRESSURE":
				return marketUnderPressureImg;
			case "RALLY_ATTEMPT":
				return marketRallyAttemptImg;
			default:
				return null;
		}
	};

	const getMarketTrendKey = () => {
		switch (trend) {
			case "UPTREND":
				return "uptrend";
			case "DOWNTREND":
				return "downtrend";
			case "UNDER_PRESSURE":
				return "under-pressure";
			case "RALLY_ATTEMPT":
					return "rally-attempt";
			default:
				return null;
		}
	};

	return (
		<Card className="shadow-sm mb-2 mb-lg-0">
			<Card.Body>
				<div className="secondary-trend-card">
					<div className="d-flex align-items-center">
						{
							loading ? null : <img src={ getMarketTrendImg() } className={ "trend-img me-3 " + getMarketTrendKey() } alt={ t("markettrend." + getMarketTrendKey()) } />
						}
						<div className="d-flex flex-column w-100">
								<b className="trend-key">{ t("markettrend.mid-long-term-market-trend") + ": " }{
									loading ? null : t("markettrend." + getMarketTrendKey())
								} </b>
							{
								loading ?
									<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
										<Skeleton count={ 1 } height="1em" />
									</SkeletonTheme> : <span className="trend-since">{ t("markettrend.since", { date: formatDate(trendStartDate) }) }</span>
							}
						</div>
					</div>
				</div>
			</Card.Body>
		</Card>
	)
};

export default MidLongTermTrend;
