import React from 'react';
import ReactDOM from 'react-dom';
import FineticAppRouter, { history } from './FineticAppRouter';
import { Auth0Provider } from "@auth0/auth0-react";
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { CacheLocation } from '@fingerprintjs/fingerprintjs-pro-spa'

import './util/i18n';

require("./assets/sass/finetic-styles.scss");

const onRedirectCallback = (state) => {
	// If using a Hash Router, you need to use window.history.replaceState to
	// remove the `code` and `state` query parameters from the callback url.
	// window.history.replaceState({}, document.title, window.location.pathname);
	history.replace((state && state.returnTo) || window.location.pathname);
};

ReactDOM.render(
	// <FpjsProvider
	// 	loadOptions={{
	// 		apiKey: process.env.REACT_APP_FINGERPRINT_API_KEY,
	// 		region: "ap",
	// 		endpoint: "https://fp.finetic.ai",
	//   }}
	//   cacheLocation={ CacheLocation.SessionStorage }
	//   cacheTimeInSeconds={ 86400 }
	// >
		<Auth0Provider
			domain={ process.env.REACT_APP_AUTH0_DOMAIN }
			clientId={ process.env.REACT_APP_AUTH0_CLIENT_ID }
			redirectUri={ window.location.origin }
			audience={ process.env.REACT_APP_FINETIC_API_URL }
			onRedirectCallback={ onRedirectCallback }
			scope="finlist finlist_hk finlist_us finlist_jp finlist_cn finlist_tw finder finder_hk market_trend market_trend_hk market_trend_us market_trend_jp market_trend_cn market_trend_tw education sunday_club paid beta_access market_movers market_movers_hk affiliate staff admin"
		>
			<FineticAppRouter />
		</Auth0Provider>,
	// </FpjsProvider>,
	document.getElementById('root')
);
