import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronRight,
	faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

function Category({ id, icon, title, children, open = false })
{
	const [ isOpen, setOpen ] = useState(open);

	return (
		<>
			<div onClick={ () => setOpen(!isOpen) } aria-controls={ "category-" + id } aria-expanded={ isOpen } className="sidebar-category d-flex align-items-center py-1">
				<span className={ "chevron " + (isOpen ? " active" : "") } ><FontAwesomeIcon icon={ faChevronRight } transform="shrink-7" /></span>
				<span className="icon mx-1">{ icon }</span>
				<span className="name">{ title }</span>
			</div>
			<Collapse in={ isOpen } >
				<div id={ "category-" + id } >
					{ children }
					<hr className="my-0 mx-3" />
				</div>
			</Collapse>
		</>
	)
}

export default Category;
