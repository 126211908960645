import React from 'react';
import {
	Content,
	Section,
	Table,
	Modal,
	Header,
	Button,
} from '../components';
import {
	FinderRow,
	Filter,
	FinalizedFilter,
} from '../components/finder';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSortUp,
	faSortDown,
	faSort,
	faTrash,
	faPlus,
	faEdit,
	faSave,
	faUndo,
	faArrowUp,
	faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { withAuth0 } from "@auth0/auth0-react";
import { Trans, Translation } from 'react-i18next';

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

class Finder extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			watchlist: [],
			selectedStockCode: "",
			selectedWatchlistId: "",
			newWatchlistName: "",
			buyPrice: "",
			activeStock: null,
			originalList: [],
			list: [],
			filters: [],
			filterSetList: [],
			currentFilterSetId: "",
			selectedFilterSetId: "",
			newFilterSetName: "",
			expanded: { },
			sort: {
				key: "stockCode",
				asc: true
			},
			isLoading: false,
		};

		this.onChangeWatchlist = this.onChangeWatchlist.bind(this);
		this.onChangeWatchlistName = this.onChangeWatchlistName.bind(this);
		this.onChangeBuyPrice = this.onChangeBuyPrice.bind(this);
		this.addToWatchlist = this.addToWatchlist.bind(this);
		this.deleteWatchlistStock = this.deleteWatchlistStock.bind(this);
		this.getUserFilterSetList = this.getUserFilterSetList.bind(this);
		this.getFinderList = this.getFinderList.bind(this);
		this.onAddFilter = this.onAddFilter.bind(this);
		this.onRemoveFilter = this.onRemoveFilter.bind(this);
		this.searchStock = this.searchStock.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleRowClickMobile = this.handleRowClickMobile.bind(this);
		this.openWatchlistModal = this.openWatchlistModal.bind(this);
		this.onChangeSortDirection = this.onChangeSortDirection.bind(this);
		this.createFilterSet = this.createFilterSet.bind(this);
		this.setActiveFilterSet = this.setActiveFilterSet.bind(this);
		this.updateFilterSet = this.updateFilterSet.bind(this);
		this.renameFilterSet = this.renameFilterSet.bind(this);
		this.deleteFilterSet = this.deleteFilterSet.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.onChangeFilterSet = this.onChangeFilterSet.bind(this);
		this.onChangeFilterSetName = this.onChangeFilterSetName.bind(this);
		this.saveFilterSetAs = this.saveFilterSetAs.bind(this);
	}

	componentDidMount()
	{
		this.getUserFilterSetList(true);
		this.getWatchlistInfo();
	}

	async getFinderList()
	{
		if (this.state.filters.length > 0) {
			try {
				var finderDataResponse = await apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"finder/" + this.props.exchange,
					"POST",
					{
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					JSON.stringify(this.state.filters)
				);

				var list = finderDataResponse.data.sort((a, b) => (a[this.state.sort.key] > b[this.state.sort.key]) ? (this.state.sort.asc ? 1 : -1) : (this.state.sort.asc ? -1 : 1));
				var expanded = {};
				
				list.forEach(a => {
					expanded[a.stockCode] = false;
				});

				this.setState({ originalList: list, list: list, expanded: expanded, isLoading: false });
			} catch (e) {
				console.log(e.message);
			}
		} else {
			this.setState({ isLoading: false });
		}
	}

	onAddFilter(filter)
	{
		var filterList = this.state.filters;
		filterList.push(filter);

		this.setState({ isLoading: true, list: [], filters: filterList }, () => {
			this.getFinderList();
		});
	}

	onRemoveFilter(filter)
	{
		var filterList = this.state.filters;
		filterList = filterList.filter(a => a.column !== filter.column);

		this.setState({ isLoading: true, list: [], filters: filterList }, () => {
			this.getFinderList();
		});
	}

	searchStock(event)
	{
		var keyword = event.target.value.trim();

		if (keyword !== "") {
			var newList = this.state.originalList.filter((stock) => stock.stockCode.indexOf(keyword) !== -1 || stock.stockName.toUpperCase().indexOf(keyword.toUpperCase()) !== -1);
			this.setState({ list: newList });
		} else {
			this.setState({ list: this.state.originalList });
		}
	}

	sortData(sortKey, doSort = true)
	{
		var sameKey = this.state.sort.key === sortKey;
		var asc = this.state.sort.asc;

		if (doSort) {
			asc = false;

			if (sameKey) {
				asc = !this.state.sort.asc;
			}
		}

		var newList = this.state.list.sort((a, b) => (a[sortKey] > b[sortKey]) ? (asc ? 1 : -1) : (asc ? -1 : 1));

		var sort = {
			key: sortKey,
			asc: asc
		};

		this.setState({ list: newList, sort: sort });
	}

	onChangeSortDirection(event)
	{
		var sortKey = this.state.sort.key;
		var asc = !this.state.sort.asc;

		var newList = this.state.list.sort((a, b) => (a[sortKey] > b[sortKey]) ? (asc ? 1 : -1) : (asc ? -1 : 1));

		var sort = {
			key: sortKey,
			asc: asc
		};

		this.setState({ list: newList, sort: sort });
	}

	handleRowClick(stockCode)
	{
		var expanded = this.state.expanded;
		expanded[stockCode] = !expanded[stockCode];
		this.setState({ expanded: expanded });
    }

	handleRowClickMobile(stock)
	{
		this.setState({ activeStock: stock }, () => {
			// halfmoon.toggleModal("finder-stock-" + stock.stockCode);
		});
    }

	async getWatchlistInfo()
	{
		try {
			var watchlist = await apiHelper.request(
				this.props.auth0.getAccessTokenSilently,
				this.props.location,
				"watchlist/user",
				"GET"
			);

			this.setState({ watchlist: watchlist.data });
		} catch (e) {
			console.log(e.message);
		}
	}

	openWatchlistModal(stockCode)
	{
		this.setState({ selectedStockCode: stockCode }, () => {
			// halfmoon.toggleModal('watchlist-modal-' + stockCode);
		})
	}

	onChangeWatchlist(event)
	{
		this.setState({ selectedWatchlistId: event.target.value });
	}

	onChangeWatchlistName(event)
	{
		this.setState({ newWatchlistName: event.target.value });
	}

	onChangeBuyPrice(event)
	{
		this.setState({ buyPrice: event.target.value });
	}

	async addToWatchlist(event)
	{
		event.preventDefault();

		try {
			var watchlistId = this.state.selectedWatchlistId;

			if (watchlistId === "new") {
				var createWatchlistResponse = await apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"watchlist/create?watchlistName=" + this.state.newWatchlistName
				);

				watchlistId = createWatchlistResponse.data;

				this.getWatchlistInfo();
			}

			var watchlistDataResponse = await apiHelper.request(
				this.props.auth0.getAccessTokenSilently,
				this.props.location,
				"watchlist/" + watchlistId,
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					stockCode: this.state.selectedStockCode,
					exchange: this.props.exchange,
					buyPrice: this.state.buyPrice,
				})
			);

			if (watchlistDataResponse.status === "success") {
				this.getFinderList();
				// halfmoon.toggleModal("watchlist-modal-" + this.state.selectedStockCode);
				this.setState({ selectedWatchlistId: "", newWatchlistName: "", buyPrice: "" });
			} else {
				alert(watchlistDataResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	async deleteWatchlistStock(watchlistStock)
	{
		try {
			var confirmDelete = window.confirm("Are you sure you want to remove the stock from your watchlist?");

			if (confirmDelete) {
				var watchlistDataResponse = await apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"watchlist/" + watchlistStock.watchlistId + "/" + watchlistStock.watchlistStockId + "/delete"
				);

				if (watchlistDataResponse.status === "success") {
					this.getFinderList();
				} else {
					console.log(watchlistDataResponse);
				}
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	async getUserFilterSetList(selectFirst = false)
	{
		try {
			var filterSetList = await apiHelper.request(
				this.props.auth0.getAccessTokenSilently,
				this.props.location,
				"finder/filter-set/user",
				"GET"
			);

			this.setState({ filterSetList: filterSetList.data }, () => {
				if (selectFirst) {
					if (this.state.filterSetList.length > 0) {
						this.setActiveFilterSet(this.state.filterSetList[0].finderFilterSetId);
					}
				}

				if (this.state.filterSetList.length === 0) {
					this.setState({ currentFilterSetId: "" });
				}
			});
		} catch (e) {
			console.log(e.message);
		}
	}

	async createFilterSet(filterSetName = "")
	{
		if (filterSetName === "") {
			filterSetName = prompt("Filter set name: ");

			if (filterSetName === null) {
				return;
			}
		}

		try {
			if (filterSetName.trim() === "") {
				alert("Please enter a valid name");
				return;
			}

			var createFilterSet = await apiHelper.request(
				this.props.auth0.getAccessTokenSilently,
				this.props.location,
				"finder/filter-set/create",
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					finderFilterSetName: filterSetName,
					filters: this.state.filters
				})
			);

			if (createFilterSet.status === "success") {
				this.getUserFilterSetList();
			} else {
				console.log(createFilterSet);
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	async setActiveFilterSet(filterSetId)
	{
		var filterSet = this.state.filterSetList.filter(filterSet => filterSetId === filterSet.finderFilterSetId)[0];

		document.getElementById("filter-set-" + filterSetId).classList.toggle("active");

		if (this.state.currentFilterSetId !== "") {
			document.getElementById("filter-set-" + this.state.currentFilterSetId).classList.toggle("active");
		}

		this.setState({ isLoading: true, filters: filterSet.filters, currentFilterSetId: filterSetId }, () => {
			this.getFinderList();
		});
	}

	async updateFilterSet(filterSetId = -1, newFilterSetName = "")
	{
		if (filterSetId === -1) {
			filterSetId = this.state.currentFilterSetId;
		}

		if (newFilterSetName === "") {
			newFilterSetName = this.state.filterSetList.filter(filterSet => filterSetId === filterSet.finderFilterSetId)[0].finderFilterSetName;
		}

		try {
			var updateFilterSet = await apiHelper.request(
				this.props.auth0.getAccessTokenSilently,
				this.props.location,
				"finder/filter-set/" + filterSetId,
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					finderFilterSetName: newFilterSetName,
					filters: this.state.filters
				})
			);

			if (updateFilterSet.status === "success") {
				this.getUserFilterSetList();
			} else {
				console.log(updateFilterSet);
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	async renameFilterSet(filterSetId)
	{
		var filterSetName = prompt("Filter set name: ");

		if (filterSetName === null) {
			return;
		}

		if (filterSetName.trim() === "") {
			alert("Please enter a valid name");
			return;
		}

		this.updateFilterSet(filterSetId, filterSetName);
	}

	async deleteFilterSet(filterSetId)
	{
		try {
			var confirmDelete = window.confirm("Are you sure you want to delete this filter set?");

			if (confirmDelete) {
				var deleteFilterSetResponse = await apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"finder/filter-set/" + filterSetId + "/delete"
				);

				if (deleteFilterSetResponse.status === "success") {
					this.setState({ currentFilterSetId: "" }, () => {
						this.getUserFilterSetList(true);
					});
				} else {
					console.log(deleteFilterSetResponse);
				}
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	clearFilters()
	{
		this.setState({ filters: [] });
	}

	onChangeFilterSet(event)
	{
		this.setState({ selectedFilterSetId: event.target.value });
	}

	onChangeFilterSetName(event)
	{
		this.setState({ newFilterSetName: event.target.value });
	}

	async saveFilterSetAs(event)
	{
		event.preventDefault();

		if (this.state.selectedFilterSetId === "new") {
			await this.createFilterSet(this.state.newFilterSetName);
		} else {
			await this.updateFilterSet(Number(this.state.selectedFilterSetId));
		}

		// halfmoon.toggleModal("filter-set-modal");
	}
    
	render()
	{
		return (
			<Translation>
				{
					(t) => (
						<>
							<Modal
								type="user"
								id={ "watchlist-modal-" + this.state.selectedStockCode }
								title={ t("watchlist.action.actions") }
								submitFormId={ "watchlist-modal" }
								submitAction={ t("watchlist.action.add-to-watchlist") }
							>
								<>
									{
										this.state.list.filter((stock, i) => stock.stockCode === this.state.selectedStockCode).length > 0 && this.state.list.filter((stock, i) => stock.stockCode === this.state.selectedStockCode)[0].watchlistData.length > 0 ?
										<>
											<h2>{ t("watchlist.action.delete-from-watchlist") }</h2>
											{
												
													this.state.list.filter((stock, i) => stock.stockCode === this.state.selectedStockCode)[0].watchlistData.map((watchlistStock, i) => (
														<div key={ "watchlist-stock-" + watchlistStock.watchlistStockId } className="d-flex justify-content-between align-content-center my-10 current-watchlist-stock">
															<span>{ watchlistStock.watchlistName } | { t("watchlist.data.buy-price") }: { watchlistStock.buyPrice }</span>
															<Button buttonType="" className="btn-danger" onClick={ () => { this.deleteWatchlistStock(watchlistStock) }} >{ t("watchlist.action.delete") }</Button>
														</div>
													))
											}
										</> : null
									}
									<h2>{ t("watchlist.action.add-to-watchlist") }</h2>
									<form id="watchlist-modal" className="form-inline mw-full" onSubmit={ this.addToWatchlist } >
										<div className="form-group">
											<label className="required w-100" htmlFor="stockcode">{ t("watchlist.data.stock-code") }</label>
											<span id="stockcode">{ this.state.selectedStockCode }</span>
										</div>
										<div className="form-group">
											<label className="required w-100" htmlFor="watchlist">{ t("watchlist.title") }</label>
											<select id="watchlist" className="form-control" value={ this.state.selectedWatchlistId } onChange={ this.onChangeWatchlist } required>
												<option value="" disabled="disabled">{ t("watchlist.action.select-watchlist") }</option>
												{
													this.state.watchlist.map((watchlist, i) => (
														<option key={ "watchlist-" + watchlist.watchlistId } value={ watchlist.watchlistId } >{ watchlist.watchlistName }</option>
													))
												}
												<option value="new" disabled={ this.state.watchlist.length >= 3 } >{ t("watchlist.action.new-watchlist") }</option>
											</select>
										</div>
										{
											this.state.selectedWatchlistId === "new" ?
											<div className="form-group">
												<label className="required w-100" htmlFor="watchlist-name">{ t("watchlist.watchlist-name") }</label>
												<input type="text" className="form-control" placeholder={ t("watchlist.watchlist-name") } id="watchlist-name" value={ this.state.newWatchlistName } onChange={ this.onChangeWatchlistName } required />
											</div> : null
										}
										<div className="form-group">
											<label className="required w-100" htmlFor="buyprice">{ t("watchlist.data.buy-price") }</label>
											<input type="number" inputMode="numeric" className="form-control" placeholder={ t("watchlist.data.buy-price") } id="buyprice" value={ this.state.buyPrice } onChange={ this.onChangeBuyPrice } min={ 0 } step=".01" required />
										</div>
									</form>
								</>
							</Modal>
							<Modal
								type="user"
								id="filter-set-modal"
								title={ t("finder.action.save-as") }
								submitFormId={ "filter-set-save-as-form" }
								submitAction={ t("finder.action.save") }
							>
								<>
									<form id="filter-set-save-as-form" className="form-inline mw-full" onSubmit={ this.saveFilterSetAs } >
										<div className="form-group">
											<label className="required" htmlFor="filter-set">{ t("finder.filter-set") }</label>
											<select id="filter-set" className="form-control" value={ this.state.selectedFilterSetId } onChange={ this.onChangeFilterSet } required>
												<option value="" disabled="disabled">{ t("finder.action.select-filter-set") }</option>
												{
													this.state.filterSetList.map((filterSet, i) => (
														<option key={ "filterSet-" + filterSet.finderFilterSetId } value={ filterSet.finderFilterSetId } >{ filterSet.finderFilterSetName }</option>
													))
												}
												<option value="new" disabled={ this.state.filterSetList.length >= 5 } >{ t("finder.action.new-filter-set") }</option>
											</select>
										</div>
										{
											this.state.selectedFilterSetId === "new" ?
											<div className="form-group">
												<label className="required" htmlFor="filter-set-name">{ t("finder.filter-set-name") }</label>
												<input type="text" className="form-control" placeholder={ t("finder.filter-set-name") } id="filter-set-name" value={ this.state.newFilterSetName } onChange={ this.onChangeFilterSetName } required />
											</div> : null
										}
									</form>
								</>
							</Modal>
							{
								this.state.activeStock ?
								<Modal
									type="fullscreen"
									id={ "finder-stock-" + this.state.activeStock.stockCode }
									title={ this.state.activeStock.stockCode }
								>
									<Section containerType="fluid">
										<div className="col-12">
											<FinderRow key={ "stock-" + this.state.activeStock.stockCode } stock={ this.state.activeStock } onRowClick={ this.handleRowClickMobile } onWatchlistIconClick={ this.openWatchlistModal } card expanded />
										</div>
									</Section>
								</Modal> : null
							}
							<Content>
								<Header>
									<Section containerType="fluid">
										<div className="col-12">
											<div className="tab-container">
												<div className="filter-set-list py-0 pb-lg-10">
													{
														this.state.filterSetList.map((filterSet, i) => (
															<div key={ "filter-set-" + filterSet.finderFilterSetId } id={ "filter-set-" + filterSet.finderFilterSetId } className="filter-set-tab">
																<div className="py-5 pl-10 py-lg-10 pl-lg-15 filter-set-label" onClick={ () => { this.setActiveFilterSet(filterSet.finderFilterSetId) }} >{ filterSet.finderFilterSetName }</div>
																<div className="filter-set-tools py-5 px-10 py-lg-10">
																	<span className="py-5 px-10 p-lg-10" onClick={ () => { this.renameFilterSet(filterSet.finderFilterSetId) }} ><FontAwesomeIcon icon={ faEdit } /></span>
																	<span className="py-5 px-10 p-lg-10" onClick={ () => { this.deleteFilterSet(filterSet.finderFilterSetId) }} ><FontAwesomeIcon icon={ faTrash } /></span>
																</div>
															</div>
														))
													}
													{
														this.state.filterSetList.length < 5 ?
														<div className="add-filter-set py-5 px-10 py-lg-10 px-lg-15" onClick={ () => { this.createFilterSet() }} >
															<b><FontAwesomeIcon icon={ faPlus } /></b>
														</div> : null
													}
												</div>
											</div>
										</div>
										<div className="col-12 d-none d-lg-block">
											<Filter disabledColumns={ this.state.filters.map(filter => filter.column) } addFilter={ this.onAddFilter } />
											{
												this.state.filters.map((filter, i) => (
													<FinalizedFilter key={ "filter-" + filter.column} filter={ filter } onRemoveFilter={ this.onRemoveFilter } />
												))
											}
										</div>
										<div className="col-12 mt-5 d-none d-lg-block">
											<div className="btn-group mx-10" role="group" aria-label={ t("finder.action.save") } >
												<Button buttonType="blue" disabled={ this.state.filters.length === 0 || this.state.currentFilterSetId === "" } onClick={ () => { this.updateFilterSet(this.state.currentFilterSetId) }} ><FontAwesomeIcon icon={ faSave } className="mr-10" />{ t("finder.action.save") }</Button>
												<Button buttonType="blue" disabled={ this.state.filters.length === 0 } onClick={ () => {
													// halfmoon.toggleModal('filter-set-modal')
												}} >{ t("finder.action.save-as") }</Button>
											</div>
											<Button buttonType="orange" disabled={ this.state.filters.length === 0 } onClick={ () => { this.clearFilters() }} >
												<FontAwesomeIcon icon={ faUndo } className="mr-10" />{ t("finder.action.clear") }
											</Button>
										</div>
										<div className="col-12 d-none d-lg-block">
											<Table wrapperClassName="data-table finlist-table pl-10 pr-20">
												<thead>
													<tr>
														<th>
														</th>
														<th className="sortable" onClick={ () => { this.sortData("stockCode") }} >
															<span className="mr-5">{ t("finder.data.ticker") }</span>
															{ this.state.sort.key === "stockCode" ? (this.state.sort.asc ? <FontAwesomeIcon icon={ faSortUp } /> : <FontAwesomeIcon icon={ faSortDown } />) : <FontAwesomeIcon icon={ faSort } /> }
														</th>
														<th className="text-right sortable" onClick={ () => { this.sortData("close") }} >
															<span className="mr-5">{ t("finder.data.close") }</span>
															{ this.state.sort.key === "close" ? (this.state.sort.asc ? <FontAwesomeIcon icon={ faSortUp } /> : <FontAwesomeIcon icon={ faSortDown } />) : <FontAwesomeIcon icon={ faSort } /> }
														</th>
														<th className="text-right sortable" onClick={ () => { this.sortData("closeChange") }} >
															<span className="mr-5 hidden-lg-and-down">{ t("finder.data.change") }</span>
															<span className="mr-5 hidden-xl-and-up">{ t("finder.data.change-abbr") }</span>
															{ this.state.sort.key === "closeChange" ? (this.state.sort.asc ? <FontAwesomeIcon icon={ faSortUp } /> : <FontAwesomeIcon icon={ faSortDown } />) : <FontAwesomeIcon icon={ faSort } /> }
														</th>
														<th className="text-right sortable" onClick={ () => { this.sortData("volume") }} >
															<span className="mr-5 hidden-lg-and-down">{ t("finder.data.volume") }</span>
															<span className="mr-5 hidden-xl-and-up">{ t("finder.data.volume-abbr") }</span>
															{ this.state.sort.key === "volume" ? (this.state.sort.asc ? <FontAwesomeIcon icon={ faSortUp } /> : <FontAwesomeIcon icon={ faSortDown } />) : <FontAwesomeIcon icon={ faSort } /> }
														</th>
														<th className="text-right sortable" onClick={ () => { this.sortData("volumeChange") }} >
															<span className="mr-5 hidden-lg-and-down">{ t("finder.data.change") }</span>
															<span className="mr-5 hidden-xl-and-up">{ t("finder.data.change-abbr") }</span>
															{ this.state.sort.key === "volumeChange" ? (this.state.sort.asc ? <FontAwesomeIcon icon={ faSortUp } /> : <FontAwesomeIcon icon={ faSortDown } />) : <FontAwesomeIcon icon={ faSort } /> }
														</th>
														<th className="text-right sortable" onClick={ () => { this.sortData("percivalScore") }} >
															<span className="mr-5 hidden-lg-and-down"><Trans i18nKey="finlist.data.percival-score" /></span>
															<span className="mr-5 hidden-xl-and-up"><Trans i18nKey="finlist.data.percival-score-abbr" /></span>
															{ this.state.sort.key === "percivalScore" ? (this.state.sort.asc ? <FontAwesomeIcon icon={ faSortUp } /> : <FontAwesomeIcon icon={ faSortDown } />) : <FontAwesomeIcon icon={ faSort } /> }
														</th>
														<th>
														</th>
													</tr>
												</thead>
											</Table>
										</div>
									</Section>
								</Header>
								<Section containerType="fluid" sectionClass="d-lg-none pt-10">
									<div className="col-12">
										<Filter disabledColumns={ this.state.filters.map(filter => filter.column) } addFilter={ this.onAddFilter } />
										<div className="pt-5">
											{
												this.state.filters.map((filter, i) => (
													<FinalizedFilter key={ "filter-" + filter.column} filter={ filter } onRemoveFilter={ this.onRemoveFilter } />
												))
											}
										</div>
									</div>
									<div className="col-8 pt-5">
										<div className="pl-10">
											<div className="btn-group w-full" role="group" aria-label={ t("finder.action.save") } >
												<Button buttonType="blue" disabled={ this.state.filters.length === 0 || this.state.currentFilterSetId === "" } onClick={ () => { this.updateFilterSet(this.state.currentFilterSetId) }} ><FontAwesomeIcon icon={ faSave } className="mr-10" />{ t("finder.action.save") }</Button>
												<Button buttonType="blue" disabled={ this.state.filters.length === 0 } onClick={ () => {
													// halfmoon.toggleModal('filter-set-modal')
												}} >{ t("finder.action.save-as") }</Button>
											</div>
										</div>
									</div>
									<div className="col-4 pt-5 text-right">
										<Button buttonType="orange" disabled={ this.state.filters.length === 0 } className="d-lg-none mr-10" onClick={ () => { this.clearFilters() }} >
											{ t("finder.action.clear-abbr") }
										</Button>
									</div>
									<div className="col-12 px-5 py-5">
										<hr />
									</div>
									<div className="col-8 px-10 pr-0 pb-0">
										<select className="form-control" id="finder-sort-by" value={ this.state.sort.key } onChange={ (e) => { this.sortData(e.target.value) }} >
											<option value="" disabled>{ t("finder.sort.sorting") }</option>
											<option value="stockCode">{ t("finder.sort.sort-by") } { t("finder.data.ticker") }</option>
											<option value="close">{ t("finder.sort.sort-by") } { t("finder.data.close") }</option>
											<option value="closeChange">{ t("finder.sort.sort-by") } { t("finder.data.close") + " " + t("finder.data.change-abbr") }</option>
											<option value="volume">{ t("finder.sort.sort-by") } { t("finder.data.volume") }</option>
											<option value="volumeChange">{ t("finder.sort.sort-by") } { t("finder.data.volume-abbr") + " " + t("finder.data.change-abbr") }</option>
											<option value="percivalScore">{ t("finder.sort.sort-by") } { t("finder.data.percival-score-abbr") }</option>
										</select>
									</div>
									<div className="col-4 pr-10 d-flex align-items-center justify-content-center sort-direction">
										<label htmlFor="sort-direction" className={ "m-0 mr-10 sort-arrow-up" + (this.state.sort.asc ? " sort-active" : "") } >
											<FontAwesomeIcon icon={ faArrowUp } />
										</label>
										<div className="custom-switch">
											<input type="checkbox" id="sort-direction" value="" checked={ !this.state.sort.asc ? "checked" : "" } onChange={ this.onChangeSortDirection } />
											<label htmlFor="sort-direction" className={ (!this.state.sort.asc ? " sort-active" : "") } >
												<FontAwesomeIcon icon={ faArrowDown } />
											</label>
										</div>
									</div>
								</Section>
								<Section containerType="fluid">
									{
										this.state.isLoading ?
										<div className="col-12">
											<div className="px-10 py-5">
												<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
													<Skeleton count={ 5 } height="3em" className="my-5" />
												</SkeletonTheme>
											</div>
										</div>
										: null
									}
									{
										this.state.list.length > 0 && this.state.filters.length > 0 ?
										<>
											<div className="col-12 d-none d-lg-block">
												<Table wrapperClassName="data-table finlist-table pl-10 pr-20 no-header">
													{
														this.state.list.map((stock, i) => (
															<FinderRow key={ "stock-" + stock.stockCode } stock={ stock } expanded={ this.state.expanded[stock.stockCode] } onRowClick={ this.handleRowClick } onWatchlistIconClick={ this.openWatchlistModal } />
														))
													}
												</Table>
											</div>
											<div className="col-12 d-lg-none">
												{
													this.state.list.map((stock, i) => (
														<FinderRow key={ "stock-" + stock.stockCode } stock={ stock } expanded={ this.state.expanded[stock.stockCode] } onRowClick={ this.handleRowClickMobile } onWatchlistIconClick={ this.openWatchlistModal } card />
													))
												}
											</div>
											<div className="col-12">
												<div className="finder-metadata text-center pl-10">
													<p className="secondary-text my-0">{ t("finder.total-records", { count: this.state.list.length }) }</p>
													{
														this.state.list.length > 0 ?
														<p className="secondary-text mt-0">{ t("finder.data-as-at", { date: this.state.list[0].issueDate }) }</p> : null
													}
												</div>
											</div>
										</> :
										<div className="col-12">
											<div className="finder-metadata text-center">
												<p className="secondary-text">{ t("finder.table-empty") }</p>
											</div>
										</div>
									}
								</Section>
							</Content>
						</>
					)
				}
			</Translation>
		);
	}
}

export default withAuth0(Finder);