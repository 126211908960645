import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChartColumn,
	faChartPie,
	faEnvelope,
	faMobileScreen,
	faNewspaper,
	faPlusMinus,
	faQuestionCircle,
	faShoppingCart,
	faUser
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Tools = () => {
	const { t } = useTranslation();

	return (
		<div className="row">
			<div className="col-12">
				<div className="d-flex flex-column justify-content-between">
					<div>
						<ListGroup className="my-2">
							<Link to="/top-shareholding-change" className="list-group-item list-group-item-action more-tools-link">
								<FontAwesomeIcon icon={ faChartPie } className="me-2" fixedWidth />
								{ t("shareholding-change.title") } (HK)
							</Link>

							<Link to="/market-movers-hk" className="list-group-item list-group-item-action more-tools-link">
								<div className="d-flex justify-content-between">
									<div>
										<FontAwesomeIcon icon={ faPlusMinus } className="me-2" fixedWidth />
										{ t("stock-movement.title") } (HK)
									</div>

									<Badge bg="finetic-orange" pill className="me-2 text-uppercase d-flex align-items-center">{ t("util.new-stuff") }</Badge>
								</div>
							</Link>

							<Link to="/stock-news-hk" className="list-group-item list-group-item-action more-tools-link">
								<div className="d-flex justify-content-between">
									<div>
										<FontAwesomeIcon icon={ faNewspaper } className="me-2" fixedWidth />
										{ t("stock-news.title") } (HK)
									</div>

									<Badge bg="finetic-orange" pill className="me-2 text-uppercase d-flex align-items-center">{ t("util.new-stuff") }</Badge>
								</div>
							</Link>

							<Link to="/interactive-chart" className="list-group-item list-group-item-action more-tools-link">
								<FontAwesomeIcon icon={ faChartColumn } className="me-2" fixedWidth />
								{ t("interactive-chart.title") }
							</Link>

							<Link to="/fintrainer" className="list-group-item list-group-item-action more-tools-link">
								<span className="fa-layers fa-fw me-2">
									<FontAwesomeIcon icon={ faMobileScreen } transform="grow-10" fixedWidth />
									<FontAwesomeIcon icon={ faChartColumn } transform="shrink-7 up-2" fixedWidth />
								</span>
								{ t("game.title") }
							</Link>
						</ListGroup>

						<ListGroup className="my-2">
							<Link to="/dashboard" className="list-group-item list-group-item-action more-tools-link">
								<FontAwesomeIcon icon={ faUser } className="me-2" fixedWidth />
								{ t("user.my-profile") }
							</Link>

							<Link to="/subscribe" className="list-group-item list-group-item-action more-tools-link">
								<FontAwesomeIcon icon={ faShoppingCart } className="me-2" fixedWidth />
								{ t("subscribe.title") }
							</Link>

							<Link to="/faq" className="list-group-item list-group-item-action more-tools-link">
								<FontAwesomeIcon icon={ faQuestionCircle } className="me-2" fixedWidth />
								{ t("faq.title") }
							</Link>
						</ListGroup>
					</div>

					<div className="d-flex flex-column align-items-center small text-muted mt-3">
						<ul className="fa-ul">
							<li>
								<a href="https://wa.me/85266575630" className="text-muted text-decoration-none">
									<span className="fa-li"><FontAwesomeIcon icon={ faWhatsapp } className="icon me-2" /></span>
								+852 6657 5630
								</a>
							</li>

							<li>
								<a href="mailto:service@finetic.ai" className="text-muted text-decoration-none">
									<span className="fa-li"><FontAwesomeIcon icon={ faEnvelope } className="icon me-2" /></span>
									service@finetic.ai
								</a>
							</li>
						</ul>

						<div className="copyright mt-2">
							<span>FINETIC™ © { new Date().getFullYear() } { t("all-rights-reserved") }</span><br />
							<a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "terms-and-conditions" } className="text-muted text-decoration-none" target="_blank">{ t("terms-and-conditions") }</a> | <a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "privacy-policy" } className="text-muted text-decoration-none" target="_blank">{ t("privacy-policy") }</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Tools;
