import { useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSave,
	faTimes,
	faPen,
} from '@fortawesome/free-solid-svg-icons';

import { formatPhoneNumberIntl } from 'react-phone-number-input';

const InlineEditor = ({ key, label, defaultValue, onSave, type }) =>
{
	const inputRef = useRef();

	const [ editing, setEditing ] = useState(false);

	const toggleEditMode = (event, cancel = false) => {
		inputRef.current.disabled = !inputRef.current.disabled;

		if (editing) {
			if (cancel) {
				inputRef.current.value = type === "tel" ? formatPhoneNumberIntl(defaultValue) : defaultValue;
			} else {
				onSave(inputRef.current.value, () => {
					inputRef.current.value = type === "tel" ? formatPhoneNumberIntl(defaultValue) : defaultValue;
				});
			}
		}

		setEditing(!editing);
	};

	const onChange = (event) => {
		if (type === "tel") {
			var formatted = formatPhoneNumberIntl(inputRef.current.value);

			if (formatted.length >= inputRef.current.value.length) {
				inputRef.current.value = formatted;
			}
		}
	};

	return (
		<div className="input-group">
			<div className="form-floating">
				<input ref={ inputRef } id={ "inline-edit-" + key } type={ type } className="form-control" aria-label={ label } defaultValue={ type === "tel" ? formatPhoneNumberIntl(defaultValue) : defaultValue } disabled onChange={ onChange } />
				<label htmlFor={ "inline-edit-" + key } >{ label }</label>
			</div>
			{
				editing ?
					<>
						<Button variant="outline-primary" onClick={ toggleEditMode } ><FontAwesomeIcon icon={ faSave } /></Button>
						<Button variant="outline-danger" onClick={ (event) => toggleEditMode(event, true) } ><FontAwesomeIcon icon={ faTimes } /></Button>
					</> :
					<Button variant="outline-primary" onClick={ toggleEditMode } ><FontAwesomeIcon icon={ faPen } /></Button>
			}
		</div>
	)
}

export default InlineEditor;
