import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";

import successImg from '../../../assets/images/success.svg';

const SubscriptionSuccessModal = ({ show, toggle, product }) => {
	const { t } = useTranslation();

	const onHide = () => {
		window.location.reload();
		toggle();
	};

	return (
		<Modal show={ show } onHide={ onHide } centered keyboard={ false } backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{ t("subscribe.purchase-success-title") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex flex-column align-items-center">
					<img src={ successImg } className="w-25" />
					<p className="mt-3">{ t("subscribe.congratulations", { title: product.name }) }</p>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default SubscriptionSuccessModal;
