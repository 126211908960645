import { useTranslation } from "react-i18next";

import Card from 'react-bootstrap/Card';

import Table from "react-bootstrap/Table";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

import { formatPercentage } from "../../util/numberUtil";
import { formatDate } from "../../util/dateUtil";

const ShareholdingChangeTable = ({ title, data, updatedAt, loading }) => {
	const { t, i18n } = useTranslation();

	return (
		<Card>
			<Card.Body>
				<Card.Title>{ title }</Card.Title>
				{
					loading ?
						<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
							<Skeleton count={ 10 } height="2em" className="my-1" />
						</SkeletonTheme> :
						<>
							<span className="shareholding-change-last-updated">{ t("shareholding-change.information-as-at", { date: formatDate(updatedAt) }) }</span>

							<div className="d-none d-lg-block">
								<Table className="shareholding-change-table">
									<thead>
										<tr>
											<th>{ t("shareholding-change.data.stock-shareholder") }</th>
											<th>{ t("shareholding-change.data.change") }</th>
										</tr>
									</thead>

									<tbody>
										{
											data.map(shareholdingChange => (
												<tr>
													<td>
														<b>{ shareholdingChange.stockCode }</b> { i18n.language === "zh" && shareholdingChange.stockNameZh !== "" ? shareholdingChange.stockNameZh : shareholdingChange.stockName }<br />
														<FontAwesomeIcon icon={ faBuilding } className="me-1" />{ shareholdingChange.participantName }
													</td>
													<td>{ formatPercentage(shareholdingChange.shareholdingPercentChange) }</td>
												</tr>
											))
										}
									</tbody>
								</Table>
							</div>

							<div className="shareholding-change-row d-lg-none">
								<hr className="my-2" />
								{
									data.map((shareholdingChange, i) => (
										<div key={ "shareholding-data-" + i } className="mb-2">
											<div className="d-flex flex-column">
												<div><b>{ shareholdingChange.stockCode }</b> { i18n.language === "zh" && shareholdingChange.stockNameZh !== "" ? shareholdingChange.stockNameZh : shareholdingChange.stockName }</div>
												<div className="d-flex justify-content-between">
													<span className="me-1"><FontAwesomeIcon icon={ faBuilding } className="me-1" />{ shareholdingChange.participantName }</span>
													<span className="text-end">{ formatPercentage(shareholdingChange.shareholdingPercentChange) }</span>
												</div>
											</div>
										</div>
									))
								}
							</div>
						</>
				}
			</Card.Body>
		</Card>
	)
};

export default ShareholdingChangeTable;
