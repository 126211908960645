import React from 'react';
import {
	Content,
	Section,
} from '../components';
import RingLoader from "react-spinners/RingLoader";
import fineticLogo from '../assets/images/finetic-logo-light-small.png';

const Login = () => {
	return (
		<Content>
			<Section>
				<div id="loading-container" className="col-12 d-flex flex-column justify-content-around align-items-center">
					<img src={ fineticLogo } className="img-fluid" alt="Finetic" />
					<RingLoader
						color="#FFFFFF"
					/>
					<div></div>
				</div>
			</Section>
		</Content>
	);
};

export default Login;
