import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const ThreeDSecureModal = ({ show, toggle, threeDSecureUrl }) => {
	useEffect(() => {
		window.addEventListener('message', function(ev) {
			if (ev.data === '3DS-authentication-complete') {
				toggle();
			}
		}, false);
	});

	if (threeDSecureUrl === null) {
		return null;
	}

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Body className="shadow">
				<iframe src={ threeDSecureUrl } width="100%" height="400" />
			</Modal.Body>
		</Modal>
	);
};

export default ThreeDSecureModal;
