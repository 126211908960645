import React from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronDown,
	faChevronRight,
	faEye,
} from '@fortawesome/free-solid-svg-icons';
import { Translation } from 'react-i18next';

export default class FinderRow extends React.PureComponent
{
	constructor(props)
	{
		super(props);

		this.state = {
			showFullText: false,
		};
	}

	handleTextClick()
	{
		this.setState({ showFullText: true });
	}

	getStockCode(stockCode)
	{
		return "0".repeat(Math.max(0, 4 - stockCode.length)) + stockCode;
	}

	getPrettyNumber(number)
	{
		var isNegative = number < 0;
		number = Math.abs(number);
		number =  number > 1000000000 ? (number / 1000000000).toFixed(1) + "B" : (number > 1000000 ? (number / 1000000).toFixed(1) + "M" : number.toLocaleString())
		number = isNegative ? "-" + number : number;
		return number;
	}

	getPercentage(percentage)
	{
		return (<span className={ (percentage >= 0 ? "positive" : "negative") + "-growth" } >{ (percentage >= 0 ? "+" : "") + percentage.toFixed(2) }%</span>);
	}

	render()
	{
		const { stock, expanded, card } = this.props;

		return (
			<Translation>
				{
					(t) => (
						<>
							{
								card ?
								<div id={ "stock-card-" + stock.stockCode } className={ "finder-stock-card" + (!expanded ? " m-10 px-10 py-5" : " expanded") } >
									<div className="row">
										<div className="col-10 ticker">
											{ !expanded ? <span className="stock-code">{ this.getStockCode(stock.stockCode) }</span> : null }
											<span className="stock-name"> { stock.stockName }</span>
										</div>
										<div className="col-2 text-right">
											<FontAwesomeIcon icon={ faEye } className={ "watchlist-icon " + (stock.watchlistData.length > 0 ? "text-finetic-orange" : "inactive-watchlist-icon" ) } onClick={ () => { this.props.onWatchlistIconClick(stock.stockCode) }} />
										</div>
									</div>
									<div className={ "row details" + (expanded ? " expanded" : "") } onClick={ () => { if (!expanded) this.props.onRowClick(stock) }} >
										<div className={ expanded ? "col-12" : "col-10" } >
											<div className="row">
												<div className="col-6">
													<div className="d-flex justify-content-between pr-10">
														<div className="label">{ t("finder.data.close") }:</div>
														${ stock.close.toFixed(2) }
													</div>
												</div>
												<div className="col-6">
													<div className="d-flex justify-content-between pl-10">
														<div className="label">{ t("finder.data.volume") }:</div>
														{ this.getPrettyNumber(stock.volume) }
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-6">
													<div className="d-flex justify-content-between pr-10">
														<div className="label">{ t("finder.data.change-abbr") }:</div>
														{ this.getPercentage(stock.closeChange) }
													</div>
												</div>
												<div className="col-6">
													<div className="d-flex justify-content-between pl-10">
														<div className="label">{ t("finder.data.change-abbr") }:</div>
														{ this.getPercentage(stock.volumeChange) }
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-6">
													<div className="d-flex justify-content-between pr-10">
														<div className="label">{ t("finlist.data.percival-score") }:</div>
														{ stock.percivalScore }
													</div>
												</div>
											</div>
											{
												expanded ?
												<>
													<div className="row mt-5">
														<div className="col-12">
															{ stock.subSector }
															{
																this.state.showFullText ?
																<p className="mt-5">
																	{ stock.profileInfo }
																</p> : <div className="mt-5 mb-10"  onClick={ () => { this.handleTextClick() }} >
																	<LinesEllipsis
																		text={ stock.profileInfo }
																		maxLine="2"
																		ellipsis={ t("finlist.data.more") }
																		trimRight
																		basedOn="letters"
																	/>
																</div>
															}
														</div>
													</div>
												</> : null
											}
										</div>
										{
											!expanded ?
											<div className="col-2 d-flex justify-content-end align-items-center">
												<FontAwesomeIcon icon={ faChevronRight } className="expand-chevron" />
											</div> : null
										}
									</div>
								</div>
								: <tbody>
									<tr id={ "stock-" + stock.stockCode } className={ "my-5 " + (expanded ? "" : " info-row-hidden") } >
										<th className="clickable text-center" onClick={ () => this.props.onRowClick(stock.stockCode) } >{ expanded ? <FontAwesomeIcon icon={ faChevronDown } /> : <FontAwesomeIcon icon={ faChevronRight } /> }</th>
										<th className="clickable" onClick={ () => this.props.onRowClick(stock.stockCode) } ><b>{ this.getStockCode(stock.stockCode) }</b><br />{ stock.stockName }</th>
										<td className="text-right">${ stock.close.toFixed(2) }</td>
										<td className="text-right">{ this.getPercentage(stock.closeChange) }</td>
										<td className="text-right">{ this.getPrettyNumber(stock.volume) }</td>
										<td className="text-right">{ this.getPercentage(stock.volumeChange) }</td>
										<td className="text-right">
											<span className="badge bg-finetic-blue badge-pill">{ stock.percivalScore }</span>
										</td>
										<td className="text-center">
											<FontAwesomeIcon icon={ faEye } className={ "watchlist-icon " + (stock.watchlistData.length > 0 ? "text-finetic-orange" : "inactive-watchlist-icon" ) } onClick={ () => { this.props.onWatchlistIconClick(stock.stockCode) }} />
										</td>
									</tr>
									<tr id={ "stock-" + stock.stockCode + "-info-row" } className={ "info-row" + (expanded ? "" : " row-hidden") } >
										<td></td>
										<td colSpan={ 7 }>
											<div className="stock-info">
												<b>{ stock.subSector }</b>
												<p>{ stock.profileInfo }</p>
											</div>
										</td>
									</tr>
								</tbody>
							}
						</>
					)
				}
			</Translation>
		);
	}
}

FinderRow.propTypes = {
	stock: PropTypes.object.isRequired,
	expanded: PropTypes.bool.isRequired,
	onRowClick: PropTypes.func.isRequired,
	onWatchlistIconClick: PropTypes.func.isRequired,
	card: PropTypes.bool,
};

FinderRow.defaultProps = {
	card: false,
};
