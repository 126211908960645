import { useEffect } from 'react';

import MarketTrend from '../MarketTrend';

import { Helmet } from "react-helmet";

const MarketTrendCN = () => {
	useEffect(() => {
		let pageLastViewed = localStorage.getItem("page-last-viewed");
		pageLastViewed = JSON.parse(pageLastViewed);
		pageLastViewed.markettrend.cn = new Date().getTime();
		localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
	}, [ ]);

	return (
		<>
			<Helmet>
				<title>Market Trend (CN) - Finetic App</title>
			</Helmet>
			<MarketTrend exchange="CN" />
		</>
	);
};

export default MarketTrendCN;
