import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SubscribeButton from '../SubscribeButton';

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck, faCog
} from '@fortawesome/free-solid-svg-icons';

import { ClipLoader } from 'react-spinners';

import Odometer from 'react-odometerjs';
import '../../../assets/odometer/odometer-theme-default.css';

import { pricing } from '../../../util/products';

const PlanCard = ({ product, billingPeriodId, existingSubscriptions, cardDetails, loading }) => {
	const { t } = useTranslation();

	return (
		<div className="col-12 my-2">
			<div className="plan">
				<div className="d-flex justify-content-between px-2 mb-3">
					<div className="w-50">
						<b className="plan-title">{ t("subscribe.product-title." + product.id) }</b>
						<div className="d-flex align-items-center plan-price">
							<span className="plan-currency"><sub>HK$</sub></span>
							<Odometer value={ pricing[billingPeriodId][product.id] } format="(ddd).dd" duration={ 600 } animation={ "count" } />
							<span><sub>&nbsp;{ t("subscribe.per-month") }</sub></span>
						</div>
					</div>
					<div className="w-half">
						{
							loading ?
							<div className="d-flex justify-content-center">
								<ClipLoader
									size={ 25 }
									speedMultiplier={ 0.5 }
									color="#005493"
								/>
							</div> : 
							<>
								{
									existingSubscriptions.length > 0 ?
									<Link to={ "/dashboard?subscriptionId=" + existingSubscriptions[0].subscriptionId } className="manage-subscription w-full">
										<div className="d-grid gap-2">
											<Button variant="light">
												<FontAwesomeIcon icon={ faCog } className="me-2" />{ t("subscribe.action.manage") }
											</Button>
										</div>
									</Link> :
									<div className="d-grid gap-2">
										<SubscribeButton product={ product } billingPeriodId={ billingPeriodId } cardDetails={ cardDetails } loading={ loading } mobile />
									</div>
								}
							</>
						}
					</div>
				</div>
				<div className="d-flex">
					<ul className="fa-ul plan-feature pr-10">
						<li>
							<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
							<b>{ t("subscribe.markets-covered.title") }: </b>
							{ t("subscribe.markets-covered." + product.id) }
						</li>
						<li>
							<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
							<b>{ t("subscribe.weekly-stock-list.title") }</b>
							{
								product.id === "free" ?
								": " + t("subscribe.weekly-stock-list." + product.id) : null
							}
							<p>{ t("subscribe.weekly-stock-list.desc") }</p>
						</li>
						{
							product["stock-highlights"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.stock-highlights.title") }</b>
								<p>{ t("subscribe.stock-highlights.desc") }</p>
							</li> : null
						}
						{
							product["market-trend"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.market-trend.title") }</b>
								<p>{ t("subscribe.market-trend.desc") }</p>
							</li> : null
						}
						{
							product["market-performance"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.market-performance.title") }</b>
							</li> : null
						}
						{
							product["ccass-shareholding"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.ccass-shareholding.title") }</b>
							</li> : null
						}
						<li>
							<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
							<b>{ t("subscribe.ccass-shareholding-change.title") }: </b>
							{ t("subscribe.ccass-shareholding-change." + product.id) }
						</li>
						<li>
							<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
							<b>{ t("subscribe.profit-alerts.title") }: </b>
							{ t("subscribe.profit-alerts." + product.id) }
						</li>
						<li>
							<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
							<b>{ t("subscribe.market-movers.title") }: </b>
							{ t("subscribe.market-movers." + product.id) }
						</li>
						{
							product["market-movers-plus"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.market-movers-plus.title") }</b>
							</li> : null
						}
						{
							product["education-video-archive"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.education-video-archive.title") }</b>
							</li> : null
						}
						{
							product["advanced-education-video-archive"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.advanced-education-video-archive.title") }</b>
							</li> : null
						}
						{
							product["sunday-club"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.sunday-club.title") }</b>
								<p>{ t("subscribe.sunday-club.desc") }</p>
							</li> : null
						}
						{
							product["sunday-club-replay"].includes(billingPeriodId) ?
							<li>
								<span className="fa-li text-finetic-blue"><FontAwesomeIcon icon={ faCheck } /></span>
								<b>{ t("subscribe.sunday-club-replay.title") }</b>
							</li> : null
						}
					</ul>
				</div>
			</div>
		</div>
	)
};

export default PlanCard;
