import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import SelectStock from "../SelectStock";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import SelectSearch from "react-select-search";

import { formatDollars } from '../../../util/numberUtil';

import ApiHelper from '../../../api/apiHelper';
const apiHelper = new ApiHelper();

const WatchlistActionModal = ({ show, toggle, watchlistId, stock, modify, stockList = [ ], getWatchlist }) => {
	const buyPriceInputRef = useRef(null);
	const sharesInputRef = useRef(null);
	const totalValueInputRef = useRef();

	const [ loading, setLoading ] = useState(true);
	const [ selectStockList, setSelectStockList ] = useState([ ]);
	const [ selectedStock, setSelectedStock ] = useState(null);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t, i18n } = useTranslation();

	const getStockCode = () => {
		if (stock.exchange === "HKEX") {
			return "0".repeat(Math.max(0, 4 - stock.stockCode.length)) + stock.stockCode;
		}

		return stock.stockCode;
	};

	const onChangeBuyPriceAndShares = () => {
		if (buyPriceInputRef.current.value > 0 && sharesInputRef.current.value > 0) {
			totalValueInputRef.current.value = formatDollars(buyPriceInputRef.current.value * sharesInputRef.current.value);
		} else {
			totalValueInputRef.current.value = "--";
		}
	};

	const changeSelectedStock = (value) => {
		var exchange = value.split("_")[0];
		var stockCode = value.split("_")[1];

		var stock = stockList.filter((stock) => stock.exchange === exchange && stock.stockCode === stockCode)[0];

		setSelectedStock(stock);
	};

	const sortOptions = (keyword = "", initial) => {
		var newStockList = stockList;

		if (keyword != "") {
			newStockList = stockList.filter((stock) => !keyword || stock.stockCode.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 || stock.stockName.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 || stock.stockNameZh.indexOf(keyword) !== -1);
		}

		var results = [ ];

		var numberStockCode = newStockList.filter(a => !isNaN(a.stockCode)).sort((a, b) => Number(a.stockCode) - Number(b.stockCode));

		var charStockCode = newStockList.filter(a => isNaN(a.stockCode)).sort((a, b) => a.stockCode.localeCompare(b.stockCode));

		var charStockNum = charStockCode.filter(a => !isNaN(a.stockCode[0]));
		var charStockChar = charStockCode.filter(a => isNaN(a.stockCode[0]));

		charStockCode = [ ...charStockChar, ...charStockNum ]

		results = !isNaN(keyword) ? [ ...numberStockCode, ...charStockCode ] : [ ...charStockCode, ...numberStockCode ];

		results = results.slice(0, Math.min(results.length, 100));

		results = results.map(stock => ({ value: stock.exchange + "_" + stock.stockCode, name: stock.stockCode + " " + (i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName), stock: stock }))

		if (initial) {
			setSelectStockList(results);
			return;
		}

		return results;
	};

	useEffect(() => {
		if (loading && stockList.length > 0) {
			sortOptions("", true);
			setLoading(false);
		}
	}, [ stockList ]);

	const addToWatchlist = async (event) => {
		event.preventDefault();

		var buyPrice = buyPriceInputRef.current.value;
		var shares = sharesInputRef.current.value;

		try {
			var watchlistDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/" + watchlistId,
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					stockCode: selectedStock.stockCode,
					exchange: selectedStock.exchange,
					buyPrice: buyPrice,
					shares: shares,
				})
			);

			if (watchlistDataResponse.status === "success") {
				getWatchlist();
				toggle();
			} else {
				console.log(watchlistDataResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const modifyWatchlistStock = async (event) => {
		event.preventDefault();

		var buyPrice = buyPriceInputRef.current.value;
		var shares = sharesInputRef.current.value;

		try {
			var modifyWatchlistResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/" + watchlistId + "/" + stock.watchlistStockId,
				"POST",
				{
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				JSON.stringify({
					buyPrice: buyPrice,
					shares: shares,
				})
			);

			if (modifyWatchlistResponse.status === "success") {
				getWatchlist();
				toggle();
			} else {
				console.log(modifyWatchlistResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{ t("watchlist.action.actions") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={ modify ? modifyWatchlistStock : addToWatchlist } >
					{
						modify ?
							<div className="form-floating mb-3">
								<input type="text" className="form-control-plaintext" id="ticker" value={ getStockCode() + " - " + (i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName) } readOnly />
								<label htmlFor="ticker">{ t("watchlist.data.stock-code") }</label>
							</div> : <div className="watchlist-search">
							<SelectSearch
								className="select-search mb-3"
								options={ selectStockList }
								disabled={ selectStockList.length === 0 || stockList.length === 0 }
								placeholder={ t("watchlist.data.stock-code") }
								onChange={ changeSelectedStock }
								filterOptions={ (options) => (s) => sortOptions(s) }
								renderOption={ (props, data, snapshot, className) => <SelectStock props={ props } data={ data } snapshot={ snapshot } className={ className } /> }
								search
							/>
						</div>
					}
					<div className="form-floating mb-3">
						<input ref={ buyPriceInputRef } type="number" inputMode="decimal" className="form-control" id="buy-price" min={ 0 } step=".0001" required onChange={ onChangeBuyPriceAndShares } defaultValue={ modify ? stock.buyPrice : null } />
						<label htmlFor="buy-price">{ t("watchlist.data.buy-price") }</label>
					</div>
					<div className="form-floating mb-3">
						<input ref={ sharesInputRef } type="number" inputMode="numeric" className="form-control" id="shares" min={ 1 } step="1" required onChange={ onChangeBuyPriceAndShares } defaultValue={ modify ? stock.shares : null } />
						<label htmlFor="shares">{ t("watchlist.data.shares") }</label>
					</div>
					<div className="form-floating mb-3">
						<input ref={ totalValueInputRef } type="text" className="form-control-plaintext" id="total-value" readOnly defaultValue={ modify ? formatDollars(stock.buyPrice * stock.shares) : "--" } />
						<label htmlFor="total-value">{ t("watchlist.data.total-value") }</label>
					</div>
					<div className="text-end">
						<Button variant="finetic-blue" type="submit">{ modify ? t("watchlist.action.modify-watchlist") : t("watchlist.action.add-to-watchlist-modal") }</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default WatchlistActionModal;
