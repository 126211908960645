import React from 'react';
import PropTypes from 'prop-types';

const Table = (props) => {
	return (
		<table className={ "table " + props.className } >
			{ props.children }
		</table>
	);
};

export default Table;

Table.propTypes = {
	isResponsive: PropTypes.bool,
	className: PropTypes.string,
};

Table.defaultProps = {
	isResponsive: false,
	className: "",
};
