const apiRoot = process.env.REACT_APP_FINETIC_API_URL + 'tvchart/storage';

function tvStorageApi(getAccessTokenSilently, clientId, userId)
{
	this.getAccessTokenSilently = getAccessTokenSilently;
	this.clientId = clientId;
	this.userId = userId;

	this.getAllCharts = async () => {
		var qs = {
			client: this.clientId,
			user: this.userId,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/charts?' + query;
		
		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data.status === "ok") {
				return Promise.resolve(response.data.data);
			}
		}

		return Promise.reject();
	};

	this.removeChart = async (id) => {
		var qs = {
			client: this.clientId,
			user: this.userId,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/charts/' + id + '/delete?' + query;
		
		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data.status === "ok") {
				return Promise.resolve();
			}
		}

		return Promise.reject();
	};

	this.saveChart = async (chartData) => {
		var qs = {
			client: this.clientId,
			user: this.userId,
		};

		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/charts?' + query;
		
		if (chartData.id) {
			url = apiRoot + '/charts/' + chartData.id + '?' + query;
		}

		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization: "Bearer " + accessToken,
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(chartData),
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data.status === "ok") {
				return Promise.resolve(response.data.id);
			}
		}

		return Promise.reject();
	};

	this.getChartContent = async (id) => {
		var qs = {
			client: this.clientId,
			user: this.userId,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/charts/' + id + '?' + query;
		
		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data.status === "ok") {
				return Promise.resolve(response.data.data.content);
			}
		}

		return Promise.reject();
	};

	this.removeStudyTemplate = async (studyTemplateData) => {
		var qs = {
			client: this.clientId,
			user: this.userId,
			templateName: studyTemplateData.name,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/studytemplates/delete?' + query;
		
		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data.status === "ok") {
				return Promise.resolve();
			}
		}

		return Promise.reject();
	};

	this.getStudyTemplateContent = async (studyTemplateData) => {
		var qs = {
			client: this.clientId,
			user: this.userId,
			templateName: studyTemplateData.name,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/studytemplates?' + query;
		
		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data.status === "ok") {
				return Promise.resolve(response.data.data.content);
			}
		}

		return Promise.reject();
	};

	this.saveStudyTemplate = async (studyTemplateData) => {
		var qs = {
			client: this.clientId,
			user: this.userId,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/studytemplates?' + query;

		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization: "Bearer " + accessToken,
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(studyTemplateData),
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data.status === "ok") {
				return Promise.resolve();
			}
		}

		return Promise.reject();
	};

	this.getAllStudyTemplates = async () => {
		var qs = {
			client: this.clientId,
			user: this.userId,
		};
		
		var query = Object.keys(qs)
			.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');

		var url = apiRoot + '/studytemplates?' + query;
		
		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data.status === "ok") {
				return Promise.resolve(response.data.data);
			}
		}

		return Promise.reject();
	};
}

export default tvStorageApi;
