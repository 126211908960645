import { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	Header,
	Forbidden,
	Error,
	Section,
	Button,
	Table,
} from "../../components";

import { formatDate } from "../../util/dateUtil";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const AffiliateManagement = () => {
	const [ affiliateDetails, setAffiliateDetails ] = useState([ ]);
	const [ couponList, setCouponList ] = useState([ ]);

	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t } = useTranslation();

	const addCouponCodeField = (e) => {
		couponList.push("")
		setCouponList([ ...couponList ]);
	};

	const updateCouponCode = (e, index) => {
		couponList[index] = e.target.value.toUpperCase();
		setCouponList([ ...couponList ]);
	};

	const deleteCoupon = (index) => {
		couponList.splice(index, 1);
		setCouponList([ ...couponList ]);
	}

	const translatePlanName = (productId, billingPeriodId) => {
		switch (productId) {
			case 1:
				productId = "finlist-hk";
				break;
			case 2:
				productId = "finlist-us";
				break;
			case 3:
				productId = "finlist-cn";
				break;
			case 4:
				productId = "finlist-tw";
				break;
			default:
				productId = "finlist-hk";
				break;
		}

		return t("subscribe.product-title." + productId) + " - " + t("subscribe.billing-period." + billingPeriodId);
	}

	const getAffiliateDetails = async () => {
		try {
			var affiliateDetails = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"affiliate/admin",
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify(couponList)
			);

			if (affiliateDetails.status === "Forbidden") {
				setForbidden(true);
				return;
			}
	
			if (affiliateDetails.status === "Error") {
				setError(true);
				return;
			}
	
			setAffiliateDetails(affiliateDetails.data);
		} catch (e) {
			console.log(e.message);
		}
	};

	if (forbidden) {
		location.replace("/");

		return null;
	}

	if (error) {
		return (
			<>
				<Error />
			</>
		);
	}

	return (
		<>
			<Section sectionClass="mx-10 my-5">
				<div className="col-12">
					<div className="row">
						<div className="col-12 col-md-6">
							{
								couponList.map((couponCode, i) => (
									<div className="d-flex align-items-center">
										<input type="text" className="form-control my-10 mr-10" onChange={ e => updateCouponCode(e, i) } value={ couponList[i] }  placeholder="Coupon Code"></input>
										<Button buttonType="blue" onClick={ e => deleteCoupon(i) } ><FontAwesomeIcon icon={ faTrash } /></Button>
									</div>
								))
							}
						</div>
					</div>

					<div className="row my-5">
						<div className="col-12 col-md-6">
							<div className="d-flex justify-content-between">
								<Button buttonType="blue" onClick={ getAffiliateDetails } disabled={ couponList.length == 0 } >Search</Button>
								<Button buttonType="blue" onClick={ addCouponCodeField } ><FontAwesomeIcon icon={ faPlus } /></Button>
							</div>
						</div>
					</div>
				</div>
			</Section>
			{
				affiliateDetails.map(affiliateDetail => (
					<>
						<hr className="mx-10" />
						<Section sectionClass="m-10">
							<div className="col-12">
								<div className="row">
									<div className="col-12">
										<h2>{ affiliateDetail.code }</h2>
									</div>
									<div className="col-12">
										{
											affiliateDetail.salesRecord.map(salesRecord => (
												<div className="row">
													<div className="col-12">
														<hr className="mx-10" />
													</div>
													<div className="col-12 mx-10">
														<b>{ formatDate(salesRecord.month.substring(0, 7)) }</b>
													</div>
													<div className="col-12 col-md-4">
														<div className="d-flex flex-column align-items-center">
															<span>{ t("affiliate.data.plans-sold") }</span>
															<b className="affiliate-stat">{ salesRecord.plansSold }</b>
														</div>
													</div>
													<div className="col-12 col-md-4">
														<div className="d-flex flex-column align-items-center">
															<span>{ t("affiliate.data.generated-revenue") }</span>
															<b className="affiliate-stat">${ salesRecord.income.toFixed(2) }</b>
														</div>
													</div>
													<div className="col-12 col-md-4">
														<div className="d-flex flex-column align-items-center">
															<span>{ t("affiliate.data.commission") }</span>
															<b className="affiliate-stat">{ salesRecord.commission === -1 ? "--" : "$" + salesRecord.commission.toFixed(2) }</b>
														</div>
													</div>
												</div>
											))
										}
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-6">
										<Table>
											<thead>
												<tr>
													<th>{ t("affiliate.data.plan") }</th>
													<th className="text-right">{ t("affiliate.data.customers") }</th>
													<th className="text-right">{ t("affiliate.data.income") }</th>
												</tr>
											</thead>
											<tbody>
												{
													affiliateDetail.planDetails.map(plan => (
														<tr>
															<td>{ translatePlanName(plan.productId, plan.billingPeriodId) }</td>
															<td className="text-right">{ plan.customers }</td>
															<td className="text-right">${ plan.income.toFixed(2) }</td>
														</tr>
													))
												}
											</tbody>
										</Table>
										<a href={ process.env.REACT_APP_STRIPE_COUPON_LINK + affiliateDetail.couponId } target="_blank">{ t("affiliate.data.stripe") }</a>
									</div>
								</div>
							</div>
						</Section>
					</>
				))
			}
		</>
	);
};

export default AffiliateManagement;