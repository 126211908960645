import historyProvider from '../api/historyProvider';

function tvStreamingApi(getAccessTokenSilently, isGame = false)
{
	this.getAccessTokenSilently = getAccessTokenSilently;
	this.currentBarTime = -1;
	this.nextBarTime = -1;
	this.callbackList = [];
	this.isGame = isGame;
	this.endTime = -1;
	this.priceMultiplier = 1;
	this.buySellMarks = [];

	this.setCurrentBarTime = (currentBarTime) => {
		this.currentBarTime = currentBarTime;
	};

	this.setNextBarTime = (nextBarTime) => {
		this.nextBarTime = nextBarTime;
	};

	this.setEndTime = (endTime) => {
		this.endTime = endTime;
	};

	this.setPriceMultiplier = (priceMultiplier) => {
		this.priceMultiplier = priceMultiplier;
	};

	this.setWidget = (widget) => {
		this.widget = widget;
	};

	this.setCurrentSymbol = (symbol) => {
		this.currentSymbol = symbol;

		if (this.isGame) {
			var chart = this.widget.activeChart();
			this.buySellMarks.forEach(patternId => {
				chart.removeEntity(patternId);
			});

			this.buySellMarks = [];
		}
	};

	this.getStockInfo = async (symbolName) => {
		var qs = {
			symbol: symbolName,
		};

		var query = Object.keys(qs)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
             .join('&');

		var url = process.env.REACT_APP_FINETIC_API_URL + 'tvchart/admin/simulator?' + query;
		
		var accessToken = await this.getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var request = await fetch(url, {
			headers: {
				Authorization: "Bearer " + accessToken,
			}
		});

		if (request.status === 200) {
			var response = await request.json();

			if (response.data) {
				this.currentBarTime = response.data.startTime;
				this.nextBarTime = -1;
				this.currentSymbol = symbolName;

				this.widget.activeChart().setResolution(response.data.resolution);
			}
		}
	};

	this.getBars = async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		var from = periodParams.from * 1000;
		var to = periodParams.to * 1000;
		var firstDataRequest = periodParams.firstDataRequest;

		if (firstDataRequest && !this.isGame) {
			await this.getStockInfo(symbolInfo.name);
		}
		
		historyProvider.getBars(symbolInfo, resolution, from, to, firstDataRequest, this.getAccessTokenSilently, true, false, this.isGame)
			.then(history => {
				if (this.currentBarTime !== -1 && this.currentBarTime < to) {
					var bars = history.bars.filter(b => b.time <= this.currentBarTime * 1000);

					if (this.isGame) {
						bars.forEach(b => {
							b.open = b.open * this.priceMultiplier;
							b.high = b.high * this.priceMultiplier;
							b.low = b.low * this.priceMultiplier;
							b.close = b.close * this.priceMultiplier;
							b.volume = b.volume * this.priceMultiplier;
						});
					}

					onHistoryCallback(bars, { noData: bars.length > 0, nextTime: this.currentBarTime });
				} else {
					onHistoryCallback(history.bars, { noData: history.noData, nextTime: history.nextTime });
				}
			}).catch(err => {
				onErrorCallback(err)
			})
	};

	this.subscribeBars = async (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
		this.callbackList.push({
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
		});
	};

	this.unsubscribeBars = async (subscriberUID) => {
		
	};

	this.nextDay = async (callback, noDataCallback) => {
		var symbolData = this.callbackList.filter((s) => s.symbolInfo.name === this.currentSymbol)[0];

		var nextTime = this.getNextWeekdayTimestamp(this.nextBarTime, this.currentBarTime);

		var barResponse = await historyProvider.getBars(symbolData.symbolInfo, "1D", nextTime, nextTime, false, this.getAccessTokenSilently, true, true, this.isGame);

		if (barResponse.noData || (this.isGame && nextTime > this.endTime * 1000)) {
			if (noDataCallback) {
				noDataCallback();
			}

			return;
		}

		if (barResponse.bars.length === 0 && barResponse.nextTime < this.endTime) {
			barResponse = await historyProvider.getBars(symbolData.symbolInfo, "1D", barResponse.nextTime * 1000, barResponse.nextTime * 1000, false, this.getAccessTokenSilently, true, true, this.isGame);

			if (barResponse.noData || (this.isGame && nextTime > this.endTime * 1000)) {
				if (noDataCallback) {
					noDataCallback();
				}
			}
		}

		this.currentBarTime = nextTime / 1000;
		this.nextBarTime = barResponse.nextTime;

		for (var i = 0; i < barResponse.bars.length; i++) {
			var bar = barResponse.bars[i];

			if (this.isGame) {
				bar.open = bar.open * this.priceMultiplier;
				bar.high = bar.high * this.priceMultiplier;
				bar.low = bar.low * this.priceMultiplier;
				bar.close = bar.close * this.priceMultiplier;
				bar.volume = bar.volume * this.priceMultiplier;
			}

			symbolData.onRealtimeCallback(bar);

			if (callback) {
				callback(bar);
			}
		}
	};

	this.nextWeek = async (callback, noDataCallback) => {
		var symbolData = this.callbackList.filter((s) => s.symbolInfo.name === this.currentSymbol)[0];

		var startTime = this.getNextWeekdayTimestamp(this.nextBarTime, this.currentBarTime);
		var endTime = this.getNextFridayTimestamp(startTime);

		var barResponse = await historyProvider.getBars(symbolData.symbolInfo, "1W", startTime, endTime, false, this.getAccessTokenSilently, true, true, this.isGame);

		if (barResponse.noData || (this.isGame && endTime > this.endTime * 1000)) {
			if (noDataCallback) {
				noDataCallback();
			}

			return;
		}
		
		this.currentBarTime = endTime / 1000;
		this.nextBarTime = barResponse.nextTime;

		for (var i = 0; i < barResponse.bars.length; i++) {
			var bar = barResponse.bars[i];

			if (this.isGame) {
				bar.open = bar.open * this.priceMultiplier;
				bar.high = bar.high * this.priceMultiplier;
				bar.low = bar.low * this.priceMultiplier;
				bar.close = bar.close * this.priceMultiplier;
				bar.volume = bar.volume * this.priceMultiplier;
			}

			symbolData.onRealtimeCallback(bar);

			if (callback) {
				callback(bar);
			}
		}
	};

	this.getNextWeekdayTimestamp = (nextTimestamp, currentTimestamp) => {
		var date = new Date(nextTimestamp * 1000);

		if (nextTimestamp == -1) {
			date.setTime(currentTimestamp * 1000);
			date.setDate(date.getDate() + 1);
		}
		
		while (date.getDay() === 0 || date.getDay() === 6) {
			date.setDate(date.getDate() + 1);
		}

		return date.getTime();
	};

	this.getNextFridayTimestamp = (currentTimestamp) => {
		var date = new Date(currentTimestamp);

		var daysPassed = 0;

		while (date.getDay() !== 5 || daysPassed < 3) {
			date.setDate(date.getDate() + 1);

			if (date.getDay() !== 0 || date.getDay() !== 6) {
				daysPassed++;
			}
		}

		return date.getTime();
	};
}

export default tvStreamingApi;
