import React from 'react';
import Finder from '../Finder';

export default class FinderHK extends React.Component
{
	render()
	{
		return (
			<Finder exchange="HKEX" location={ this.props.location } />
		);
	}
}
