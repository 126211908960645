import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './lang/en.json';
import zh from './lang/zh.json';

const resources = {
	en: {
		translation: en,
	},
	zh: {
		translation: zh,
	},
};

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources: resources,
		fallbackLng: 'en',
		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},

		load: 'languageOnly',

		react: {
			transKeepBasicHtmlNodesFor: [
				'br', 'strong', 'i', 'p', 'b'
			],
            useSuspense: false
        }
	});

export default i18n;