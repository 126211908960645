import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import WatchlistActionModal from "./modal/WatchlistActionModal";

import TVChartContainer from "../TVChartContainer";

import Offcanvas from "react-bootstrap/Offcanvas";

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronRight,
	faEdit,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { formatDollars, formatBigNumber, formatPercentage } from '../../util/numberUtil';
import ConfirmationModal from "../modal/ConfirmationModal";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const WatchlistData = ({ watchlistId, stock, mobile = false, getWatchlist }) => {
	const [ showOverlay, setShowOverlay ] = useState(false);
	const [ showModifyWatchlistModal, setShowModifyWatchlistModal ] = useState(false);
	const [ showConfirmDeleteWatchlistStockModal, setShowConfirmDeleteWatchlistStockModal ] = useState(false);

	const { t, i18n } = useTranslation();
	const location = useLocation();
	const { getAccessTokenSilently, userId } = useAuth0();

	const toggleOverlay = () => { setShowOverlay(!showOverlay) };

	const toggleModifyWatchlistModal = (event) => {
		if (event) {
			event.stopPropagation();
		}

		setShowModifyWatchlistModal(!showModifyWatchlistModal);
	};

	const toggleConfirmDeleteWatchlistStockModal = (event) => {
		if (event) {
			event.stopPropagation();
		}

		setShowConfirmDeleteWatchlistStockModal(!showConfirmDeleteWatchlistStockModal);
	};

	const deleteWatchlistStock = async () => {
		try {
			var deleteWatchlistResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/" + watchlistId + "/" + stock.watchlistStockId + "/delete"
			);

			if (deleteWatchlistResponse.status === "success") {
				toggleConfirmDeleteWatchlistStockModal();
				getWatchlist();
			} else {
				console.log(deleteWatchlistResponse);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const getStockCode = () => {
		if (stock.exchange === "HKEX") {
			return "0".repeat(Math.max(0, 4 - stock.stockCode.length)) + stock.stockCode;
		}

		return stock.stockCode;
	};

	const renderOverlay = () => {
		return (
			<Offcanvas show={ showOverlay } onHide={ toggleOverlay } placement="end" scroll className="watchlist-overlay">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						<b className="me-2">{ getStockCode() }</b>
						{ i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName }
						</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div>
						<div>
							<b>{ i18n.language === "zh" && stock.subSectorZh !== "" ? stock.subSectorZh : stock.subSector }</b>
							<p className="mt-1">
								{ i18n.language === "zh" && stock.profileInfoZh !== "" ? stock.profileInfoZh : stock.profileInfo }
							</p>
						</div>
						<hr />
						<div>
							{
								(stock.exchange === "HKEX" || stock.exchange === "TW") ?
								<TVChartContainer
									symbol={ stock.exchange + ":" + stock.stockCode }
									containerId={ "watchlist-chart-" + stock.stockCode }
									autosize={ true }
									className="watchlist-chart"
									disabledFeatures={[
										"header_saveload",
										"header_symbol_search",
										"symbol_search_hot_key",
										"header_chart_type",
										"header_settings",
										"header_compare",
										"header_undo_redo",
										"header_screenshot",
										"header_fullscreen_button",
										"left_toolbar",
										"control_bar",
										"timeframes_toolbar",
										"main_series_scale_menu",
									]}
									getAccessTokenSilently={ getAccessTokenSilently }
									userId={ userId }
								/>
								: <div className="watchlist-chart">
									<AdvancedRealTimeChart
										interval="D"
										symbol={ stock.stockCode }
										style="0"
										autosize
										withdateranges={ true }
										allow_symbol_change={ false }
										save_image={ false }
										hide_side_toolbar={ true }
									/>
								</div>
							}
						</div>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		);
	};

	var cost = stock.buyPrice * stock.shares;
	var marketValue = isNaN(stock.currentPrice) ? -1 : (stock.currentPrice * stock.shares);
	var change = isNaN(stock.currentPrice) ? -1 : ((stock.currentPrice - stock.buyPrice) / stock.buyPrice) * 100;
	var profit = marketValue === -1 ? -1 : marketValue - cost;
	var profitChange = profit === -1 ? -1 : (profit / cost) * 100;

	const renderDesktopRow = () => {
		return (
			<tr className="clickable" onClick={ toggleOverlay } >
				<td>
					<div>
						<b>{ getStockCode() }</b><br />{ i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName }
					</div>
				</td>
				<td className="text-right">{ isNaN(stock.currentPrice) ? "--" : formatDollars(stock.currentPrice) }</td>
				<td className="text-right">{ isNaN(stock.currentVolume) ? "--" : formatBigNumber(stock.currentVolume) }</td>
				<td className="text-right">{ formatDollars(stock.buyPrice) }</td>
				<td className="text-right">{ stock.shares }</td>
				<td className="text-right">{ formatDollars(cost) }</td>
				<td className="text-right">
					{ marketValue === -1 ? "--" : formatDollars(marketValue) }
				</td>
				<td className="text-right">
					{ profit === -1 ? "-- " : formatDollars(profit, true) }<br />
					{ profitChange === - 1 ? "--" : formatPercentage(profitChange) }
				</td>
				<td className="text-center">
					<FontAwesomeIcon icon={ faEdit } className="watchlist-action edit-watchlist-stock-icon me-3" onClick={ toggleModifyWatchlistModal } />
					<FontAwesomeIcon icon={ faTrash } className="watchlist-action remove-from-watchlist-icon" onClick={ toggleConfirmDeleteWatchlistStockModal } />
				</td>
			</tr>
		)
	};

	const renderMobileRow = () => {
		return (
			<div className="watchlist-stock-card my-2 py-1 px-2">
				<div className="row">
					<div className="col-12 d-flex justify-content-between">
						<div className="ticker">
							<span className="stock-code">{ getStockCode() }</span>
							<span className="stock-name"> { i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName }</span>
						</div>
						<div className="text-right">
							<FontAwesomeIcon icon={ faEdit } className="watchlist-action edit-watchlist-stock-icon mx-2" onClick={ toggleModifyWatchlistModal } />
							<FontAwesomeIcon icon={ faTrash } className="watchlist-action remove-from-watchlist-icon ms-2" onClick={ toggleConfirmDeleteWatchlistStockModal } />
						</div>
					</div>
				</div>
				<div className="row details" onClick={ toggleOverlay } >
					<div className="col-10">
						<div className="row">
							<div className="col-6">
								<div className="d-flex justify-content-between pr-10">
									<div className="label">{ t("watchlist.data.price") }:</div>
									{ isNaN(stock.currentPrice) ? "--" : formatDollars(stock.currentPrice) }
								</div>
							</div>
							<div className="col-6">
								<div className="d-flex justify-content-between pl-10">
									<div className="label">{ t("watchlist.data.volume") }:</div>
									{ isNaN(stock.currentVolume) ? "--" : formatBigNumber(stock.currentVolume) }
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="d-flex justify-content-between pr-10">
									<div className="label">{ t("watchlist.data.buy-price") }:</div>
									{ formatDollars(stock.buyPrice) }
								</div>
							</div>
							<div className="col-6">
								<div className="d-flex justify-content-between pl-10">
									<div className="label">{ t("watchlist.data.shares") }:</div>
									{ stock.shares }
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="d-flex justify-content-between pr-10">
									<div className="label">{ t("watchlist.data.cost") }:</div>
									{ formatDollars(cost) }
								</div>
							</div>
							<div className="col-6">
								<div className="d-flex justify-content-between pl-10">
									<div className="label">{ t("watchlist.data.total-value-abbr") }:</div>
										{ marketValue === -1 ? "--" : formatDollars(marketValue) }
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="d-flex justify-content-between pr-10">
									<div className="label">{ t("watchlist.data.profit") }:</div>
									{ profit === -1 ? "-- " : formatDollars(profit, true) }
								</div>
							</div>
							<div className="col-6">
								<div className="d-flex justify-content-between pl-10">
									<div className="label">{ t("watchlist.data.change-abbr") }:</div>
									{ profitChange === - 1 ? "--" : formatPercentage(profitChange) }
								</div>
							</div>
						</div>
					</div>
					<div className="col-2 d-flex justify-content-end align-items-center">
						<FontAwesomeIcon icon={ faChevronRight } className="expand-chevron" />
					</div>
				</div>
			</div>
		)
	};

	return (
		<>
			<WatchlistActionModal show={ showModifyWatchlistModal } toggle={ toggleModifyWatchlistModal } stock={ stock } watchlistId={ watchlistId } modify getWatchlist={ getWatchlist } />
			<ConfirmationModal show={ showConfirmDeleteWatchlistStockModal } toggle={ toggleConfirmDeleteWatchlistStockModal } onConfirm={ deleteWatchlistStock } confirmText={ t("watchlist.confirm-delete") } yesText={ t("watchlist.action.delete") } danger />
			{ mobile ? renderMobileRow() : renderDesktopRow() }
			{ renderOverlay() }
		</>
	);
};

export default WatchlistData;
