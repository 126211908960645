import { useEffect } from "react";

const SubscribeVerify = () => {
	useEffect(() => {
		window.top.postMessage('3DS-authentication-complete');
	}, [ ]);

	return (
		<span>Success</span>
	)
};

export default SubscribeVerify;
