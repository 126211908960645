import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ConfirmationModal = ({ show, toggle, onConfirm, confirmTitle, confirmText, yesText, noText, danger }) => {
	const { t } = useTranslation();

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{ confirmTitle ? confirmTitle : t("confirmation-prompt.title") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ confirmText }
			</Modal.Body>
			<Modal.Footer>
				<Button variant={ danger ? "finetic-blue" : "danger" } onClick={ toggle } className="me-3" >{ danger ? (noText ? noText : t("confirmation-prompt.cancel")) : t("confirmation-prompt.no") }</Button>
				<Button variant={ danger ? "danger" : "finetic-blue" } onClick={ onConfirm } >{ yesText ? yesText : t("confirmation-prompt.yes") }</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmationModal;
