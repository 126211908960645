import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import ManageSubscriptionModal from "./modal/ManageSubscriptionModal";

import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { products } from "../../util/products";
import { formatTime, formatDateObj } from "../../util/dateUtil";

const ActiveSubscription = ({ subscription, cardDetails, getSubscriptions }) => {
	const [ showManageSubscriptionModal, setShowManageSubscriptionModal ] = useState(false);

	const { i18n, t } = useTranslation();
	const location = useLocation();

	const toggleManageSubscriptionModal = () => { setShowManageSubscriptionModal(!showManageSubscriptionModal) };

	useEffect(() => {
		if (location.search !== "") {
			var params = location.search.split("?")[1].split("&");

			if (params !== "") {
				var subscriptionId = params[0].split("=")[1];

				if (subscription.subscriptionId === subscriptionId) {
					toggleManageSubscriptionModal();
				}
			}
		}
	}, [ ]);

	return (
		<>
			<ManageSubscriptionModal show={ showManageSubscriptionModal } toggle={ setShowManageSubscriptionModal } subscription={ subscription } cardDetails={ cardDetails } getSubscriptions={ getSubscriptions } />

			<Card className="mb-2"  onClick={ toggleManageSubscriptionModal } >
				<Card.Body>
					<div className="subscription d-flex justify-content-between align-items-center">
						<div className="d-flex flex-column">
							<div className="d-flex align-items-center">
								<h5 className="mb-1">{ t("subscribe.product-title." + products[subscription.productId]) }</h5>
								<div className="mb-1 ms-2">
									{
										subscription.status === "trialing" ?
											<Badge bg="primary" className="me-2">{ t("user.subscription.trialing") }</Badge> : null
									}

									{
										!subscription.hasAutoRenew ?
											<Badge bg="finetic-yellow" className="me-2">{ t("user.subscription.not-auto-renewing") }</Badge> : null
									}

									{
										subscription.futureBillingPeriodId > 0 ?
											<Badge bg="secondary">{ t("user.subscription.downgrade-scheduled") }</Badge> : null
									}
								</div>
							</div>
							<span>
								{ subscription.hasAutoRenew ?
									<>{ t("user.subscription.next-billing-period") }: { formatDateObj(new Date(subscription.subscriptionPeriodEnd)) + (i18n.language === "en" ? "," : "") + " " + formatTime(new Date(subscription.subscriptionPeriodEnd)) }</> :
									t("user.subscription.expires-at", { date: formatDateObj(new Date(subscription.subscriptionPeriodEnd)) + (i18n.language === "en" ? "," : "") + " " + formatTime(new Date(subscription.subscriptionPeriodEnd)) })
								}
							</span>
						</div>
						<div>
							<FontAwesomeIcon icon={ faChevronRight } className="user-info-edit-icon" />
						</div>
					</div>
				</Card.Body>
			</Card>
		</>
	)
};

export default ActiveSubscription;
