import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import Modal from "react-bootstrap/Modal";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faMedal, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

import { formatPercentage } from '../../../util/numberUtil';

import ApiHelper from '../../../api/apiHelper';
const apiHelper = new ApiHelper();

const SORT_KEY_TOTAL_RETURN = "TOTAL_RETURN";
const SORT_KEY_AVG_RETURN = "AVG_RETURN";

const LeaderboardTable = ({ loading, data, sortKey, setSortKey }) => {
	const [ leaderboardData, setLeaderboardData ] = useState(data);
	const [ sortAsc, setSortAsc ] = useState(true);

	const { t } = useTranslation();

	const onChangeSort = (key) => {
		if (sortKey === key) {
			setSortAsc(!sortAsc);
		} else {
			setSortKey(key);
		}
	};

	const sortData = () => {
		setLeaderboardData([ ...leaderboardData.sort((a, b) => {
			switch (sortKey) {
				case SORT_KEY_TOTAL_RETURN:
					return (a.totalReturn < b.totalReturn ? (sortAsc ? 1 : -1) : (sortAsc ? -1 : 1));
				case SORT_KEY_AVG_RETURN:
					return (a.averageReturn < b.averageReturn ? (sortAsc ? 1 : -1) : (sortAsc ? -1 : 1));
			}
		}) ]);
	};

	useEffect(() => {
		setLeaderboardData(data);
	}, [ data ])

	useEffect(() => {
		sortData();
	}, [ sortAsc ]);

	if (loading) {
		return (
			<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
				<Skeleton count={ 7 } height="2em" className="my-1" />
			</SkeletonTheme>
		);
	}

	if (leaderboardData.length === 0) {
		return <p className="text-center text-finetic-dark">{ t("game.leaderboard.no-one") }</p>;
	}

	return (
		<Table className="game-leaderboard-table">
			<thead>
				<tr>
					<th></th>
					<th>{ t("game.leaderboard.data.name") }</th>
					<th className="sortable text-end text-nowrap" onClick={ (e) => onChangeSort(SORT_KEY_TOTAL_RETURN) } >
						{ t("game.leaderboard.data.total-return") }
						<FontAwesomeIcon icon={ sortKey === SORT_KEY_TOTAL_RETURN ? (sortAsc ? faSortUp : faSortDown) : faSort } className="ms-1" />
					</th>
					<th className="sortable text-end text-nowrap" onClick={ (e) => onChangeSort(SORT_KEY_AVG_RETURN) } >
						{ t("game.leaderboard.data.avg-return") }
						<FontAwesomeIcon icon={ sortKey === SORT_KEY_AVG_RETURN ? (sortAsc ? faSortUp : faSortDown) : faSort } className="ms-1" />
					</th>
				</tr>
			</thead>
			<tbody>
			{
				leaderboardData.map((leaderboardUser, i) => {
					var leaderboardRank = i;
					
					if (!sortAsc) {
						leaderboardRank = leaderboardData.length - i - 1;
					}

					return (
						<tr>
							<th className="text-center">{ leaderboardRank < 3 ? <FontAwesomeIcon icon={ (leaderboardRank === 0 ? faCrown : faMedal) } className={ (leaderboardRank === 0 ? "first" : (leaderboardRank === 1 ? "second" : "third")) } /> : (leaderboardRank + 1) }</th>
							<td className="text-break">{ leaderboardUser.name }</td>
							<td className="text-end">{ formatPercentage(leaderboardUser.totalReturn) }</td>
							<td className="text-end">{ formatPercentage(leaderboardUser.averageReturn) }</td>
						</tr>
					);
				})
			}
			</tbody>
		</Table>
	)
};

const LeaderboardModal = ({ show, toggle, currentPlaylistId }) => {
	const playlistSelect = useRef();

	const [ leaderboardType, setLeaderboardType ] = useState("all-time");
	const [ sortKey, setSortKey ] = useState(SORT_KEY_TOTAL_RETURN);
	const [ data, setData ] = useState([ ]);
	const [ loading, setLoading ] = useState(false);

	const { t } = useTranslation();

	const { getAccessTokenSilently } = useAuth0();	

	const getLeaderboardData = async (playlistId) => {
		setLoading(true);
		setData([ ]);

		try {
			var leaderboardData = await apiHelper.request(
				getAccessTokenSilently,
				null,
				"game/leaderboard?playlistId=" + playlistId + "&isAllTime=" + (leaderboardType === "all-time") + "&sortByTotalReturn=" + (sortKey === SORT_KEY_TOTAL_RETURN) + "&direction=asc",
				"GET"
			);

			setData([ ...leaderboardData.data ]);
			setLoading(false);
		} catch (e) {
			console.log(e.message);
		}
	};

	const onChangePlaylist = (event) => {
		getLeaderboardData(event.target.value);
	};

	useEffect(() => {
		getLeaderboardData(currentPlaylistId);
	}, [ ]);

	useEffect(() => {
		if (playlistSelect.current != null) {
			getLeaderboardData(playlistSelect.current.value);
		}
	}, [ sortKey, leaderboardType ]);

	return (
		<Modal show={ show } onHide={ toggle } centered>
			<Modal.Header closeButton>
				<Modal.Title>{ t("game.leaderboard.title") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel controlId="fintrainer-leaderboard-modal-select-scenario" label={ t("game.actions.select-scenario") } className="mb-3">
					<Form.Select ref={ playlistSelect } defaultValue={ currentPlaylistId } aria-label={ t("game.actions.select-scenario") } onChange={ onChangePlaylist }>
						<option value="1">{ t("game.scenario.easy") }</option>
						<option value="2">{ t("game.scenario.normal") }</option>
						<option value="3">{ t("game.scenario.hard") }</option>
					</Form.Select>
				</FloatingLabel>
				<Tabs
					activeKey={ leaderboardType }
					onSelect={ setLeaderboardType }
					className="justify-content-center mb-3"
					variant="pills"
					mountOnEnter
				>
					<Tab eventKey="all-time" title={ t("game.leaderboard.all-time") } >
						<LeaderboardTable
							loading={ loading }
							data={ data }
							sortKey={ sortKey }
							setSortKey={ setSortKey }
						/>
					</Tab>
					<Tab eventKey="monthly" title={ t("game.leaderboard.monthly") } >
						<LeaderboardTable
							loading={ loading }
							data={ data }
							sortKey={ sortKey }
							setSortKey={ setSortKey }
						/>
					</Tab>
				</Tabs>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="finetic-blue" onClick={ toggle } >OK</Button>
			</Modal.Footer>
		</Modal>
	)
};

export default LeaderboardModal;
