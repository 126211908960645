import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import Card from 'react-bootstrap/Card';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import marketUptrendImg from "../../assets/images/market-uptrend.png";
import marketDowntrendImg from "../../assets/images/market-downtrend.png";
import marketUnderPressureImg from "../../assets/images/market-under-pressure.png";
import marketRallyAttemptImg from "../../assets/images/market-rally-attempt.png";

import { formatDate, formatDateObj } from "../../util/dateUtil";

const seedrandom = require('seedrandom');

const ShortTermTrend = ({ trend, trendStartDate, loading }) => {
	const [ lastUpdateDate, setLastUpdatedDate ] = useState(null);
	const [ lastUpdateMinute, setLastUpdatedMinute ] = useState(null);

	const { t } = useTranslation();

	const getMarketTrendImg = () => {
		switch (trend) {
			case "UPTREND":
				return marketUptrendImg;
			case "DOWNTREND":
				return marketDowntrendImg;
			case "UNDER_PRESSURE":
				return marketUnderPressureImg;
			case "RALLY_ATTEMPT":
				return marketRallyAttemptImg;
			default:
				return null;
		}
	};

	const getMarketTrendKey = () => {
		switch (trend) {
			case "UPTREND":
				return "uptrend";
			case "DOWNTREND":
				return "downtrend";
			case "UNDER_PRESSURE":
				return "under-pressure";
			case "RALLY_ATTEMPT":
				return "rally-attempt";
			default:
				return null;
		}
	};

	const generateMarketTrendUpdateDate = () => {
		var date = new Date();

		var rng = seedrandom(date.getDate());
		var min = Math.floor(rng() * 9);

		if ((date.getHours() < 23) || (date.getHours() >= 23 && (date.getMinutes() < min))) {
			date.setDate(date.getDate() - 1);
		}

		setLastUpdatedDate(date);
		setLastUpdatedMinute(min);
	};

	useEffect(() => {
		generateMarketTrendUpdateDate();
	}, [ ]);

	return (
		<Card className="mb-2 shadow-sm h-100">
			<Card.Body className="p-0">
				<div className="d-flex flex-column">
					<div className="main-trend-card">
						<div className="background-image-wrapper">
							<div className="background-image-overlay">
								{
									!loading ?
										<img src={ getMarketTrendImg() } className={ getMarketTrendKey() } alt={ t("markettrend." + getMarketTrendKey()) } /> : null
								}
							</div>
						</div>

						<div className="d-flex justify-content-between justify-content-lg-start align-items-center p-3 w-100 trend-info position-absolute">
							<div className="trend-img-wrapper d-none d-lg-block me-lg-3">
								{
									loading ?
										<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
											<Skeleton count={ 1 } height="5em" />
										</SkeletonTheme> : <img src={ getMarketTrendImg() } className={ "trend-img " + getMarketTrendKey() } alt={ t("markettrend." + getMarketTrendKey()) } />
								}
							</div>
							<div className="d-flex flex-column justify-content-between w-100">
								<div className="d-flex flex-column">
									<b className="text-uppercase term-key">{ t("markettrend.short-term-market-trend") }</b>
									{
										loading ?
											<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
												<Skeleton count={ 1 } height="1em" />
											</SkeletonTheme> : <b className="trend-key">{ t("markettrend." + getMarketTrendKey()) }</b>
									}
								</div>
								<div className="d-flex flex-column">
									{
										loading ?
											<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
												<Skeleton count={ 2 } height="1em" className="my-0" />
											</SkeletonTheme> :
											<>
												<span className="trend-since">{ t("markettrend.since", { date: formatDate(trendStartDate) }) }</span>
												<span className="trend-last-updated">{ t("markettrend.last-updated", { date: formatDateObj(lastUpdateDate) + " 23:0" + lastUpdateMinute }) }</span>
											</>
									}
								</div>
							</div>

							{
								loading ? null : <img src={ getMarketTrendImg() } className={ "d-lg-none trend-img " + getMarketTrendKey() } alt={ t("markettrend." + getMarketTrendKey()) } />
							}
						</div>
					</div>
					<div className="p-3 mb-0">
					{
						loading ?
							<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
								<Skeleton count={ 3 } height="1.5em" className="my-1" />
							</SkeletonTheme> : <p><Trans i18nKey={ "markettrend." + getMarketTrendKey() + "-desc" } /></p>
					}
					</div>
				</div>
			</Card.Body>
		</Card>
	)
};

export default ShortTermTrend;
