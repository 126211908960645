import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import WatchlistRow from "../WatchlistRow";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { formatDollars } from '../../../util/numberUtil';

import ApiHelper from '../../../api/apiHelper';
const apiHelper = new ApiHelper();

const WatchlistActionModal = ({ show, toggle, stock, exchange, watchlist, getFinList, getWatchlist }) => {
	const watchlistSelectRef = useRef();
	const watchlistNameDivRef = useRef();
	const watchlistNameInputRef = useRef();
	const buyPriceInputRef = useRef({ value: null });
	const sharesInputRef = useRef({ value: null });
	const totalValueInputRef = useRef();

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t, i18n } = useTranslation();

	const getStockCode = () => {
		if (exchange === "HKEX") {
			return "0".repeat(Math.max(0, 4 - stock.stockCode.length)) + stock.stockCode;
		}

		return stock.stockCode;
	};

	const onSelectWatchlist = (event) => {
		if (event.target.value === "new") {
			watchlistNameDivRef.current.classList.remove("d-none");
			watchlistNameInputRef.current.required = true;
		} else {
			watchlistNameDivRef.current.classList.add("d-none");
			watchlistNameInputRef.current.required = false;
		}
	};

	const onChangeBuyPriceAndShares = () => {
		if (buyPriceInputRef.current.value > 0 && sharesInputRef.current.value > 0) {
			totalValueInputRef.current.value = formatDollars(buyPriceInputRef.current.value * sharesInputRef.current.value);
		} else {
			totalValueInputRef.current.value = "--";
		}
	};

	const addToWatchlist = async (event) => {
		event.preventDefault();

		var watchlistId = watchlistSelectRef.current.value;
		var newWatchlistName = watchlistNameInputRef.current.value;
		var buyPrice = buyPriceInputRef.current.value;
		var shares = sharesInputRef.current.value;

		try {
			if (watchlistId === "new") {
				var createWatchlistResponse = await apiHelper.request(
					getAccessTokenSilently,
					location,
					"watchlist/create?watchlistName=" + newWatchlistName
				);

				watchlistId = createWatchlistResponse.data;

				getWatchlist();
			}

			var watchlistDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/" + watchlistId,
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify({
					stockCode: stock.stockCode,
					exchange: exchange,
					buyPrice: buyPrice,
					shares: shares,
				})
			);

			if (watchlistDataResponse.status === "success") {
				getFinList();
				toggle();
			} else {
				console.log(watchlistDataResponse.errors[0].message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	return (
		<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{ t("watchlist.action.actions") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="form-floating mb-3">
					<input type="text" className="form-control-plaintext" id="ticker" value={ getStockCode() + " - " + (i18n.language === "zh" && stock.stockNameZh !== "" ? stock.stockNameZh : stock.stockName) } readOnly />
					<label htmlFor="ticker">{ t("watchlist.data.stock-code") }</label>
				</div>
				<hr />
				{
					stock.watchlistData.length > 0 ?
					<>
						{
							stock.watchlistData.map((watchlistStock, i) => (
								<WatchlistRow stock={ watchlistStock } getFinList={ getFinList } />
							))
						}
						<hr />
					</> : null
				}
				
				<form onSubmit={ addToWatchlist } >
					<div className="form-floating mb-3">
						<select ref={ watchlistSelectRef } id="select-watchlist" className="form-select"  onChange={ onSelectWatchlist } required>
							<option value="" disabled="disabled" selected>{ t("watchlist.action.select-watchlist") }</option>
							{
								watchlist.map((watchlist, i) => (
									<option key={ "watchlist-" + watchlist.watchlistId } value={ watchlist.watchlistId } >{ watchlist.watchlistName }</option>
								))
							}
							<option value="new" disabled={ watchlist.length >= 3 } >{ t("watchlist.action.new-watchlist") }</option>
						</select>
						<label htmlFor="select-watchlist">{ t("watchlist.title") }</label>
					</div>
					<div ref={ watchlistNameDivRef } className="form-floating mb-3 d-none">
						<input ref={ watchlistNameInputRef } type="text" className="form-control" id="watchlist-name" />
						<label htmlFor="watchlist-name">{ t("watchlist.watchlist-name") }</label>
					</div>
					<div className="form-floating mb-3">
						<input ref={ buyPriceInputRef } type="number" inputMode="decimal" className="form-control" id="buy-price" min={ 0 } step=".0001" required onChange={ onChangeBuyPriceAndShares } />
						<label htmlFor="buy-price">{ t("watchlist.data.buy-price") }</label>
					</div>
					<div className="form-floating mb-3">
						<input ref={ sharesInputRef } type="number" inputMode="numeric" className="form-control" id="shares" min={ 1 } step="1" required onChange={ onChangeBuyPriceAndShares } />
						<label htmlFor="shares">{ t("watchlist.data.shares") }</label>
					</div>
					<div className="form-floating mb-3">
						<input ref={ totalValueInputRef } type="text" className="form-control-plaintext" id="total-value" readOnly value="--" />
						<label htmlFor="total-value">{ t("watchlist.data.total-value") }</label>
					</div>
					<div className="text-end">
						<Button variant="primary" className="text-white" type="submit">{ t("watchlist.action.add-to-watchlist") }</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default WatchlistActionModal;
