import { useRef, useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import {
	TVChartContainer,
	Error,
} from '../components';

import {
	Panel,
	LeaderboardModal,
	TutorialModal,
	StockSummaryModal,
	ScenarioSummaryModal,
} from '../components/fintrainer';

import ConfirmationModal from "../components/modal/ConfirmationModal";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faChevronRight,
	faMedal,
	faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import { ClipLoader } from "react-spinners";

import { Helmet } from "react-helmet";
import { formatDollars, formatPercentage } from '../util/numberUtil';

import StreamingApi from '../components/TVChartContainer/streamingApi';

import $ from "jquery";

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

const FinTrainer = () => {
	const playlistSelectDesktop = useRef();
	const playlistSelectMobile = useRef();
	const playlistSelectModal = useRef();

	const nextStockButtonDesktop = useRef();
	const nextDayButtonDesktop = useRef();
	const nextWeekButtonDesktop = useRef();
	const buyButtonDesktop = useRef();
	const sellButtonDesktop = useRef();

	const nextStockButtonMobile = useRef();
	const nextDayButtonMobile = useRef();
	const nextWeekButtonMobile = useRef();
	const buyButtonMobile = useRef();
	const sellButtonMobile = useRef();

	const buttons = [ nextStockButtonDesktop, nextDayButtonDesktop, nextWeekButtonDesktop, buyButtonDesktop, sellButtonDesktop, nextStockButtonMobile, nextDayButtonMobile, nextWeekButtonMobile, buyButtonMobile, sellButtonMobile ];

	const utilButtons = [ nextStockButtonDesktop, nextDayButtonDesktop, nextWeekButtonDesktop, nextStockButtonMobile, nextDayButtonMobile, nextWeekButtonMobile ]
	const buyButtons = [ buyButtonDesktop, buyButtonMobile ];
	const sellButtons = [ sellButtonDesktop, sellButtonMobile ];

	// General Page States
	const [ loading, setLoading ] = useState(true);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);


	// State
	const [ tvChartReady, setTvChartReady ] = useState(false);
	const [ playlistId, setPlaylistId ] = useState(1);
	const [ playlist, setPlaylist ] = useState(null);
	const [ playedList, setPlayedList ] = useState([ ]);
	const [ currentLevel, setCurrentLevel ] = useState(null);
	const [ revealInfo, setRevealInfo ] = useState(null);
	const [ tradeHistory, setTradeHistory ] = useState([ ]);

	const [ currentBuyDate, setCurrentBuyDate ] = useState(-1);
	const [ currentBuyPrice, setCurrentBuyPrice ] = useState(-1);
	const [ currentPrice, setCurrentPrice ] = useState(-1);
	const [ returnRate, setReturnRate ] = useState(0);
	const [ totalReturnRate, setTotalReturnRate ] = useState(0);
	const [ currentDay, setCurrentDay ] = useState(1);

	const [ canBuy, setCanBuy ] = useState(true);

	const [ confirmPlaylistId, setConfirmPlaylistId ] = useState(null);

	const [ rank, setRank ] = useState({
		allTime: -1,
		monthly: -1
	});

	const [ showGameTutorialModal, setShowGameTutorialModal ] = useState(false);
	const [ showLeaderboardModal, setShowLeaderboardModal ] = useState(false);
	const [ showConfirmNextStockModal, setShowConfirmNextStockModal ] = useState(false);
	const [ showConfirmNextScenarioModal, setShowConfirmNextScenarioModal ] = useState(false);
	const [ showConfirmBackToFinListModal, setShowConfirmBackToFinListModal ] = useState(false);
	const [ showStockSummaryModal, setShowStockSummaryModal ] = useState(false);
	const [ showScenarioSummaryModal, setShowScenarioSummaryModal ] = useState(false);

	const { user, getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const history = useHistory();
	const { t } = useTranslation();
	
	const [ streamingApi, setStreamingApi ] = useState(new StreamingApi(getAccessTokenSilently, true));

	const toggleGameTutorialModal = () => { setShowGameTutorialModal(!showGameTutorialModal) };
	const toggleLeaderboardModal = () => { setShowLeaderboardModal(!showLeaderboardModal) };
	const toggleConfirmNextStockModal = () => { setShowConfirmNextStockModal(!showConfirmNextStockModal) };
	const toggleConfirmNextScenarioModal = () => { setShowConfirmNextScenarioModal(!showConfirmNextScenarioModal) };
	const toggleConfirmBackToFinListModal = () => { setShowConfirmBackToFinListModal(!showConfirmBackToFinListModal) };
	const toggleStockSummaryModal = () => { setShowStockSummaryModal(!showStockSummaryModal) };
	const toggleScenarioSummaryModal = () => { setShowScenarioSummaryModal(!showScenarioSummaryModal) };

	const toggleButtonState = (callback = () => {
		buyButtons.filter(buttonRef => buttonRef.current != null).forEach(buttonRef => buttonRef.current.disabled = !canBuy);
		sellButtons.filter(buttonRef => buttonRef.current != null).forEach(buttonRef => buttonRef.current.disabled = canBuy);
	}) => {
		buttons.filter(buttonRef => buttonRef.current != null).forEach(buttonRef => buttonRef.current.disabled = true);
		
		setTimeout(() => {
			utilButtons.filter(buttonRef => buttonRef.current != null).forEach(buttonRef => buttonRef.current.disabled = false);

			if (callback) {
				callback();
				
			}
		}, 1000);
	};

	const onTVChartReady = () => {
		setTvChartReady(true);
		getSimulatorGamePlaylist();

		utilButtons.filter(buttonRef => buttonRef.current != null).forEach(buttonRef => buttonRef.current.disabled = false);

		$('iframe[id^="tradingview_"]').contents().find('.chart-markup-table.time-axis').remove();
	};

	const getSimulatorGamePlaylist = async () => {
		try {
			var simulatorLevel = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"tvchart/game/" + playlistId,
				"GET"
			);

			if (simulatorLevel.status === "success") {
				var playlist = simulatorLevel.data.filter(p => playedList.indexOf(playlistId + "_" + p.levelId) === -1);

				if (showScenarioSummaryModal) {
					toggleScenarioSummaryModal();
				}
				setPlaylist(playlist);
			}
		} catch (e) {
			setError(true);
			setLoading(false);
		}
	};

	const changeSimulatorGameStock = async () => {
		var random = Math.floor((Math.random() * playlist.length));

		var level = playlist[random];

		var ticker = level.exchange + ":" + level.stockCode;
		
		streamingApi.setCurrentBarTime(level.startTime);
		streamingApi.setNextBarTime(-1);
		streamingApi.setCurrentSymbol(ticker);
		streamingApi.setEndTime(level.endTime);
		streamingApi.setPriceMultiplier(level.multiplier);

		setCurrentLevel(level);
		setCurrentDay(1);
		setCanBuy(true);
		setCurrentPrice(-1);
		setCurrentBuyPrice(-1);
		setReturnRate(0);

		streamingApi.widget.chart().setSymbol(ticker);
		setLoading(false);
	};

	const getRank = async () => {
		try {
			var rankData = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"game/rank?playlistId=" + playlistId,
				"GET",
			);

			if (rankData.status === "Forbidden") {
				setForbidden(true);
			} else if (rankData.status === "success") {
				rank.allTime = rankData.data.allTimeRank;
				rank.monthly = rankData.data.monthlyRank;

				setForbidden(false);
				setRank({ ...rank });
			}
		} catch (e) {
			console.log(e);
		}
	};

	const saveTradeRecord = async (tradeRecord) => {
		try {
			await apiHelper.request(
				getAccessTokenSilently,
				location,
				"game/save",
				"POST",
				{
					'Accept': 'application/json',
    				'Content-Type': 'application/json',
				},
				JSON.stringify(tradeRecord)
			);
		} catch (e) {
			console.log(e);
		}
	};

	const getStockRevealInfo = async (tradeHistory) => {
		if (currentLevel != null) {
			try {
				var simulatorLevelInfo = await apiHelper.request(
					getAccessTokenSilently,
					location,
					"tvchart/game/" + currentLevel.playlistId + "/" + currentLevel.levelId,
					"GET"
				);
	
				if (simulatorLevelInfo.status === "success") {
					var revealInfo = simulatorLevelInfo.data;
					var stockRecord = tradeHistory.filter(t => t.playlistId == currentLevel.playlistId && t.stockCode === currentLevel.stockCode && t.exchange === currentLevel.exchange);
					var stockReturn = 0;
					stockRecord.forEach(t => {
						stockReturn += t.returnRate
					});

					revealInfo.trades = stockRecord.length;
					revealInfo.return = stockReturn;

					setRevealInfo(revealInfo);
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	const concludeCurrentTrade = () => {
		var returnRate = ((currentPrice - currentBuyPrice) / currentBuyPrice) * 100;
		var totalReturnRate = 0;

		var currentTrade = {
			playlistId: currentLevel.playlistId,
			levelId: currentLevel.levelId,
			stockCode: currentLevel.stockCode,
			exchange: currentLevel.exchange,
			buyDate: currentBuyDate,
			buyPrice: currentBuyPrice,
			actualBuyPrice: currentBuyPrice / currentLevel.multiplier,
			sellDate: streamingApi.currentBarTime * 1000,
			sellPrice: currentPrice,
			actualSellPrice: currentPrice / currentLevel.multiplier,
			returnRate: returnRate,
		};

		saveTradeRecord(currentTrade);

		tradeHistory.forEach(h => {
			totalReturnRate += h.returnRate
		});

		totalReturnRate += returnRate;

		var newTradeHistory = [ ...tradeHistory, currentTrade ];

		setTradeHistory(newTradeHistory);
		setCurrentBuyDate(-1);
		setCurrentBuyPrice(-1);
		setCurrentPrice(-1);
		setReturnRate(0);
		setCanBuy(true);
		setTotalReturnRate(totalReturnRate);

		getStockRevealInfo(newTradeHistory);

		var levelId = currentLevel.playlistId + "_" + currentLevel.levelId;

		setPlayedList([ ...playedList, levelId ]);
		setPlaylist(playlist.filter(p => p.levelId !== currentLevel.levelId));
	};

	const onConfirmNextStock = async () => {
		toggleConfirmNextStockModal();

		if (!canBuy) {
			concludeCurrentTrade();
		} else {
			var levelId = currentLevel.playlistId + "_" + currentLevel.levelId;

			getStockRevealInfo(tradeHistory);

			setPlayedList([ ...playedList, levelId ]);
			setPlaylist(playlist.filter(p => p.levelId !== currentLevel.levelId));
		}
	};

	const onConfirmNextScenario = () => {
		toggleConfirmNextScenarioModal();

		if (!canBuy) {
			concludeCurrentTrade();
		} else {
			var levelId = currentLevel.playlistId + "_" + currentLevel.levelId;

			getStockRevealInfo(tradeHistory);

			setPlayedList([ ...playedList, levelId ]);
			setPlaylist(playlist.filter(p => p.levelId !== currentLevel.levelId));
		}

		setPlaylistId(confirmPlaylistId);
	};

	const onNoData = () => {
		if (!canBuy) {
			concludeCurrentTrade();
		} else {
			var levelId = currentLevel.playlistId + "_" + currentLevel.levelId;

			getStockRevealInfo(tradeHistory);

			setPlayedList([ ...playedList, levelId ]);
			setPlaylist(playlist.filter(p => p.levelId !== currentLevel.levelId));
		}
	};

	const confirmNextScenario = (event) => {
		setConfirmPlaylistId(event.target.value);
		toggleConfirmNextScenarioModal();
	};

	const onClickNextDay = () => {
		toggleButtonState();

		var dayCount = 0;

		streamingApi.nextDay((bar) => {
			dayCount++;

			if (!canBuy) {
				var returnRate = ((bar.close - currentBuyPrice) / currentBuyPrice) * 100;

				var totalReturnRate = 0;

				tradeHistory.forEach(t => {
					totalReturnRate += t.returnRate
				});

				totalReturnRate += returnRate;

				setReturnRate(returnRate);
				setTotalReturnRate(totalReturnRate);
			}

			setCurrentDay(currentDay + dayCount);
			setCurrentPrice(bar.close);
		}, onNoData);

	};

	const onClickNextWeek = () => {
		toggleButtonState();

		var dayCount = 0;

		streamingApi.nextWeek((bar) => {
			dayCount++;

			if (!canBuy) {
				var returnRate = ((bar.close - currentBuyPrice) / currentBuyPrice) * 100;

				var totalReturnRate = 0;

				tradeHistory.forEach(t => {
					totalReturnRate += t.returnRate
				});

				totalReturnRate += returnRate;

				setReturnRate(returnRate);
				setTotalReturnRate(totalReturnRate);
			}

			setCurrentDay(currentDay + dayCount);
			setCurrentPrice(bar.close);
		}, onNoData);

	};

	const onClickBuy = (event) => {
		setCanBuy(false);

		streamingApi.nextDay((bar) => {
			var buyShapeId = streamingApi.widget.activeChart().createShape({ time: bar.time / 1000, price: bar.low }, { shape: "arrow_up", text: "B", lock: true, overrides: { color: 'green', backgroundColor: 'green' }});
			streamingApi.buySellMarks.push(buyShapeId);

			var returnRate = ((bar.close - bar.open) / bar.open) * 100;

			var totalReturnRate = 0;

			tradeHistory.forEach(t => {
				totalReturnRate += t.returnRate
			});

			totalReturnRate += returnRate;

			setCurrentDay(currentDay + 1);
			setCurrentBuyDate(bar.time);
			setCurrentBuyPrice(bar.open);
			setCurrentPrice(bar.close);
			setReturnRate(returnRate);
			setTotalReturnRate(totalReturnRate);
		}, onNoData);
	};

	const onClickSell = (event) => {
		setCanBuy(true);

		streamingApi.nextDay((bar) => {
			var sellShapeId = streamingApi.widget.activeChart().createShape({ time: bar.time / 1000, price: bar.high }, { shape: "arrow_down", text: "S", lock: true, overrides: { color: 'red', backgroundColor: 'red' }});
			streamingApi.buySellMarks.push(sellShapeId);

			var returnRate = ((bar.open - currentBuyPrice) / currentBuyPrice) * 100;
			var totalReturnRate = 0;

			var currentTrade = {
				playlistId: currentLevel.playlistId,
				levelId: currentLevel.levelId,
				stockCode: currentLevel.stockCode,
				exchange: currentLevel.exchange,
				buyDate: currentBuyDate,
				buyPrice: currentBuyPrice,
				actualBuyPrice: currentBuyPrice / currentLevel.multiplier,
				sellDate: bar.time,
				sellPrice: bar.open,
				actualSellPrice: bar.open / currentLevel.multiplier,
				returnRate: returnRate,
			};

			saveTradeRecord(currentTrade);

			tradeHistory.forEach(h => {
				totalReturnRate += h.returnRate
			});

			totalReturnRate += returnRate;

			setTradeHistory([ ...tradeHistory, currentTrade ]);
			setCurrentDay(currentDay + 1);
			setCurrentBuyDate(-1);
			setCurrentBuyPrice(-1);
			setCurrentPrice(bar.close);
			setReturnRate(0);
			setTotalReturnRate(totalReturnRate);
		}, onNoData);
	};

	useEffect(() => {
		buttons.filter(buttonRef => buttonRef.current != null).forEach(buttonRef => buttonRef.current.disabled = true);

		getRank();
		$("body").addClass("bg-finetic-blue");
		$("#header-finetic").addClass("fintrainer-mobile-header-override");
		$("#main-container").addClass("fintrainer-mobile-body-override");
		$("#main-content").addClass("fintrainer-mobile-main-content-override");
		$("#finetic-navbar").addClass("d-none");

		return () => {
			$("body").removeClass("bg-finetic-blue");
			$("#header-finetic").removeClass("fintrainer-mobile-header-override");
			$("#main-container").removeClass("fintrainer-mobile-body-override");
			$("#main-content").removeClass("fintrainer-mobile-main-content-override");
			$("#finetic-navbar").removeClass("d-none");
		}
	}, [ ]);

	useEffect(() => {
		if (tvChartReady) {
			getSimulatorGamePlaylist();
			getRank();
		}
	}, [ playlistId ]);

	useEffect(() => {
		if (playlist == null) return;

		if (playlist.length > 0) {
			changeSimulatorGameStock();
		} else {
			toggleScenarioSummaryModal();
		}
	}, [ playlist ]);

	useEffect(() => {
		if (revealInfo) {
			toggleStockSummaryModal();
		}
	}, [ revealInfo ]);

	useEffect(() => {
		toggleButtonState(() => {
			buyButtons.filter(buttonRef => buttonRef.current != null).forEach(buttonRef => buttonRef.current.disabled = !canBuy);
			sellButtons.filter(buttonRef => buttonRef.current != null).forEach(buttonRef => buttonRef.current.disabled = canBuy);
		});
	}, [ canBuy ]);

	if (forbidden) {
		return (
			<div className="mt-5">
				<Error />
			</div>
		);
	}

	return <>
		<Helmet>
			<title>FinTrainer - Finetic App</title>
		</Helmet>

		<TutorialModal show={ showGameTutorialModal } toggle={ toggleGameTutorialModal } />
		<LeaderboardModal currentPlaylistId={ playlistId } show={ showLeaderboardModal } toggle={ toggleLeaderboardModal } />
		<ConfirmationModal show={ showConfirmNextStockModal } toggle={ toggleConfirmNextStockModal } onConfirm={ onConfirmNextStock } confirmText={ t("game.confirm.next-stock") } />
		<ConfirmationModal show={ showConfirmNextScenarioModal } toggle={ toggleConfirmNextScenarioModal } onConfirm={ onConfirmNextScenario } confirmText={ t("game.confirm.next-scenario") } />
		<ConfirmationModal show={ showConfirmBackToFinListModal } toggle={ toggleConfirmBackToFinListModal } onConfirm={ ()=>{ history.push("/") } } confirmText={ t("game.confirm.back-to-finlist") } />

		{
			revealInfo ?
				<StockSummaryModal show={ showStockSummaryModal } toggle={ toggleStockSummaryModal } stockInfo={ revealInfo } /> : null
		}

		<ScenarioSummaryModal show={ showScenarioSummaryModal } toggle={ toggleScenarioSummaryModal } totalReturnRate={ totalReturnRate } totalTrades={ tradeHistory.length } playlistSelectRef={ playlistSelectModal } confirmNextScenario={ confirmNextScenario } />

		<div className="row">
			<div className="col-xxl-9 col-lg-8 col-12 px-0">
				<div className={ !tvChartReady ? "d-flex justify-content-center align-items-center vh-100" : "d-none" } >
					<ClipLoader size={ 50 } speedMultiplier={ 0.5 } color="#005493" />
				</div>
				<TVChartContainer
					className={ !tvChartReady ? "d-none" : "simulator-game-chart" }
					streamingApi={ streamingApi }
					onReadyCallback={ onTVChartReady }
					autosize={ true }
					disabledFeatures={[ "use_localstorage_for_settings", "header_symbol_search", "header_settings", "header_compare", "header_undo_redo", "header_screenshot", "header_fullscreen_button", "header_saveload", "left_toolbar", "edit_buttons_in_legend", "main_series_scale_menu" ]}
					enabledFeatures={[  ]}
					getAccessTokenSilently={ getAccessTokenSilently }
					userId={ user.sub }
					hideRSLine
				/>
			</div>

			<div className="col-xxl-3 col-lg-4 col-12">
				<div className="d-lg-none simulator-game-info">
					<div className="my-2 d-flex justify-content-between">
						<span onClick={ toggleConfirmBackToFinListModal } className="action-link"><FontAwesomeIcon icon={ faChevronLeft } /><FontAwesomeIcon icon={ faChevronLeft } className="me-1" />{ t("game.back-to-finlist") }</span>
						<span onClick={ () => { toggleLeaderboardModal() }} className="action-link"><FontAwesomeIcon icon={ faMedal } className="me-1" />{ t("game.leaderboard.view-abbr") }</span>
						<span className="action-link" onClick={ toggleGameTutorialModal } ><FontAwesomeIcon icon={ faQuestionCircle } className="me-1" />{ t("game.tutorial.title") }</span>
					</div>
					<hr className="mt-1 mb-2" />
					<div className="d-flex justify-content-between">
						<div className="w-50 pe-1">
							<div className="d-grid gap-2">
								<select ref={ playlistSelectMobile } className="form-select" value={ playlistId } onChange={ confirmNextScenario } >
									<option value="1">{ t("game.scenario.title") + ": " + t("game.scenario.easy") }</option>
									<option value="2">{ t("game.scenario.title") + ": " + t("game.scenario.normal") }</option>
									<option value="3">{ t("game.scenario.title") + ": " + t("game.scenario.hard") }</option>
								</select>
							</div>
						</div>
						<div className="w-50 ps-1">
							<div className="d-grid gap-2">
								<Button ref={ nextStockButtonMobile } variant="outline-finetic-blue" onClick={ toggleConfirmNextStockModal } className="ms-1">{ t("game.actions.next-stock") }</Button>
							</div>
						</div>
					</div>
					<hr className="my-2" />
					<div className="d-flex justify-content-between">
						<div className="d-flex flex-column w-50 me-1 simulator-game-stats">
							<div className="d-flex justify-content-between">
								<b>{ t("game.stats.day") }:</b>
								<span>{ currentDay }</span>
							</div>

							<div className="d-flex justify-content-between">
								<b>{ t("game.stats.buy-price") }</b>
								<span>{ currentBuyPrice === -1 ? "--" : formatDollars(currentBuyPrice) }</span>
							</div>

							<div className="d-flex justify-content-between">
								<b>{ t("game.stats.current-price") }:</b>
								<span>{ currentPrice === -1 ? "--" : formatDollars(currentPrice) }</span>
							</div>

							<div className="d-flex justify-content-between">
								<b>{ t("game.stats.all-time-rank") }:</b>
								<span>{ rank.allTime === -1 ? "--" : (rank.allTime === -2 ? "> 20" : rank.allTime) }</span>
							</div>
						</div>

						<div className="d-flex flex-column w-50 ms-1 simulator-game-stats">
							<div className="d-flex justify-content-between">
								<b>{ t("game.stats.return") }:</b>
								{ formatPercentage(returnRate) }
							</div>

							<div className="d-flex justify-content-between">
								<b>{ t("game.stats.total-return") }:</b>
								{ formatPercentage(totalReturnRate) }
							</div>

							<div className="d-flex justify-content-between">
								<b>{ t("game.stats.total-trades") }:</b>
								<span>{ tradeHistory.length }</span>
							</div>

							<div className="d-flex justify-content-between">
								<b>{ t("game.stats.monthly-rank") }:</b>
								<span>{ rank.monthly === -1 ? "--" : (rank.monthly === -2 ? "> 20" : rank.monthly) }</span>
							</div>
						</div>
					</div>
					<hr className="my-2" />
					<div className="d-flex justify-content-between">
						<div className="w-50 pe-1">
							<div className="d-grid gap-2">
								<Button ref={ nextDayButtonMobile } variant="finetic-blue" onClick={ onClickNextDay } >{ t("game.actions.next-day") }<FontAwesomeIcon icon={ faChevronRight } className="ms-1" /></Button>
							</div>
						</div>
						<div className="w-50 ps-1">
							<div className="d-grid gap-2">
								<Button ref={ nextWeekButtonMobile } variant="finetic-blue" onClick={ onClickNextWeek } >{ t("game.actions.next-week") }<FontAwesomeIcon icon={ faChevronRight } className="ms-1" /><FontAwesomeIcon icon={ faChevronRight } /><FontAwesomeIcon icon={ faChevronRight } /></Button>
							</div>
						</div>
					</div>
					<div className="d-grid gap-2 mt-1">
						<Button ref={ buyButtonMobile } variant="positive" className={ !canBuy ? "d-none" : "" } onClick={ onClickBuy } >{ t("game.actions.buy") }</Button>
						<Button ref={ sellButtonMobile } variant="negative" className={ canBuy ? "d-none" : "" } onClick={ onClickSell } >{ t("game.actions.sell") }</Button>
					</div>
				</div>

				<div className="my-2 simulator-panel d-none d-lg-block">
					<Panel>
						<h1 className="my-1">{ t("game.title") }</h1>
						<span className="game-how-to-play action-link" onClick={ toggleGameTutorialModal } ><FontAwesomeIcon icon={ faQuestionCircle } className="me-1" />{ t("game.tutorial.title") }</span>
						<hr />
						<div className="form-floating">
							<select ref={ playlistSelectDesktop } id="scenario-select" className="form-select" value={ playlistId } onChange={ confirmNextScenario } >
								<option value="1">{ t("game.scenario.easy") }</option>
								<option value="2">{ t("game.scenario.normal") }</option>
								<option value="3">{ t("game.scenario.hard") }</option>
							</select>
							<label htmlFor="scenario-select">{ t("game.actions.select-scenario") }</label>
						</div>
						
						<div className="d-grid gap-2">
							<Button ref= { nextStockButtonDesktop } variant="finetic-blue" onClick={ toggleConfirmNextStockModal } className="mt-3">{ t("game.actions.next-stock") }</Button>
						</div>
					</Panel>
				</div>
				<div className="my-2 simulator-panel d-none d-lg-block">
					<Panel>
						<div className="d-flex mb-2">
							<div className="w-50 pe-1">
								<div className="d-grid gap-2">
									<Button ref={ nextDayButtonDesktop } variant="finetic-blue" onClick={ onClickNextDay } >{ t("game.actions.next-day") }</Button>
								</div>
							</div>
							<div className="w-50 ps-1">
								<div className="d-grid gap-2">
									<Button ref={ nextWeekButtonDesktop } variant="finetic-blue" onClick={ onClickNextWeek } >{ t("game.actions.next-week") }</Button>
								</div>
							</div>
						</div>
						<div className="d-flex">
							<div className="w-50 pe-1">
								<div className="d-grid gap-2">
									<Button ref={ buyButtonDesktop } variant="positive" onClick={ onClickBuy } >{ t("game.actions.buy") }</Button>
								</div>
							</div>
							<div className="w-50 ps-1">
								<div className="d-grid gap-2">
									<Button ref={ sellButtonDesktop } variant="negative" onClick={ onClickSell } >{ t("game.actions.sell") }</Button>
								</div>
							</div>
						</div>
					</Panel>
				</div>
				<div className="my-2 simulator-panel d-none d-lg-block">
					<Panel>
						<div className="d-flex justify-content-between">
							<b>{ t("game.stats.day") }:</b>
							<span>{ currentDay }</span>
						</div>

						<div className="d-flex justify-content-between">
							<b>{ t("game.stats.buy-price") }</b>
							<span>{ currentBuyPrice === -1 ? "--" : formatDollars(currentBuyPrice) }</span>
						</div>

						<div className="d-flex justify-content-between">
							<b>{ t("game.stats.current-price") }:</b>
							<span>{ currentPrice === -1 ? "--" : formatDollars(currentPrice) }</span>
						</div>

						<div className="d-flex justify-content-between">
							<b>{ t("game.stats.return") }:</b>
							{ formatPercentage(returnRate) }
						</div>

						<div className="d-flex justify-content-between">
							<b>{ t("game.stats.total-return") }:</b>
							{ formatPercentage(totalReturnRate) }
						</div>

						<div className="d-flex justify-content-between">
							<b>{ t("game.stats.total-trades") }:</b>
							<span>{ tradeHistory.length }</span>
						</div>
					</Panel>
				</div>
				<div className="my-2 simulator-panel d-none d-lg-block">
					<Panel>
						<b onClick={ toggleLeaderboardModal } className="open-leaderboard action-link">{ t("game.leaderboard.view") }</b>
						<div className="d-flex justify-content-between">
							<b>{ t("game.stats.all-time-rank") }:</b>
							<span>{ rank.allTime === -1 ? "--" : (rank.allTime === -2 ? "> 20" : rank.allTime) }</span>
						</div>
						<div className="d-flex justify-content-between">
							<b>{ t("game.stats.monthly-rank") }:</b>
							<span>{ rank.monthly === -1 ? "--" : (rank.monthly === -2 ? "> 20" : rank.monthly) }</span>
						</div>
					</Panel>
				</div>
			</div>
		</div>
	</>;
}

export default FinTrainer;
