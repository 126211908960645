import React from 'react';
import { Translation } from 'react-i18next';

class Error extends React.Component
{
	render()
	{
		return (
			<Translation>
				{
					(t) => (
						<>
							<div className="col-12 text-center my-20">
								{ t("error.unknown-error") }
							</div>
						</>
					)
				}
			</Translation>
		);
	}
}

export default Error;
