const pricing = {
	"1": {
		"finlist-hk": 1400,
		"finlist-us": 1050,
		"finlist-jp": 1050,
		"finlist-cn": 1050,
		"finlist-tw": 1050,
	},
	"2": {
		"finlist-hk": 1600,
		"finlist-us": 1200,
		"finlist-jp": 1200,
		"finlist-cn": 1200,
		"finlist-tw": 1200,
	},
	"3": {
		"finlist-hk": 2000,
		"finlist-us": 1500,
		"finlist-jp": 1500,
		"finlist-cn": 1500,
		"finlist-tw": 1500,
	},
};

const products = {
	"1": "finlist-hk",
	"2": "finlist-us",
	"3": "finlist-cn",
	"4": "finlist-tw",
	"5": "sunday-club",
	"6": "finlist-jp",
};

const productDetails = [
	{
		"id": "finlist-hk",
		"name": "FinList (HK)",
		"product-id": 1,
		"highlighted": true,
		"stock-highlights": [ 1, 2, 3 ],
		"market-trend": [ 1, 2, 3 ],
		"market-performance": [ 1, 2, 3 ],
		"ccass-shareholding": [ 1, 2, 3 ],
		"ccass-shareholding-change": true,
		"market-movers": true,
		"market-movers-plus": [ 1, 2, 3 ],
		"profit-alerts": true,
		"education-video-archive": [ 1, 2 ],
		"advanced-education-video-archive": [ 1 ],
		"sunday-club": [ 1, 2, 3 ],
		"sunday-club-replay": [ 1, 2, 3 ],
	},
	{
		"id": "finlist-us",
		"name": "FinList (US)",
		"product-id": 2,
		"highlighted": false,
		"stock-highlights": [ 1, 2, 3 ],
		"market-trend": [ 1, 2, 3 ],
		"market-performance": [ 1, 2, 3 ],
		"ccass-shareholding": [  ],
		"ccass-shareholding-change": true,
		"market-movers": true,
		"market-movers-plus": [ ],
		"profit-alerts": true,
		"education-video-archive": [ 1, 2 ],
		"advanced-education-video-archive": [ 1 ],
		"sunday-club": [ ],
		"sunday-club-replay": [ ],
	},
	{
		"id": "finlist-jp",
		"name": "FinList (JP)",
		"product-id": 6,
		"highlighted": false,
		"stock-highlights": [ 1, 2, 3 ],
		"market-trend": [ 1, 2, 3 ],
		"market-performance": [ 1, 2, 3 ],
		"ccass-shareholding": [  ],
		"ccass-shareholding-change": true,
		"market-movers": true,
		"market-movers-plus": [ ],
		"profit-alerts": true,
		"education-video-archive": [ 1, 2 ],
		"advanced-education-video-archive": [ 1 ],
		"sunday-club": [ ],
		"sunday-club-replay": [ ],
	},
	{
		"id": "finlist-cn",
		"name": "FinList (CN)",
		"product-id": 3,
		"highlighted": false,
		"stock-highlights": [ 1, 2, 3 ],
		"market-trend": [ 1, 2, 3 ],
		"market-performance": [ 1, 2, 3 ],
		"ccass-shareholding": [  ],
		"ccass-shareholding-change": true,
		"market-movers": true,
		"market-movers-plus": [ ],
		"profit-alerts": true,
		"education-video-archive": [ 1, 2 ],
		"advanced-education-video-archive": [ 1 ],
		"sunday-club": [ ],
		"sunday-club-replay": [ ],
	},
	{
		"id": "finlist-tw",
		"name": "FinList (TW)",
		"product-id": 4,
		"highlighted": false,
		"stock-highlights": [ 1, 2, 3 ],
		"market-trend": [ 1, 2, 3 ],
		"market-performance": [ 1, 2, 3 ],
		"ccass-shareholding": [  ],
		"ccass-shareholding-change": true,
		"market-movers": true,
		"market-movers-plus": [ ],
		"profit-alerts": true,
		"education-video-archive": [ 1, 2 ],
		"advanced-education-video-archive": [ 1 ],
		"sunday-club": [ ],
		"sunday-club-replay": [ ],
	},
	{
		"id": "sunday-club",
		"name": "Sunday Club",
		"product-id": 5,
	}
];

export {
	pricing,
	products,
	productDetails,
};
