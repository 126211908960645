import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { loadStripe } from '@stripe/stripe-js';
import {
	useStripe,
	useElements,
	Elements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';

import SubscriptionSuccessModal from "./SubscriptionSuccessModal";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ClipLoader } from 'react-spinners';

import ApiHelper from '../../../api/apiHelper';
import ThreeDSecureModal from './ThreeDSecureModal';
const apiHelper = new ApiHelper();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SubscribeModal = ({ show, toggle, product, billingPeriodId, cardDetails }) => {
	const [ loading, setLoading ] = useState(true);

	const [ paymentRequest, setPaymentRequest ] = useState(null);
	const [ subscriptionDetail, setSubscriptionDetail ] = useState(null);
	const [ couponCode, setCouponCode ] = useState("");
	const [ couponCodeValid, setCouponCodeValid ] = useState(false);
	const [ couponCodeValidated, setCouponCodeValidated ] = useState(false);
	const [ validatingCouponCode, setValidatingCouponCode ] = useState(false);
	const [ threeDSecureUrl, setThreeDSecureUrl ] = useState(null);

	const [ termsChecked, setTermsChecked ] = useState(false);
	const [ hasExpressCheckout, setHasExpressCheckout ] = useState(false);

	const [ cardNumberComplete, setCardNumberComplete ] = useState(false);
	const [ cardExpComplete, setCardExpComplete ] = useState(false);
	const [ cardCvcComplete, setCardCvcComplete ] = useState(false);

	const [ showSubscriptionSuccessModal, setShowSubscriptionSuccessModal ] = useState(false);
	const [ show3DSecureModal, setShow3DSecureModal ] = useState(false);

	const { t } = useTranslation();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();

	const stripe = useStripe();
	const elements = useElements();

	const toggleSubscriptionSuccessModal = () => { setShowSubscriptionSuccessModal(!showSubscriptionSuccessModal) };
	const toggle3DSecureModal = () => { setShow3DSecureModal(!show3DSecureModal) };

	const onChangeCouponCode = (event) => {
		setCouponCodeValidated(false);
		setCouponCode(event.target.value);
	};

	const toggleTerms = () => { setTermsChecked(!termsChecked) };

	const getSubscriptionDetail = async (validateCoupon = false, deleteCoupon = false) => {
		if (validateCoupon || deleteCoupon) {
			setValidatingCouponCode(true);
		}

		setLoading(true);
		setPaymentRequest(null);

		const subscriptionDetailResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			validateCoupon ? "users/subscribe/validate-coupon" : "users/subscribe/preview",
			"POST",
			{
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			JSON.stringify({
				productId: product["product-id"],
				billingPeriodId: billingPeriodId,
				couponCode: validateCoupon ? couponCode.trim() : null,
			})
		);

		if (subscriptionDetailResponse.status === "success") {
			if (subscriptionDetailResponse.data) {
				setLoading(false);
				setSubscriptionDetail(subscriptionDetailResponse.data);

				if (validateCoupon || deleteCoupon) {
					setCouponCode("");
					setValidatingCouponCode(false);
					setCouponCodeValidated(validateCoupon);
					setCouponCodeValid(subscriptionDetailResponse.data.couponDetail !== null);
				}

				ReactGA.event('add_to_cart', {
					currency: 'HKD',
					value: subscriptionDetailResponse.data.amount / 100,
					items: [{
						item_name: product["name"],
					}],
				});
			
				ReactPixel.track('Subscribe', {
					content_name: product["name"],
					value: subscriptionDetailResponse.data.amount / 100,
					currency: 'HKD',
				});
			}
		}
	};

	const getApplePayButton = (callback) => {
		const paymentRequest = stripe.paymentRequest({
			country: 'HK',
			currency: 'hkd',
			displayItems: [{
				label: product.name + " - " + t("subscribe.billing-period." + billingPeriodId),
				amount: subscriptionDetail.amount,
			}],
			total: {
				label: "Finetic Limited",
				amount: subscriptionDetail.amount,
			},
			requestPayerName: false,
			requestPayerEmail: false,
		});

		// Check the availability of the Payment Request API.
		paymentRequest.canMakePayment().then(result => {
			if (result) {
				setPaymentRequest(paymentRequest);
				setHasExpressCheckout(true);
			} else {
				setPaymentRequest(null);
				setHasExpressCheckout(cardDetails != null);
			}
		});

		paymentRequest.on('paymentmethod', async (ev) => {
			subscribe(ev.paymentMethod, () => {
				ev.complete('success');
			}, () => {
				ev.complete('fail');
			});
		});
	}

	const onSubscribe = async () => {
		setLoading(true);

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardNumberElement = elements.getElement(CardNumberElement);

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardNumberElement,
		});
	
		if (error) {
			console.log('[error]', error);

			setLoading(false);
		} else {
			subscribe(paymentMethod, () => {
				cardNumberElement.clear();
				elements.getElement(CardExpiryElement).clear();
				elements.getElement(CardCvcElement).clear();
			}, () => {
				alert("Payment fail!");
			});
		}
	};

	const subscribe = async (paymentMethod, callback = () => { }, error = () => { }) => {
		setLoading(true);

		const subscribeResponse = await apiHelper.request(
			getAccessTokenSilently,
			location,
			"users/subscribe",
			"POST",
			{
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			JSON.stringify({
				productId: product["product-id"],
				billingPeriodId: billingPeriodId,
				paymentMethod: paymentMethod.id ? paymentMethod.id : paymentMethod,
				couponCode: couponCodeValid ? subscriptionDetail.couponDetail.id : null,
			})
		);

		if (subscribeResponse.status === "success") {
			if (subscribeResponse.data.subscriptionStatus === "active") {
				callback();
				toggleSubscriptionSuccessModal();
				toggle();

				setLoading(false);

				ReactGA.event('purchase', {
					currency: 'HKD',
					transaction_id: subscribeResponse.data.subscriptionId,
					value: subscriptionDetail.amount / 100,
					coupon: couponCodeValid ? subscriptionDetail.couponDetail.code : null,
					items: [{
						item_name: product.name,
					}],
				});

				ReactPixel.track('Subscribe', {
					currency: 'HKD',
					value: subscriptionDetail.amount / 100,
				});
			} else if (subscribeResponse.data.subscriptionStatus === 'requires_action') {
				var paymentIntentResponse = await stripe.confirmCardPayment(
					subscribeResponse.data.paymentIntentSecret,
					{
						payment_method: paymentMethod.id,
						return_url: window.location.origin + "/subscribe-verify",
					},
					{ handleActions: false }
				);
	
				window.addEventListener('message', function(ev) {
					if (ev.data === '3DS-authentication-complete') {
						on3DSComplete(subscribeResponse.data.subscriptionId, subscribeResponse.data.paymentIntentSecret);
					}
				}, false);

				setThreeDSecureUrl(paymentIntentResponse.paymentIntent.next_action.redirect_to_url.url);
	
				toggle3DSecureModal();
			} else {
				error();
			}
		} else {
			error();
		}
	}

	const expressCheckout = () => {
		subscribe("existing_card", () => {  }, () => alert("Payment fail!"));
	};

	const onClickPaymentButton = (event) => {
		if ((couponCode != "" && !couponCodeValid) || loading || validatingCouponCode || !termsChecked) {
			event.preventDefault();
		}
	};

	const on3DSComplete = (subscriptionId, paymentIntentSecret) => {
		// Check the PaymentIntent
		stripe.retrievePaymentIntent(paymentIntentSecret)
			.then(function(result) {
				if (result.error) {
					// PaymentIntent client secret was invalid
				} else {
					if (result.paymentIntent.status === 'succeeded') {
						// Show your customer that the payment has succeeded
						toggleSubscriptionSuccessModal();
						toggle();

						ReactGA.event('purchase', {
							currency: 'HKD',
							transaction_id: subscriptionId,
							value: subscriptionDetail.amount / 100,
							coupon: couponCodeValid ? subscriptionDetail.couponDetail.code : null,
							items: [{
								item_name: product.name,
							}],
						});
		
						ReactPixel.track('Subscribe', {
							currency: 'HKD',
							value: subscriptionDetail.amount / 100,
						});
					} else if (result.paymentIntent.status === 'requires_payment_method') {
						alert("Payment fail!");
					}
				}
			});
	}

	useEffect(() => {
		if (show) {
			getSubscriptionDetail();
		}
	}, [ show ]);

	useEffect(() => {
		if (subscriptionDetail !== null) {
			getApplePayButton();
		}
	}, [ subscriptionDetail ]);

	return (
		<>
			<ThreeDSecureModal show={ show3DSecureModal } toggle={ toggle3DSecureModal } threeDSecureUrl={ threeDSecureUrl } />
			<SubscriptionSuccessModal show={ showSubscriptionSuccessModal } toggle={ toggleSubscriptionSuccessModal } product={ product } />
			<Modal show={ show } onHide={ toggle } centered keyboard={ false } backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title>{ product.name + " - " + t("subscribe.billing-period." + billingPeriodId) }</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						subscriptionDetail === null ?
							<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
								<Skeleton count={ 5 } height="2em" className="my-1" />
							</SkeletonTheme> :
							<div className="subscription-modal mb-3">
								<div className="coupon-code">
									{
										couponCodeValid && subscriptionDetail.couponDetail ?
										<div className="coupon">
											<div className="d-flex align-items-center">
												<FontAwesomeIcon icon={ faTag } className="me-2 text-finetic-blue" />
												<div className="d-flex flex-column">
													<span>{ t("subscribe.coupon-applied") }{ subscriptionDetail.couponDetail.code }</span>
													<span className="coupon-saved">
														{ t("subscribe.coupon-saved") }
														{
															subscriptionDetail.couponDetail.amountOff !== null ?
															"$" + subscriptionDetail.couponDetail.amountOff / 100 :
															subscriptionDetail.couponDetail.percentOff + "%"
														}
													</span>
												</div>
											</div>
											<FontAwesomeIcon icon={ faTimesCircle } className="coupon-dismiss" onClick={ () => {
												getSubscriptionDetail(false, true);
											}} />
										</div> :
										<div class="input-group mb-3">
											<div className="form-floating">
												<input type="text" value={ couponCode } className="form-control" id="coupon-code" autoComplete="off" onChange={ onChangeCouponCode } disabled={ validatingCouponCode } />
												<label className="" htmlFor="coupon-code">{ t("subscribe.coupon-code") }</label>
											</div>
											<Button variant="finetic-blue" onClick={ () => {
												getSubscriptionDetail(true);
											}} disabled={ couponCode.trim() === "" || validatingCouponCode } >
												{
													validatingCouponCode ?
													<div className="d-flex align-items-center">
														<ClipLoader
															size={ 25 }
															speedMultiplier={ 0.5 }
															color="#FFFFFF"
														/>
													</div> :
													t("subscribe.coupon-code-apply")
												}
											</Button>
										</div>
									}
								</div>

								<div className="my-1">
									<span className="note text-muted">
										{
											!validatingCouponCode ?
												couponCodeValidated && couponCodeValid ?
													(subscriptionDetail && subscriptionDetail.credit !== 0 && subscriptionDetail.credit != null ?
														t("subscribe.price-and-billing-coupon-credit", { amountNow: subscriptionDetail.amount / 100, amount: subscriptionDetail.amountOriginal / 100, count: (billingPeriodId === 1 ? 12 : (billingPeriodId === 2 ? 3 : 1)), amountPerMonth: ((subscriptionDetail.amount  / 100) / (billingPeriodId === 1 ? 12 : (billingPeriodId === 2 ? 3 : 1))), credit: Math.min(subscriptionDetail.credit * -1 / 100, subscriptionDetail.amountOriginal  / 100) })
													: t("subscribe.price-and-billing-coupon", { amountNow: subscriptionDetail.amount / 100, amount: subscriptionDetail.amountOriginal / 100, count: (billingPeriodId === 1 ? 12 : (billingPeriodId === 2 ? 3 : 1)), amountPerMonth: ((subscriptionDetail.amount / 100) / (billingPeriodId === 1 ? 12 : (billingPeriodId === 2 ? 3 : 1))) }))
													: (subscriptionDetail && subscriptionDetail.credit !== 0 && subscriptionDetail.credit != null ?
														t("subscribe.price-and-billing-credit", { amountNow: subscriptionDetail.amount / 100, amount: subscriptionDetail.amountOriginal / 100, count: (billingPeriodId === 1 ? 12 : (billingPeriodId === 2 ? 3 : 1)), amountPerMonth: ((subscriptionDetail.amount / 100) / (billingPeriodId === 1 ? 12 : (billingPeriodId === 2 ? 3 : 1))), credit: Math.min(subscriptionDetail.credit * -1 / 100, subscriptionDetail.amountOriginal / 100) }) : t("subscribe.price-and-billing", { amount: subscriptionDetail.amount / 100, count: (billingPeriodId === 1 ? 12 : (billingPeriodId === 2 ? 3 : 1)), amountPerMonth: ((subscriptionDetail.amount / 100) / (billingPeriodId === 1 ? 12 : (billingPeriodId === 2 ? 3 : 1))) }))
											: null
										}
									</span>
								</div>

								{
									!termsChecked ?
									<div className="mt-1">
										<span className="text-danger"><FontAwesomeIcon icon={ faTimes } className="me-2" />{ t("subscribe.error.terms") }</span>
									</div> : null
								}

								{
									couponCode.trim() !== "" && !couponCodeValidated ?
									<div className="mt-1">
										<span className="text-danger"><FontAwesomeIcon icon={ faTimes } className="me-2" />{ t("subscribe.error.validate-coupon") }</span>
									</div> : null
								}

								{
									!couponCodeValid && couponCodeValidated ?
									<div className="mt-1">
										<span className="text-danger"><FontAwesomeIcon icon={ faTimes } className="me-2" />{ t("subscribe.error.invalid-coupon") }</span>
									</div> : null
								}

								<div className="form-check mt-1">
									<input className="form-check-input" type="checkbox" id="terms" checked={ termsChecked ? "checked" : "" } onChange={ toggleTerms } />
									<label className="form-check-label note text-muted" for="terms">{ t("subscribe.reminder-1") }<a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "terms-and-conditions" } target="_blank">{ t("subscribe.reminder-2") }</a>{ t("subscribe.reminder-3") }<br />{ t("subscribe.reminder-4") }</label>
								</div>

								{
									termsChecked ?
									<>
										{
											hasExpressCheckout ?
											<>
												<div className="express-checkout mt-2">
													<div className="title">{ t("subscribe.express-checkout.title") }</div>
													<div className="payment-methods">
														{
															cardDetails ?
															<div className="d-grid gap-2">
																<Button variant="finetic-blue" disabled={ loading || validatingCouponCode || cardDetails.expired } onClick={ expressCheckout } className={ paymentRequest ? "mb-2" : "" } >
																{
																	cardDetails.expired ?
																	t("subscribe.express-checkout.card-on-file-expired", { card: cardDetails.cardNumberLast4 }) :
																	t("subscribe.express-checkout.card-on-file", { card: cardDetails.cardNumberLast4 })
																}
																</Button>
															</div> : null
														}
														{
															paymentRequest ? <PaymentRequestButtonElement options={{ paymentRequest }} onClick={ onClickPaymentButton } /> : null 
														}
													</div>
												</div>
												<div className="payment-separator mt-3 mb-2">
													<span>{ t("subscribe.or") }</span>
												</div>
											</> : null
										}

									<div className="my-1">{ t("subscribe.enter-your-card-details") }</div>
									<div className="card-elements-wrapper px-3">
										<div className="row pt-3 pb-1">
											<div className="col">
												<CardNumberElement
													options={{
														style: {
															base: {
																fontSize: '16px'
															},
														},
														showIcon: true,
													}}
													onChange={ (event) => setCardNumberComplete(event.complete) }
												/>
											</div>
										</div>
										<div className="row py-2">
											<div className="col-6">
												<CardExpiryElement
													options={{
														style: {
															base: {
																fontSize: '16px'
															},
														},
													}}
													onChange={ (event) => setCardExpComplete(event.complete) }
												/>
											</div>
											<div className="col-6">
												<CardCvcElement
													options={{
														style: {
															base: {
																fontSize: '16px'
															},
														},
													}}
													onChange={ (event) => setCardCvcComplete(event.complete) }
												/>
											</div>
										</div>
									</div>

									<div className="d-grid gap-2 mt-3">
										<Button variant="finetic-orange" disabled={ loading || !termsChecked || (couponCode !== "" && !couponCodeValid) || validatingCouponCode || !(cardNumberComplete && cardExpComplete && cardCvcComplete) || !stripe || !elements } onClick={ onSubscribe } >
											{
												loading ?
												<div className="d-flex align-items-center justify-content-center h-full">
													<ClipLoader
														size={ 25 }
														speedMultiplier={ 0.5 }
														color="#FFFFFF"
													/>
												</div> : t("subscribe.action.subscribe-now")
											}
										</Button>
									</div>
								</> : null
							}
						</div>
					}
				</Modal.Body>
			</Modal>
		</>
	);
};

const SubscribeModalWrapper = (props) => {
	return (
		<Elements stripe={ stripePromise } >
			<SubscribeModal { ...props } />
		</Elements>
	);
};

export default SubscribeModalWrapper;
