import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUser,
	faShoppingCart,
	faAward,
	faBars,
	faSignOutAlt,
	faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
	useLocation,
	Link,
} from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';

import fineticLogoLight from '../assets/images/finetic-logo-light.png';

const DropdownLink = React.forwardRef(({ children, ...props }, ref) => {
	return (
		<Link ref={ ref } { ...props } to={ children.to } ><FontAwesomeIcon icon={ children.icon } className="me-2" fixedWidth />{ children.title }</Link>
	);
});

const Header = ({ toggleSideBar }) => {
	const { t } = useTranslation();

	const { user, logout } = useAuth0();

	let location = useLocation();

	const onLogout = () => {
		localStorage.setItem("user-complete-registration", false);
		localStorage.setItem("user-email-verified", false);
		logout();
	};

	return (
		<div id="header-finetic">
			<Navbar bg="finetic-blue" fixed="top">
				<div className="container-fluid pe-0 pe-lg-3">
					<div className="row align-items-center w-100">
						<div className="d-none d-lg-block col-lg-3 col-xxl-2">
							<div className="d-flex justify-content-between align-items-center">
								<Link to="/" className="text-white text-decoration-none">
									<img src={ fineticLogoLight } alt="Finetic" className="logo img-fluid ms-2" />
								</Link>
							</div>
						</div>
						<div className="col-lg-9 col-xxl-10 px-lg-0">
							<div className="row align-items-center">
								<div className="col-9 col-lg-6">
									<div className="d-flex align-items-center">
										{/* <FontAwesomeIcon icon={ faBars } className="d-none d-lg-block sidebar-toggle me-3" onClick={ toggleSideBar } /> */}
										<h1 className="navbar-title my-0">
											{ location.pathname === "/" ? t("finlist.title") + " (HK)" : "" }
											{ location.pathname === "/complete-registration" ? t("user.my-profile") : "" }
											{ location.pathname === "/verify-email" ? t("user.my-profile") : "" }
											{ location.pathname === "/dashboard" ? t("user.my-profile") : "" }
											{ location.pathname === "/subscribe" ? t("subscribe.title") : "" }
											{ location.pathname === "/finlist-hk" ? t("finlist.title") + " (HK)" : "" }
											{ location.pathname === "/finder-hk" ? t("finder.title") + " (HK)" : "" }
											{ location.pathname === "/markettrend-hk" ? t("markettrend.title") + " (HK)" : "" }
											{ location.pathname === "/top-shareholding-change" ? t("shareholding-change.title") + " (HK)" : "" }
											{ location.pathname === "/market-movers-hk" ? t("stock-movement.title") + " (HK)": "" }
											{ location.pathname === "/market-movers-plus-hk" ? t("stock-movement.title-enhanced") + " (HK)": "" }
											{ location.pathname === "/stock-news-hk" ? t("stock-news.title") + " (HK)": "" }
											{ location.pathname === "/finlist-us" ? t("finlist.title") + " (US)" : "" }
											{ location.pathname === "/markettrend-us" ? t("markettrend.title") + " (US)" : "" }
											{ location.pathname === "/finlist-cn" ? t("finlist.title") + " (CN)" : "" }
											{ location.pathname === "/markettrend-cn" ? t("markettrend.title") + " (CN)" : "" }
											{ location.pathname === "/finlist-tw" ? t("finlist.title") + " (TW)" : "" }
											{ location.pathname === "/markettrend-tw" ? t("markettrend.title") + " (TW)" : "" }
											{ location.pathname === "/finlist-jp" ? t("finlist.title") + " (JP)" : "" }
											{ location.pathname === "/markettrend-jp" ? t("markettrend.title") + " (JP)" : "" }
											{ location.pathname === "/interactive-chart" ? t("interactive-chart.title") : "" }
											{ location.pathname === "/fintrainer" ? "FinTrainer" : "" }
											{ location.pathname === "/watchlist" ? t("watchlist.title") : "" }
											{ location.pathname === "/education" ? t("education.title") : "" }
											{ location.pathname === "/practice/simulator" ? "Simulator" : "" }
											{ location.pathname === "/admin/upload" ? "Admin | Data Upload" : "" }
											{ location.pathname === "/admin/user-list" ? "Admin | User List" : "" }
											{ location.pathname === "/tools" ? t("more-tools") : "" }
											{ location.pathname === "/faq" ? t("faq.title") : "" }
										</h1>
										{
											location.pathname.indexOf("finlist") !== -1 || location.pathname === "/" ?
											<>
												<div className="d-flex genuine-amelia-icon align-items-center ms-2">
													<FontAwesomeIcon icon={ faAward } className="mx-1" />
													<div className="d-flex flex-column me-1 icon-text">
														<span>{ t("finlist.title-genuine") }</span>
														<span>{ t("finlist.title-amelia") }</span>
													</div>
												</div>
											</> : ""
										}
									</div>
								</div>
								<div className="col-3 col-lg-6 pe-0">
									<div className="d-flex justify-content-end">
										<div className=" d-none d-lg-block">
											<Link to="/subscribe">
												<Button variant="finetic-yellow" className="me-4">
													<FontAwesomeIcon icon={ faShoppingCart } className="me-2" />
													{ t("subscribe.title") }
												</Button>
											</Link>
										</div>

										<Dropdown align="end" className="finetic-header-dropdown">
											<Dropdown.Toggle variant="finetic-blue">
												<FontAwesomeIcon icon={ faUser } className="me-2" fixedWidth />
												<div className="d-none d-lg-inline-block">{ user ? user.nickname : "" }</div>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item as={ DropdownLink } >
													{{ to: "/dashboard", icon: faUser, title: t("user.my-profile") }}
												</Dropdown.Item>
												<Dropdown.Item as={ DropdownLink } className="d-lg-none">
													{{ to: "/subscribe", icon: faShoppingCart, title: t("subscribe.title") }}
												</Dropdown.Item>
												<Dropdown.Item as={ DropdownLink } >
													{{ to: "/faq", icon: faQuestionCircle, title: t("faq.title") }}
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item  onClick={ onLogout }>
													<span className="text-danger"><FontAwesomeIcon icon={ faSignOutAlt } className="me-2" fixedWidth />{ t("user.logout") }</span>
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Navbar>
		</div>
	);
};

export default Header;
