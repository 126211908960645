import { useEffect } from 'react';

import MarketTrend from '../MarketTrend';

import { Helmet } from "react-helmet";

const MarketTrendJP = () => {
	useEffect(() => {
		let pageLastViewed = localStorage.getItem("page-last-viewed");
		pageLastViewed = JSON.parse(pageLastViewed);
		pageLastViewed.markettrend.jp = new Date().getTime();
		localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
	}, [ ]);

	return (
		<>
			<Helmet>
				<title>Market Trend (JP) - Finetic App</title>
			</Helmet>
			<MarketTrend exchange="JP" />
		</>
	);
};

export default MarketTrendJP;
