import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { RingLoader } from "react-spinners";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const VerifyEmail = () => {
	const otpInputRef = useRef();

	const [ loading, setLoading ] = useState(true);
	const [ user, setUser ] = useState(null);
	const [ emailVerificationFailed, setEmailVerificationFailed ] = useState(false);

	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const history = useHistory();

	const getUserDetails = async () => {
		setLoading(true);

		try {
			var userResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/user",
				"GET"
			);

			if (userResponse.data.emailVerified) {
				history.push("/dashboard");
			} else {
				setLoading(false);
				setUser(userResponse.data);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const verifyEmail = async (event) => {
		setLoading(true);

		event.preventDefault();

		try {
			var verifyResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/verify-email",
				"POST",
				{
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				JSON.stringify({
					email: user.email,
					otp: otpInputRef.current.value,
				})
			);

			var verified = verifyResponse.data;

			setEmailVerificationFailed(!verified);

			if (verified) {
				history.push("/dashboard");
			} else {
				setLoading(false);
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	useEffect(() => {
		getUserDetails();
	}, [ ]);

	return (
		<div className="row">
			<div className="col-12 col-lg-6">
				{
					loading ?
						<div id="loading-container" className="col-12 d-flex flex-column justify-content-around align-items-center my-5">
							<RingLoader color="#005493" />
						</div> :
						<>
							<h2 className="my-3">{ t("user.post-registration.email-verification-pending") }</h2>
							<Form onSubmit={ verifyEmail } >
								<FloatingLabel controlId="otp" label={ t("user.data.email-otp") } className="mb-3">
									<Form.Control ref={ otpInputRef } type="text" aria-label={ t("user.data.email-otp") } required />
								</FloatingLabel>

								{
									emailVerificationFailed ?
									<p className="text-danger"><FontAwesomeIcon icon={ faTimes } className="me-2" />{ t("user.post-registration.email-verification-failed") }</p>
									: null
								}

								<Button variant="finetic-blue" type="submit">{ t("user.post-registration.verify-email") }</Button>
							</Form>
						</>
				}
			</div>
		</div>
	);
};

export default VerifyEmail;
