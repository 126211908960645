import React from 'react';
import {
	Content,
	Section,
	Table,
	Modal,
	Header,
	SearchField,
	Button,
	Forbidden,
	Error,
} from '../../components';
import {
	UserRow,
} from '../../components/admin/user';
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSortUp,
	faSortDown,
	faSort,
	faArrowUp,
	faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { withAuth0 } from "@auth0/auth0-react";
import { Translation } from 'react-i18next';

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

class UserList extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			userlist: [],
			originalList: [],
			expanded: { },
			activeUser: null,
			sort: {
				key: "name",
				asc: true
			},
			show: {
				admin: true,
				friend: true,
				paid: true,
				freeUser: true,
			},
			isLoading: true,
			forbidden: false,
			error: false,
			nextPage: 0,
			hasMore: false,
		};

		this.getUserlist = this.getUserlist.bind(this);
		this.searchUser = this.searchUser.bind(this);
		this.sortData = this.sortData.bind(this);
		this.onChangeSortDirection = this.onChangeSortDirection.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleRowClickMobile = this.handleRowClickMobile.bind(this);
	}

	componentDidMount()
	{
		this.getUserlist();
	}

	async getUserlist()
	{
		const page = this.state.nextPage;

		this.setState({ isLoading: true, hasMore: false }, async () => {
			try {
				var userlistResponse = await apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"admin/users?page=" + page,
					"GET"
				);

				var userlist = userlistResponse.data.data;

				if (page > 0) {
					this.state.originalList.forEach((user) => {
						userlist.push(user);
					})
				}

				var list = userlist.sort((a, b) => (a[this.state.sort.key] > b[this.state.sort.key]) ? (this.state.sort.asc ? 1 : -1) : (this.state.sort.asc ? -1 : 1));
				var expanded = {};
				
				list.forEach(a => {
					expanded[a.userId] = false;
				});

				this.setState({ userlist: list, originalList: list, expanded: expanded, isLoading: false, nextPage: page + 1, hasMore: userlistResponse.data.dataCount > 0 });
			} catch (e) {
				this.setState({ error: true, isLoading: false });
			}
		})
	}

	searchUser(event)
	{
		var keyword = event.target.value.trim();

		if (keyword !== "") {
			var newList = this.state.originalList.filter((user) => user.name.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 || user.email.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 || user.phoneNumber.indexOf(keyword) !== -1 || (user.stripeCustomerId ? user.stripeCustomerId.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 : false) || (user.subscriptions.filter((subscription) => subscription.planName.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 || subscription.stripeSubscriptionId.toUpperCase().indexOf(keyword.toUpperCase()) !== -1).length > 0));
			this.setState({ userlist: newList });
		} else {
			this.setState({ userlist: this.state.originalList });
		}
	}

	sortData(sortKey, doSort = true)
	{
		var sameKey = this.state.sort.key === sortKey;
		var asc = this.state.sort.asc;

		if (doSort) {
			asc = false;

			if (sameKey) {
				asc = !this.state.sort.asc;
			}
		}

		var newList = this.state.userlist.sort((a, b) => (a[sortKey] > b[sortKey]) ? (asc ? 1 : -1) : (asc ? -1 : 1));

		var sort = {
			key: sortKey,
			asc: asc
		};

		this.setState({ list: newList, sort: sort });
	}

	onChangeSortDirection(event)
	{
		var sortKey = this.state.sort.key;
		var asc = !this.state.sort.asc;

		var newList = this.state.userlist.sort((a, b) => (a[sortKey] > b[sortKey]) ? (asc ? 1 : -1) : (asc ? -1 : 1));

		var sort = {
			key: sortKey,
			asc: asc
		};

		this.setState({ list: newList, sort: sort });
	}

	handleRowClick(userId)
	{
		var expanded = this.state.expanded;
		expanded[userId] = !expanded[userId];
		this.setState({ expanded: expanded });
    }

	handleRowClickMobile(user)
	{
		this.setState({ activeUser: user }, () => {
			// halfmoon.toggleModal("user-" + user.userId);
		});
    }

	onChangeShowUserType(type, event)
	{
		var show = this.state.show;
		show[type] = event.target.checked;

		var newList = [];

		if (show.freeUser) {
			this.state.originalList.filter((user) => user.freeUser === show.freeUser).forEach((user) => {
				newList.push(user);
			})
		}

		if (show.paid) {
			this.state.originalList.filter((user) => !user.admin && !user.friend && !user.freeUser).forEach((user) => {
				newList.push(user);
			})
		}

		if (show.friend) {
			this.state.originalList.filter((user) => user.friend === show.friend).forEach((user) => {
				newList.push(user);
			})
		}

		if (show.admin) {
			this.state.originalList.filter((user) => user.admin === show.admin).forEach((user) => {
				newList.push(user);
			})
		}

		this.setState({ show: show, userlist: newList });
	}

	render()
	{
		return (
			<>
				<Helmet>
					<title>User List - Finetic App</title>
				</Helmet>
				<Translation>
					{
						(t) => (
							<>
								{
									this.state.activeUser ?
									<Modal
										type="fullscreen"
										id={ "user-" + this.state.activeUser.userId }
										title={ this.state.activeUser.name }
									>
										<Section containerType="fluid">
											<div className="col-12">
												<UserRow key={ "user-card-" + this.state.activeUser.userId } user={ this.state.activeUser } onRowClick={ this.handleRowClickMobile } card expanded />
											</div>
										</Section>
									</Modal> : null
								}
								<Content>
									<Header>
										<Section containerType="fluid">
											<div className="col-12 col-md-6 d-none d-lg-block">
												<div className="d-flex align-items-center pl-10 pr-10 pr-lg-0 w-full">
													<SearchField onSearch={ this.searchUser } />
												</div>
											</div>

											<div className="col-12 d-none d-lg-block">
												<div className="d-flex align-items-center pl-10 pr-10 pr-lg-0 w-full mt-10">
													<div className="custom-checkbox d-inline-block mr-10">
														<input type="checkbox" id="freeUser" checked={ this.state.show.freeUser } onChange={ (e) => { this.onChangeShowUserType("freeUser", e) }} />
														<label htmlFor="freeUser">
															<span className="badge badge-secondary mx-5">FREE</span>
														</label>
													</div>
													<div className="custom-checkbox d-inline-block mr-10">
														<input type="checkbox" id="paid" checked={ this.state.show.paid } onChange={ (e) => { this.onChangeShowUserType("paid", e) }} />
														<label htmlFor="paid">
															<span className="badge mx-5">PAID</span>
														</label>
													</div>
													<div className="custom-checkbox d-inline-block mr-10">
														<input type="checkbox" id="friend" checked={ this.state.show.friend } onChange={ (e) => { this.onChangeShowUserType("friend", e) }} />
														<label htmlFor="friend">
															<span className="badge badge-success mx-5">FRIEND</span>
														</label>
													</div>
													<div className="custom-checkbox d-inline-block mr-10">
														<input type="checkbox" id="admin" checked={ this.state.show.admin } onChange={ (e) => { this.onChangeShowUserType("admin", e) }} />
														<label htmlFor="admin">
															<span className="badge badge-danger mx-5">ADMIN</span>
														</label>
													</div>
												</div>
											</div>
											
											<div className="col-12 d-none d-lg-block">
												<Table wrapperClassName="data-table userlist-table pl-10 pr-20">
													<thead>
														<tr>
															<th>
															</th>
															<th className="sortable" onClick={ () => { this.sortData("name") }} >
																<span className="mr-5">User</span>
																{ this.state.sort.key === "name" ? (this.state.sort.asc ? <FontAwesomeIcon icon={ faSortUp } /> : <FontAwesomeIcon icon={ faSortDown } />) : <FontAwesomeIcon icon={ faSort } /> }
															</th>
															<th>Auth0 User ID</th>
															<th>Stripe Customer ID</th>
														</tr>
													</thead>
												</Table>
											</div>
										</Section>
									</Header>
									<Section containerType="fluid" sectionClass="d-lg-none pt-10">
										<div className="col-12 px-10">
											<div className="d-flex align-items-center w-full">
												<SearchField onSearch={ this.searchUser } />
											</div>
										</div>
										<div className="col-8 p-10 pr-0 pb-0">
											Sort By: Name
										</div>
										<div className="col-4 pr-10 pt-10 d-flex align-items-center justify-content-center sort-direction">
											<label htmlFor="sort-direction" className={ "m-0 mr-10 sort-arrow-up" + (this.state.sort.asc ? " sort-active" : "") } >
												<FontAwesomeIcon icon={ faArrowUp } />
											</label>
											<div className="custom-switch">
												<input type="checkbox" id="sort-direction" value="" checked={ !this.state.sort.asc ? "checked" : "" } onChange={ this.onChangeSortDirection } />
												<label htmlFor="sort-direction" className={ (!this.state.sort.asc ? " sort-active" : "") } >
													<FontAwesomeIcon icon={ faArrowDown } />
												</label>
											</div>
										</div>
									</Section>
									<Section containerType="fluid">
										{
											this.state.isLoading ?
											<div className="col-12">
												<div className="px-10 py-5">
													<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
														<Skeleton count={ 5 } height="3em" className="my-5" />
													</SkeletonTheme>
												</div>
											</div>
											: (this.state.forbidden || this.state.error ?
												<>
													{
														this.state.forbidden ?
														<Forbidden /> : <Error />
													}
												</>
												: <>
													<div className="col-12 d-none d-lg-block">
														<Table wrapperClassName="data-table userlist-table pl-10 pr-20">
															{
																this.state.userlist.map((user, i) => (
																	<UserRow key={ "user-" + user.userId } user={ user } expanded={ this.state.expanded[user.userId] } onRowClick={ this.handleRowClick } />
																))
															}
														</Table>
													</div>
													<div className="col-12 d-lg-none">
														{
															this.state.userlist.map((user, i) => (
																<UserRow key={ "user-sm-" + user.userId } user={ user } expanded={ this.state.expanded[user.userId] } onRowClick={ this.handleRowClickMobile } card />
															))
														}
													</div>
												</>
											)
										}
										{
											this.state.hasMore ?
											<div className="col-12 text-center">
												<div className="pl-10">
													<Button buttonType="blue" onClick={ this.getUserlist } >Load more...</Button>
												</div>
											</div> : null
										}
										<div className="col-12">
											<div className="finder-metadata pl-10 text-center">
												<p className="secondary-text my-10">Total users: { this.state.userlist.length }</p>
											</div>
										</div>
									</Section>
								</Content>
							</>
						)
					}
				</Translation>
			</>
		);
	}
}

export default withAuth0(UserList);
