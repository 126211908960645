import Category from './sidebar/Category';
import Item from './sidebar/Item';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	faEnvelope,
	faLink,
	faUniversity,
	faChartPie,
	faLocationArrow,
	faPlusMinus,
	faChartColumn,
	faDisplay,
	faNewspaper,
} from '@fortawesome/free-solid-svg-icons';
import {
	faFile,
	faEye,
} from '@fortawesome/free-regular-svg-icons';
import {
	faFacebook,
	faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
	useLocation,
} from "react-router-dom";

import { useTranslation } from 'react-i18next';

const SideBar = ({ extended }) => {
	const { t } = useTranslation();

	let location = useLocation();

	return (
		<div id="sidebar-finetic" className="d-flex flex-column justify-content-between">
			<div className="sidebar-nav">
				<Category id="hk" icon="🇭🇰" title="HK" open>
					<Item icon={ <FontAwesomeIcon icon={ faFile } /> } title={ t("finlist.title") } link="/finlist-hk" isActive={ location.pathname === "/finlist-hk" || location.pathname === "/" } />
					<Item icon={ <FontAwesomeIcon icon={ faLocationArrow } /> } title={ t("markettrend.title") } link="/markettrend-hk" isActive={ location.pathname === "/markettrend-hk" } />
					<Item icon={ <FontAwesomeIcon icon={ faChartPie } /> } title={ t("shareholding-change.title") } link="/top-shareholding-change" isActive={ location.pathname === "/top-shareholding-change" } />
					<Item icon={ <FontAwesomeIcon icon={ faPlusMinus } /> } title={ t("stock-movement.title") } link="/market-movers-hk" isActive={ location.pathname === "/market-movers-hk" } isNew />
					<Item icon={ <FontAwesomeIcon icon={ faNewspaper } /> } title={ t("stock-news.title") } link="/stock-news-hk" isActive={ location.pathname === "/stock-news-hk" } isNew />
				</Category>

				<Category id="us" icon="🇺🇸" title="US">
					<Item icon={ <FontAwesomeIcon icon={ faFile } /> } title={ t("finlist.title") } link="/finlist-us" isActive={ location.pathname === "/finlist-us" } />
					<Item icon={ <FontAwesomeIcon icon={ faLocationArrow } /> } title={ t("markettrend.title") } link="/markettrend-us" isActive={ location.pathname === "/markettrend-us" } />
				</Category>

				<Category id="jp" icon="🇯🇵" title="JP">
					<Item icon={ <FontAwesomeIcon icon={ faFile } /> } title={ t("finlist.title") } link="/finlist-jp" isActive={ location.pathname === "/finlist-jp" } />
					<Item icon={ <FontAwesomeIcon icon={ faLocationArrow } /> } title={ t("markettrend.title") } link="/markettrend-jp" isActive={ location.pathname === "/markettrend-jp" } />
				</Category>

				<Category id="cn" icon="🇨🇳" title="CN">
					<Item icon={ <FontAwesomeIcon icon={ faFile } /> } title={ t("finlist.title") } link="/finlist-cn" isActive={ location.pathname === "/finlist-cn" } />
					<Item icon={ <FontAwesomeIcon icon={ faLocationArrow } /> } title={ t("markettrend.title") } link="/markettrend-cn" isActive={ location.pathname === "/markettrend-cn" } />
				</Category>

				<Category id="tw" icon="🇹🇼" title="TW">
					<Item icon={ <FontAwesomeIcon icon={ faFile } /> } title={ t("finlist.title") } link="/finlist-tw" isActive={ location.pathname === "/finlist-tw" } />
					<Item icon={ <FontAwesomeIcon icon={ faLocationArrow } /> } title={ t("markettrend.title") } link="/markettrend-tw" isActive={ location.pathname === "/markettrend-tw" } />
				</Category>

				<Item icon={ <FontAwesomeIcon icon={ faChartColumn } /> } title={ t("interactive-chart.title") } link="/interactive-chart" isActive={ location.pathname === "/interactive-chart" } className="mt-3" />
				<Item
					icon={
						<span className="fa-layers fa-fw">
							<FontAwesomeIcon icon={ faDisplay } />
							<FontAwesomeIcon icon={ faChartColumn } transform="shrink-8 up-2" />
						</span>
					}
					title={ t("game.title") } link="/fintrainer" isActive={ location.pathname === "/fintrainer" }
				/>
				<Item icon={ <FontAwesomeIcon icon={ faEye } /> } title={ t("watchlist.title") } link="/watchlist" isActive={ location.pathname === "/watchlist" } />
				<Item icon={ <FontAwesomeIcon icon={ faUniversity } /> } title={ t("education.title") } link="/education" isActive={ location.pathname === "/education" } />
			</div>

			<div className="footer mt-5 mb-2 d-flex flex-column">
				<a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL } className="social-link" target="_blank">
					<FontAwesomeIcon icon={ faLink } className="icon mx-1" />
					finetic.ai
				</a>

				<a href="https://www.facebook.com/fineticai" className="social-link">
					<FontAwesomeIcon icon={ faFacebook } className="icon mx-1" />
					facebook.com/fineticai
				</a>

				<a href="https://wa.me/85266575630" className="social-link">
				<FontAwesomeIcon icon={ faWhatsapp } className="icon mx-1" />
					+852 6657 5630
				</a>

				<a href="mailto:service@finetic.ai" className="social-link">
					<FontAwesomeIcon icon={ faEnvelope } className="icon mx-1" />
					service@finetic.ai
				</a>

				<div className="copyright px-2 mt-3">
					<span>FINETIC™ © { new Date().getFullYear() } <br />{ t("all-rights-reserved") }</span><br /><br />
					<a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "terms-and-conditions" } className="footer-link" target="_blank">{ t("terms-and-conditions") }</a><br /><a href={ process.env.REACT_APP_FINETIC_WEBSITE_URL + "privacy-policy" } className="footer-link" target="_blank">{ t("privacy-policy") }</a>
				</div>
			</div>
		</div>
	);
};

export default SideBar;
