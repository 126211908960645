import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UpdateCardModal from "./modal/UpdateCardModal";
import ActiveSubscription from "./ActiveSubscription";
import SubscribeModal from "../subscribe/modal/SubscribeModal";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { formatDollars } from '../../util/numberUtil';
import { productDetails } from "../../util/products";

const Subscriptions = ({ subscriptions, cardDetails, credit, loading, getSubscriptions }) => {
	const [ showUpdateCardModal, setShowUpdateCardModal ] = useState(false);
	const [ showSubscribeSundayClubModal, setShowSubscribeSundayClubModal ] = useState(false);

	const toggleSubscribeSundayClubModal = () => { setShowSubscribeSundayClubModal(!showSubscribeSundayClubModal) };

	const { t } = useTranslation();

	const { user } = useAuth0();

	const toggleUpdateCardModal = () => { setShowUpdateCardModal(!showUpdateCardModal) };

	return (
		<>
			<SubscribeModal show={ showSubscribeSundayClubModal } toggle={ toggleSubscribeSundayClubModal } product={ productDetails[4] } billingPeriodId={ 4 } cardDetails={ cardDetails } />
			{
				cardDetails ? <UpdateCardModal show={ showUpdateCardModal } toggle={ toggleUpdateCardModal } getSubscriptions={ getSubscriptions } /> : null
			}
			<div className="row">
				<div className="col-12 col-lg-8 col-xxl-6 order-lg-1 order-2">
					{
						loading ?
							<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
								<Skeleton count={ 3 } height="7em" className="my-1" />
							</SkeletonTheme> :
							<>
								{
									subscriptions.length === 0 ?
										<span>{ t("user.no-active-subscriptions") }</span> :
										<>
											{
												subscriptions.map((subscription, i) => (
													<ActiveSubscription subscription={ subscription } getSubscriptions={ getSubscriptions } cardDetails={ cardDetails } />
												))
											}
										</>
								}
							</>
					}
				</div>

				<div className="col-12 col-lg-4 col-xxl-3 py-2 py-lg-0 order-lg-2 order-1">
					{
						loading ?
							<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
								<Skeleton count={ 2 } height="7em" className="my-1" />
							</SkeletonTheme> :
							<>
								{
									cardDetails ?
										<Card className="dashboard-card mb-2">
											<Card.Body>
												<div className="d-flex align-items-center justify-content-between">
													<h5 className="my-0">{ t("user.data.payment-info") }</h5>
													<FontAwesomeIcon icon={ faPen } className="user-info-edit-icon" onClick={ toggleUpdateCardModal } />
												</div>

												<div className="row py-2">
													<div className="col-12">
														{ t("user.data.credit-card-ending-in", { code: cardDetails.cardNumberLast4 }) }
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														{
															cardDetails.expired ?
															<span className="text-danger">{ t("user.data.expired-on", { date: cardDetails.expireMonth + "/" + cardDetails.expireYear }) }</span> :
															t("user.data.expiring-on", { date: cardDetails.expireMonth + "/" + cardDetails.expireYear })
														}
													</div>
												</div>
											</Card.Body>
										</Card> : null
								}
								<Card className="dashboard-card">
									<Card.Body>
										<div className="d-flex align-items-center justify-content-between">
											<h5 className="my-0">{ t("user.data.remaining-credit") }</h5>
											{ formatDollars(credit) }
										</div>
										<div className="mt-2 text-muted small">{ t("user.data.remaining-credit-desc") }</div>
									</Card.Body>
								</Card>
							</>
					}
				</div>
			</div>

			<div className="row mt-1 mb-2 d-lg-none">
				<div className="col-12 col-lg-8">
					<div className="text-center">
						<Link to="/subscribe" className="subscribe-link">
							<FontAwesomeIcon icon={ faShoppingCart } className="me-2" />{ t("subscribe.title") }
						</Link>
					</div>
				</div>
			</div>
		</>
	)
};

export default Subscriptions;
