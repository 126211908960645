import React from 'react';
import PropTypes from 'prop-types';

export default class Button extends React.Component
{
	render()
	{
		const { buttonType, isLink, isBlock, isCallToAction, className, ...buttonProps } = this.props;


		if (isLink) {
			return (
				<a
					{ ...buttonProps }
					className={ "btn btn-finetic-" + this.props.buttonType + (isBlock ? " btn-block" : "") + (isCallToAction ? " btn-call-to-action" : "") + " " + className } >
					{ this.props.children }
				</a>
			);
		} else {
			return (
				<button
					{ ...buttonProps }
					className={ "btn btn-finetic-" + this.props.buttonType + (isBlock ? " btn-block" : "") + (isCallToAction ? " btn-call-to-action" : "") + " " + className } >
					{ this.props.children }
				</button>
			);
		}
	}
}

Button.propTypes = {
	buttonType: PropTypes.oneOf([ "green", "orange", "blue", "white", "" ]).isRequired,
	isLink: PropTypes.bool,
	isBlock: PropTypes.bool,
	isCallToAction: PropTypes.bool,
	type: PropTypes.string,
	className: PropTypes.string,
};

Button.defaultProps = {
	isLink: false,
	isBlock: false,
	isCallToAction: false,
	type: "button",
	className: "",
};
