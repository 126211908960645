import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import VideoModal from "./modal/VideoModal";

import Card from "react-bootstrap/Card";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPlayCircle,
} from '@fortawesome/free-regular-svg-icons';

import videoPlaceholder from '../../assets/images/video-placeholder.png'
import sundayClub from '../../assets/images/sunday-club.png'

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const Video = ({ video, zoomLink, loading }) => {
	const [ showVideoModal, setShowVideoModal ] = useState(false);

	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();

	const toggleVideoModal = () => { setShowVideoModal(!showVideoModal) };

	const getTimeDisplay = () => {
		var seconds = video.duration;
		var output = "";

		if (seconds < 60) {
			output = t("education.time.less-than-a-minute");
		}

		var minute = Math.floor(seconds / 60 % 60);
		var hour = Math.floor(seconds / 60 / 60);

		if (hour >= 1) {
			output += hour + " " + t("education.time.hour" + (hour > 1 ? "s" : "")) + " ";
		}

		if (minute >= 1) {
			output += minute + " " + t("education.time.min" + (minute > 1 ? "s" : ""));
		}

		return output;
	}

	useEffect(() => {
		if (showVideoModal) {
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"education/view-video/" + video.vimeoId,
				"GET"
			);
		}
	}, [ showVideoModal ])

	if (loading) {
		return (
			<div className="col-6 col-lg-3 mb-3 d-flex">
				<Card className="shadow-sm">
					<Card.Img variant="top" src={ videoPlaceholder } />
					<Card.Body>
						<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
							<Skeleton count={ 2 } height="2em" className="my-1" />
						</SkeletonTheme>
					</Card.Body>
				</Card>
			</div>
		);
	}

	if (zoomLink) {
		return (
			<div className="col-6 col-lg-3 mb-3 d-flex">
				<a href={ zoomLink } target="_blank" className="sunday-club-card">
					<Card className="shadow-sm w-100 h-100">
						<Card.Img variant="top" src={ sundayClub } />
						<Card.Body>
							<Card.Title>{ t("education.register-sunday-club") }</Card.Title>
						</Card.Body>
					</Card>
				</a>
			</div>
		);
	}

	return (
		<>
			<VideoModal show={ showVideoModal } toggle={ toggleVideoModal } video={ video } />
			<div className="col-6 col-lg-3 mb-3 d-flex">
				<Card className="d-flex shadow-sm video w-100" onClick={ toggleVideoModal } >
					<div className="video-img-wrapper">
						<Card.Img variant="top" src={ video.thumbnail ? video.thumbnail : videoPlaceholder } />
						<div className="play-button position-absolute top-0 d-flex justify-content-center align-items-center w-100 h-100">
							<FontAwesomeIcon icon={ faPlayCircle } />
						</div>
					</div>
					<Card.Body>
						<Card.Title>{ video.name }</Card.Title>
						<span className="text-muted small">
							{ getTimeDisplay() }
						</span>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};

export default Video;
