import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

import Video from "./Video";

import Forbidden from "../Forbidden";
import Error from "../Error";


import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const VideoList = ({ type }) => {
	const [ loading, setLoading ] = useState(true);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	const [ videoList, setVideoList ] = useState([ ]);
	const [ sundayClubLink, setSundayClubLink ] = useState(null);
	const [ showSundayClubLink, setShowSundayClubLink ] = useState(false);

	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();

	const getSundayClubVideos = async () => {
		setLoading(true);
		setForbidden(false);
		setError(false);

		try {
			var videoList = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"education/sunday-club",
				"GET"
			);

			if (videoList.status === "Forbidden") {
				setForbidden(true);
				setLoading(false);
				return;
			} else if (videoList.status === "Error") {
				setError(true);
				setLoading(false);
				return;
			}

			var sundayClubLinkResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"education/sunday-club/link",
				"GET"
			);

			var date = changeTimezone(new Date(), "Asia/Hong_Kong");
			var showSundayClubLink = false;

			if (date.getDay() === 6) {
				if (date.getHours() >= 8) {
					showSundayClubLink = true;
				}
			} else if (date.getDay() === 0) {
				showSundayClubLink = true;
			}
			
			setSundayClubLink(sundayClubLinkResponse.data);
			setShowSundayClubLink(showSundayClubLink);
			
			setVideoList(videoList.data);
			setLoading(false);
			setForbidden(false);
			setError(false);

			let pageLastViewed = localStorage.getItem("page-last-viewed");
			pageLastViewed = JSON.parse(pageLastViewed);
			pageLastViewed.education.sundayClub = new Date().getTime();
			localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
		} catch (e) {
			setForbidden(false);
			setError(true);
			setLoading(false);
		}
	}

	const getEducationVideos = async () => {
		setLoading(true);
		setForbidden(false);
		setError(false);

		try {
			var videoList = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"education/videos",
				"GET"
			);

			if (videoList.status === "Forbidden") {
				setForbidden(true);
				setLoading(false);
				return;
			} else if (videoList.status === "Error") {
				setError(true);
				setLoading(false);
				return;
			}
			
			setVideoList(videoList.data);
			setForbidden(false);
			setError(false);
			setLoading(false);

			let pageLastViewed = localStorage.getItem("page-last-viewed");
			pageLastViewed = JSON.parse(pageLastViewed);
			pageLastViewed.education.videos = new Date().getTime();
			localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
		} catch (e) {
			setForbidden(false);
			setError(true);
			setLoading(false);
		}
	}

	const getAdvancedEducationVideos = async () => {
		setLoading(true);
		setForbidden(false);
		setError(false);

		try {
			var videoList = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"education/videos/advanced",
				"GET"
			);

			if (videoList.status === "Forbidden") {
				setForbidden(true);
				setLoading(false);
				return;
			} else if (videoList.status === "Error") {
				setError(true);
				setLoading(false);
				return;
			}
			
			setVideoList(videoList.data);
			setLoading(false);
			setForbidden(false);
			setError(false);

			let pageLastViewed = localStorage.getItem("page-last-viewed");
			pageLastViewed = JSON.parse(pageLastViewed);
			pageLastViewed.education.videos = new Date().getTime();
			localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
		} catch (e) {
			setForbidden(false);
			setError(true);
			setLoading(false);
		}
	}

	const changeTimezone = (date, timeZone) => {
		var invdate = new Date(date.toLocaleString('en-US', {
			timeZone: timeZone
		}));

		var diff = date.getTime() - invdate.getTime();
	  
		return new Date(date.getTime() - diff);
	}

	useEffect(() => {
		switch (type) {
			case "SUNDAY_CLUB":
				getSundayClubVideos();
				break;
			case "EDUCATION_VIDEO":
				getEducationVideos();
				break;
			case "EDUCATION_VIDEO_ADVANCED":
				getAdvancedEducationVideos();
				break;
			default:
				break;
		}
	}, [ ]);

	if (loading) {
		return (
			<div className="row">
				{ [ 0, 1, 2, 3, 4, 5, 6, 7 ].map(a => <Video loading />) }
			</div>
		)
	}

	if (forbidden) {
		if (type === "EDUCATION_VIDEO_ADVANCED") {
			return (
				<Forbidden customMessage={ <Trans i18nKey="education.advanced-video-forbidden" /> } />
			);
		}

		return <Forbidden />;
	}

	if (error) {
		return <Error />;
	}

	return (
		<>
			<div className="row">
				{
					type === "SUNDAY_CLUB" && showSundayClubLink ?
						<Video zoomLink={ sundayClubLink } /> : null
				}
				{
					videoList.map(video => <Video video={ video } loading={ loading } />)
				}
			</div>

			<div className="row">
				<div className="col-12">
					<p className="text-finetic-gray text-center">{ t("education.total-videos", { count: videoList.length }) }</p>
				</div>
			</div>
		</>
	);
};

export default VideoList;
