function formatDollars(number, isDifference = false)
{
	var isNegative = number < 0;
	number = Math.abs(number);
	number = (isNegative ? "-" : "") + "$" + number.toFixed(2);

	if (isDifference) {
		return <span className={ (isNegative ? "negative" : "positive") + "-growth" } >{ number }</span>;
	} else {
		return number;
	}
}

function formatBigNumber(number, isDollar = false, decimalPlace = 2)
{
	var isNegative = number < 0;
	number = Math.abs(number);
	number =  number >= 1000000000 ? (number / 1000000000).toFixed(decimalPlace) + "B" : (number / 1000000).toFixed(decimalPlace) + "M";
	number = (isNegative ? "-" : "") + (isDollar ? "$" : "") + number;

	return number;
}

function formatPercentage(percentage, isDifference = true, abbrivate = false)
{
	if (isDifference) {
		return <span className={ (percentage >= 0 ? "positive" : "negative") + "-growth" } >
			{
				abbrivate ?
					(percentage >= 9999 ? "> 9999" :
						percentage <= -9999 ? "< -9999" :
						(percentage >= 0 ? "+" : "") + percentage.toFixed(2)) :
						(percentage >= 0 ? "+" : "") + percentage.toFixed(2)
			}%
		</span>;
	} else {
		return <span>{ percentage.toFixed(2) }%</span>;
	}
}

export {
	formatDollars,
	formatBigNumber,
	formatPercentage,
};
