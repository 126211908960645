import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronDown,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Translation } from 'react-i18next';

export default class UserRow extends React.PureComponent
{
	render()
	{
		const { user, expanded, card } = this.props;

		return (
			<Translation>
				{
					(t) => (
						<>
							{
								card ?
								<div id={ "user-card-" + user.userId } className={ "user-card" + (!expanded ? " m-10 px-10 py-5" : " expanded") } >
									<div className="row">
										<div className="col-12 user-basic-info">
											{ !expanded ? <span className="name">{ user.name }</span> : null }
											<span className="email"> { user.email }</span>
										</div>
										<div className="col-12">
											{ user.freeUser ? <span className="user-badge badge-secondary mr-5">FREE</span> : null }
											{ !user.freeUser && !user.friend && !user.admin ? <span className="user-badge badge mx-5">PAID</span> : null }
											{ user.friend ? <span className="user-badge badge badge-success mr-5">FRIEND</span> : null }
											{ user.admin ? <span className="user-badge badge badge-danger mr-5">ADMIN</span> : null }
										</div>
									</div>
									<div className={ "row details" + (expanded ? " expanded" : "") } onClick={ () => { if (!expanded) this.props.onRowClick(user) }} >
										<div className={ expanded ? "col-12" : "col-10" } >
											<div className="row">
												<div className="col-12">
													<div className="d-flex justify-content-between">
														<div className="label">Auth0 User ID:</div>
													</div>
												</div>
												<div className="col-12">
													<div className="d-flex justify-content-between">
														{ user.userId }
													</div>
												</div>
												<div className="col-12">
													<div className="d-flex justify-content-between">
														<div className="label">Stripe Customer ID:</div>
													</div>
												</div>
												<div className="col-12">
													<div className="d-flex justify-content-between">
														{ user.stripeCustomerId ? user.stripeCustomerId : "--" }
													</div>
												</div>
											</div>
											{
												expanded ?
												(user.subscriptions.length > 0 ?
												<>
													<hr />
													<div className="row mb-5">
														<div className="col-12">
															<b>Subscriptions</b>
														</div>
													</div>
													{
														user.subscriptions.map((subscription, i) => (
														<div key={ subscription.stripeSubscriptionId } className="row">
															<div className="col-12">
																<b>{ subscription.planName } - { subscription.stripeSubscriptionId }</b><br />
																{ subscription.startDate.substring(0, 10) } - { subscription.endDate.substring(0, 10) }
															</div>
														</div>))
													}
												</> : "No active subscriptions") : null
											}
										</div>
										{
											!expanded ?
											<div className="col-2 d-flex justify-content-end align-items-center">
												<FontAwesomeIcon icon={ faChevronRight } className="expand-chevron" />
											</div> : null
										}
									</div>
								</div>
								: <tbody>
									<tr id={ "user-" + user.userId } className={ "my-5 " + (expanded ? "" : " info-row-hidden") } >
										<th className="clickable text-center" onClick={ () => this.props.onRowClick(user.userId) } >{ expanded ? <FontAwesomeIcon icon={ faChevronDown } /> : <FontAwesomeIcon icon={ faChevronRight } /> }</th>
										<th className="clickable" onClick={ () => this.props.onRowClick(user.userId) } >
											<b>
												{ user.name }
												{ user.freeUser ? <span className="user-badge badge badge-secondary mx-5">FREE</span> : null }
												{ !user.freeUser && !user.friend && !user.admin ? <span className="user-badge badge mx-5">PAID</span> : null }
												{ user.friend ? <span className="user-badge badge badge-success mx-5">FRIEND</span> : null }
												{ user.admin ? <span className="user-badge badge badge-danger mx-5">ADMIN</span> : null }
											</b><br />
											{ user.email }<br />
											{ user.phoneNumber }
										</th>
										<td>
											{ user.userId }
										</td>
										<td>
											{ user.stripeCustomerId ? user.stripeCustomerId : "--" }
										</td>
									</tr>
									{
										expanded ?
										<tr id={ "user-" + user.userId + "-info-row" } className={ "info-row" + (expanded ? "" : " row-hidden") } >
											<td></td>
											<td colSpan={ 3 } >
												{
													user.subscriptions.length > 0 ?
													<>
														<div className="row">
															<div className="col-4">
																<b>Stripe Subscription ID</b>
															</div>
															<div className="col-2">
																<b>Product</b>
															</div>
															<div className="col-3">
																<b>Start Date</b>
															</div>
															<div className="col-3">
																<b>End Date</b>
															</div>
														</div>
														{
															user.subscriptions.map((subscription, i) => (
															<div key={ subscription.stripeSubscriptionId } className="row">
																<div className="col-4">
																	{ subscription.stripeSubscriptionId }
																</div>
																<div className="col-2">
																	{ subscription.planName }
																</div>
																<div className="col-3">
																	{ subscription.startDate.substring(0, 10) }
																</div>
																<div className="col-3">
																	{ subscription.endDate.substring(0, 10) }
																</div>
															</div>))
														}
													</> : "No active subscriptions"
												}
											</td>
										</tr> : null
									}
								</tbody>
							}
						</>
					)
				}
			</Translation>
		);
	}
}

UserRow.propTypes = {
	user: PropTypes.object.isRequired,
	expanded: PropTypes.bool.isRequired,
	onRowClick: PropTypes.func.isRequired,
	card: PropTypes.bool,
};

UserRow.defaultProps = {
	card: false,
};
