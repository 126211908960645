import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChartBar,
	faEllipsisH,
	// faGlasses,
	faLocationArrow,
	faUniversity,
} from '@fortawesome/free-solid-svg-icons';
import {
	faFile,
	faEye,
} from '@fortawesome/free-regular-svg-icons';
import {
	withRouter,
	Link,
} from "react-router-dom";
import { Translation } from 'react-i18next';

import ApiHelper from '../api/apiHelper';
import { withAuth0 } from '@auth0/auth0-react';
const apiHelper = new ApiHelper();

class NavBar extends React.Component
{
	constructor(props)
	{
		super(props);

		let currentMarket = localStorage.getItem("user-current-market");

		if (currentMarket) {
			currentMarket = JSON.parse(currentMarket);
		} else {
			currentMarket = {
				id: "HKEX",
				name: "🇭🇰 HK",
				icon: "🇭🇰",
				linkSuffix: "hk",
			};
		}

		this.props.history.listen((location, action) => {
			this.getCurrentMarket()
			this.getTabStatus();
		});

		this.state = {
			currentMarket: currentMarket,
			showDot: {
				finlist: false,
				trend: false,
				education: false,
			},
		};
	}

	async componentDidMount()
	{
		this.getTabStatus();
	}

	getCurrentMarket()
	{
		let currentMarket = localStorage.getItem("user-current-market");

		if (currentMarket) {
			currentMarket = JSON.parse(currentMarket);
		} else {
			currentMarket = {
				id: "HKEX",
				name: "🇭🇰 HK",
				icon: "🇭🇰",
				linkSuffix: "hk",
			};
		}

		this.setState({ currentMarket: currentMarket });
	}

	async getTabStatus()
	{
		let deviceId = localStorage.getItem("finetic-app-device-id");

		if (deviceId === null) return;

		if (this.props.auth0.isAuthenticated) {
			let [ finlistHK, finlistUS, finlistJP, finlistCN, finlistTW, trendHK, trendUS, trendJP, trendCN, trendTW, educationSundayClub, educationVideo ] = await Promise.all([
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"finlist/HKEX/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"finlist/US/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"finlist/JP/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"finlist/CN/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"finlist/TW/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"markettrend/HKEX/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"markettrend/US/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"markettrend/JP/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"markettrend/CN/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"markettrend/TW/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"education/sunday-club/last-updated",
					"GET"
				),
				apiHelper.request(
					this.props.auth0.getAccessTokenSilently,
					this.props.location,
					"education/videos/last-updated",
					"GET"
				),
			]);
	
			var pageLastUpdated = {
				finlist: {
					hk: (finlistHK.data && finlistHK.data !== "") ? new Date(finlistHK.data) : new Date("2021-09-01"),
					us: (finlistUS.data && finlistUS.data !== "") ? new Date(finlistUS.data) : new Date("2021-09-01"),
					jp: (finlistJP.data && finlistJP.data !== "") ? new Date(finlistJP.data) : new Date("2021-09-01"),
					cn: (finlistCN.data && finlistCN.data !== "") ? new Date(finlistCN.data) : new Date("2021-09-01"),
					tw: (finlistTW.data && finlistTW.data !== "") ? new Date(finlistTW.data) : new Date("2021-09-01"),
				},
				markettrend: {
					hk: (trendHK.data && trendHK.data !== "") ? new Date(trendHK.data) : new Date("2021-09-01"),
					us: (trendUS.data && trendUS.data !== "") ? new Date(trendUS.data) : new Date("2021-09-01"),
					jp: (trendJP.data && trendJP.data !== "") ? new Date(trendJP.data) : new Date("2021-09-01"),
					cn: (trendCN.data && trendCN.data !== "") ? new Date(trendCN.data) : new Date("2021-09-01"),
					tw: (trendTW.data && trendTW.data !== "") ? new Date(trendTW.data) : new Date("2021-09-01"),
				},
				education: {
					sundayClub: (educationSundayClub.data && educationSundayClub.data !== "") ? new Date(Number(educationSundayClub.data)) : new Date("2021-09-01"),
					videos: (educationVideo.data && educationVideo.data !== "") ? new Date(Number(educationVideo.data)) : new Date("2021-09-01"),
				},
			};
	
			let pageLastViewed = localStorage.getItem("page-last-viewed");
	
			if (pageLastViewed) {
				pageLastViewed = JSON.parse(pageLastViewed);
				pageLastViewed = {
					finlist: {
						hk: new Date(pageLastViewed.finlist.hk),
						us: new Date(pageLastViewed.finlist.us),
						jp: new Date(pageLastViewed.finlist.jp),
						cn: new Date(pageLastViewed.finlist.cn),
						tw: new Date(pageLastViewed.finlist.tw),
					},
					markettrend: {
						hk: new Date(pageLastViewed.markettrend.hk),
						us: new Date(pageLastViewed.markettrend.us),
						jp: new Date(pageLastViewed.markettrend.jp),
						cn: new Date(pageLastViewed.markettrend.cn),
						tw: new Date(pageLastViewed.markettrend.tw),
					},
					education: {
						sundayClub: new Date(pageLastViewed.education.sundayClub),
						videos: new Date(pageLastViewed.education.videos),
					},
				};
			}

			var showDot = this.state.showDot;
	
			var finlistHKUpdated = !isNaN(pageLastUpdated.finlist.hk) ? pageLastUpdated.finlist.hk > pageLastViewed.finlist.hk : false;
			var finlistUSUpdated = !isNaN(pageLastUpdated.finlist.us) ? pageLastUpdated.finlist.us > pageLastViewed.finlist.us : false;
			var finlistJPUpdated = !isNaN(pageLastUpdated.finlist.jp) ? pageLastUpdated.finlist.jp > pageLastViewed.finlist.jp : false;
			var finlistCNUpdated = !isNaN(pageLastUpdated.finlist.cn) ? pageLastUpdated.finlist.cn > pageLastViewed.finlist.cn : false;
			var finlistTWUpdated = !isNaN(pageLastUpdated.finlist.tw) ? pageLastUpdated.finlist.tw > pageLastViewed.finlist.tw : false;
	
			var markettrendHKUpdated = !isNaN(pageLastUpdated.markettrend.hk) ? pageLastUpdated.markettrend.hk > pageLastViewed.markettrend.hk : false;
			var markettrendUSUpdated = !isNaN(pageLastUpdated.markettrend.us) ? pageLastUpdated.markettrend.us > pageLastViewed.markettrend.us : false;
			var markettrendJPUpdated = !isNaN(pageLastUpdated.markettrend.jp) ? pageLastUpdated.markettrend.jp > pageLastViewed.markettrend.jp : false;
			var markettrendCNUpdated = !isNaN(pageLastUpdated.markettrend.cn) ? pageLastUpdated.markettrend.cn > pageLastViewed.markettrend.cn : false;
			var markettrendTWUpdated = !isNaN(pageLastUpdated.markettrend.tw) ? pageLastUpdated.markettrend.tw > pageLastViewed.markettrend.tw : false;
	
			var educationSundayClubUpdated = !isNaN(pageLastUpdated.education.sundayClub) ? pageLastUpdated.education.sundayClub > pageLastViewed.education.sundayClub : false;
			var educationVideosUpdated = !isNaN(pageLastUpdated.education.videos) ? pageLastUpdated.education.videos > pageLastViewed.education.videos : false;
	
			showDot.finlist = finlistHKUpdated || finlistUSUpdated || finlistJPUpdated || finlistCNUpdated || finlistTWUpdated;
			showDot.trend = markettrendHKUpdated || markettrendUSUpdated || markettrendJPUpdated || markettrendCNUpdated || markettrendTWUpdated;
			showDot.education = educationSundayClubUpdated || educationVideosUpdated;
	
			this.setState({ showDot: showDot });
		}
	}

	render()
	{
		return (
			<Translation>
				{
					(t) => (
						<nav id="finetic-navbar" className="navbar fixed-bottom d-lg-none">
							<div className="d-flex nav-bar-justify-content-between w-100">
								<Link to={ "finlist-" + this.state.currentMarket.linkSuffix } >
									<div className={ "tab" + (this.props.location.pathname.indexOf("/finlist") !== -1 || this.props.location.pathname === "/" ? " active" : "") } >
										<FontAwesomeIcon icon={ faFile } size="lg" fixedWidth />
										<span>{ t("finlist.title") }</span>
										{ this.state.showDot.finlist ? <div className="dot"></div> : null }
									</div>
								</Link>
								<Link to={ "markettrend-" + this.state.currentMarket.linkSuffix } >
									<div className={ "tab" + (this.props.location.pathname.indexOf("/markettrend") !== -1 ? " active" : "") } >
										<FontAwesomeIcon icon={ faLocationArrow } size="lg" fixedWidth />
										<span>{ t("markettrend.title-abbr") }</span>
										{ this.state.showDot.trend ? <div className="dot"></div> : null }
									</div>
								</Link>
								{/* <Link to="finder-hk">
									<div className={ "tab" + (this.props.location.pathname === "/finder-hk" ? " active" : "") } >
										<FontAwesomeIcon icon={ faGlasses } size="lg" fixedWidth />
										<span>{ t("finder.title") }</span>
									</div>
								</Link> */}
								<Link to="watchlist">
									<div className={ "tab" + (this.props.location.pathname === "/watchlist" ? " active" : "") } >
										<FontAwesomeIcon icon={ faEye } size="lg" fixedWidth />
										<span>{ t("watchlist.title") }</span>
									</div>
								</Link>
								<Link to="education">
									<div className={ "tab" + (this.props.location.pathname === "/education" ? " active" : "") } >
										<FontAwesomeIcon icon={ faUniversity } size="lg" fixedWidth />
										<span>{ t("education.title-abbr") }</span>
										{ this.state.showDot.education ? <div className="dot"></div> : null }
									</div>
								</Link>
								<Link to="tools">
									<div className={ "tab" + (this.props.location.pathname === "/tools" ? " active" : "") } >
										<FontAwesomeIcon icon={ faEllipsisH } size="lg" fixedWidth />
										<span>{ t("more-tools-abbr") }</span>
									</div>
								</Link>
							</div>
						</nav>
					)
				}
			</Translation>
		);
	}
};

export default withAuth0(withRouter(NavBar));
