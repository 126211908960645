import withTracker from './withTracker';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';

import ProtectedContent from './ProtectedContent';

const ProtectedRoute = ({ component, path, ...args }) => (
	<Route
		path={ path }
		render={ (props) => {
			let Component = withAuthenticationRequired(ProtectedContent, {
				// If using a Hash Router, you need to pass the hash fragment as `returnTo`
				// returnTo: () => window.location.hash.substr(1),
			});

			Component = withTracker(Component);

			let Content = component;

			return <Component { ...props } ><Content /></Component>
		}}
		{ ...args }
	/>
);

export default ProtectedRoute;
