import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import { Modal as BootstrapModal } from 'react-bootstrap';

import $ from "jquery";

const renderButton = (type, submitFormId, submitAction, submitButtonDisabled, onDismiss) => {
	switch (type) {
		case "form":
			return (
				<Button variant="finetic-blue" form={ submitFormId } disabled={ submitButtonDisabled } type="submit">
					{ submitAction }
				</Button>
			);
		default:
			return (
				<Button variant="finetic-blue" onClick={ onDismiss } >
					OK
				</Button>
			);
	}
};

const Modal = ({ title, children, type, submitFormId, submitAction, submitButtonDisabled, centered = false, show, onDismiss, hideFooterButton, ...props }) => {
	return (
		<BootstrapModal
			show={ show }
			onHide={ onDismiss }
			centered={ centered }
			{ ...props }
		>
			<BootstrapModal.Header closeButton>
				<BootstrapModal.Title>{ title }</BootstrapModal.Title>
			</BootstrapModal.Header>
			<BootstrapModal.Body>{ children }</BootstrapModal.Body>
			{
				!hideFooterButton ?
				<BootstrapModal.Footer>
					{ renderButton(type, submitFormId, submitAction,submitButtonDisabled,  onDismiss) }
				</BootstrapModal.Footer> : null
			}
		</BootstrapModal>
	);
}

export default Modal;

// export default class Modal extends React.PureComponent
// {
// 	constructor(props)
// 	{
// 		super(props);

// 		this.renderButtons = this.renderButtons.bind(this);
// 	}

// 	renderButtons(type)
// 	{
// 		const { buttonClass } = this.props;
// 		switch (type) {
// 			case "user":
// 				const { submitFormId, submitAction, submitButtonDisabled } = this.props;

// 				return (
// 					<Button buttonType="blue" isBlock role="button" form={ submitFormId } type="submit" disabled={ submitButtonDisabled } className={ buttonClass } >{ submitAction }</Button>
// 				)
// 			case "popup":
// 				const { noAction, yesAction } = this.props;

// 				return (
// 					<>
// 						<Button isLink className={ "mx-5 modal-popup-button modal-cancel " + buttonClass } data-dismiss="modal" role="button">{ noAction }</Button>
// 						<Button isLink className={ "mx-5 modal-popup-button " + buttonClass } data-dismiss="modal" role="button" onClick={ this.props.onSubmit } >{ yesAction }</Button>
// 					</>
// 				);
// 			case "alert":
// 				return (
// 					<Button buttonType="white" isLink className={ "mx-5 modal-popup-button modal-cancel " + buttonClass } data-dismiss="modal" role="button">OK</Button>
// 				);
// 			default:
// 				return null;
// 		}
// 	}

// 	render()
// 	{
// 		const { type, id, title, image, imageAlt, children, hideButton, noBlurGlass, className, ...props } = this.props;

// 		return (
// 			<div className={ "modal fade" + (type === "fullscreen" ? " modal-full" : "") } id={ id } tabIndex="-1" role="dialog" { ...props } >
// 				<div className="modal-dialog" role="document">
// 					<div className={ "modal-content" + (type === "alert" ? " alert-modal" : "") + (noBlurGlass ? " modal-no-blur-glass" : "") + " " + className } >
// 						<div className="modal-header d-flex justify-content-between">
// 							<h1 className="modal-title text-center">{ title }</h1>
// 							<span className="close modal-cancel" data-dismiss="modal" aria-label="Close">
// 								<span aria-hidden="true">&times;</span>
// 							</span>
// 						</div>
// 						{
// 							type === "fullscreen" ?
// 							children :
// 							<>
// 								{
// 									image ?
// 									<div className="text-center">
// 										<img className="modal-img" src={ image } alt={ imageAlt } />
// 									</div> : null
// 								}
// 								<div className={ type === "user" ? "modal-body" : "" } >
// 									{ children }
// 								</div>
// 								{
// 									hideButton ? null :
// 									<div className="d-flex text-center mt-20">
// 										{ this.renderButtons(type) }
// 									</div>
// 								}
// 							</>
// 						}
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// }

// Modal.propTypes = {
// 	type: PropTypes.oneOf([ "user", "popup", "alert", "fullscreen" ]).isRequired,
// 	id: PropTypes.string.isRequired,
// 	title: PropTypes.string.isRequired,
// 	image: PropTypes.string,
// 	imageAlt: PropTypes.string,
// 	submitFormId: PropTypes.string,
// 	onSubmit: PropTypes.func,
// 	submitAction: PropTypes.string,
// 	noAction: PropTypes.string,
// 	yesAction: PropTypes.string,
// 	submitButtonDisabled: PropTypes.bool,
// 	buttonClass: PropTypes.string,
// 	noBlurGlass: PropTypes.bool,
// 	className: PropTypes.string,
// };

// Modal.defaultProps = {
// 	imageAlt: "",
// 	submitFormId: "",
// 	onSubmit: () => { },
// 	submitAction: "",
// 	noAction: "",
// 	yesAction: "",
// 	submitButtonDisabled: false,
// 	buttonClass: "",
// 	noBlurGlass: false,
// 	className: "",
// };
