import { useEffect } from 'react';

import FinList from '../FinList';

import { Helmet } from "react-helmet";

const FinListHK = () => {
	useEffect(() => {
		let pageLastViewed = localStorage.getItem("page-last-viewed");
		pageLastViewed = JSON.parse(pageLastViewed);
		pageLastViewed.finlist.hk = new Date().getTime();
		localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
	}, [ ]);

	return (
		<>
			<Helmet>
				<title>FinList (HK) - Finetic App</title>
			</Helmet>
			<FinList exchange="HKEX" />
		</>
	);
};

export default FinListHK;
