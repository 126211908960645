import { useEffect } from 'react';

import FinList from '../FinList';

import { Helmet } from "react-helmet";

const FinListCN = () => {
	useEffect(() => {
		let pageLastViewed = localStorage.getItem("page-last-viewed");
		pageLastViewed = JSON.parse(pageLastViewed);
		pageLastViewed.finlist.cn = new Date().getTime();
		localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
	}, [ ]);

	return (
		<>
			<Helmet>
				<title>FinList (CN) - Finetic App</title>
			</Helmet>
			<FinList exchange="CN" />
		</>
	);
};

export default FinListCN;
