import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import ShortTermTrend from "./ShortTermTrend";
import MidLongTermTrend from "./MidLongTermTrend";
import MarketMovement from "./MarketMovement";

import TVChartContainer from "../TVChartContainer";

import Card from 'react-bootstrap/Card';

import { formatDate } from "../../util/dateUtil";

const MarketSnapshot = ({ exchange, marketTrendData, loading }) => {
	const [ marketData, setMarketData ] = useState({
		shortTermTrend: "",
		shortTermTrendStartDate: "",
		midLongTermTrend: "",
		midLongTermTrendStartDate: "",
		marketMovementData: [ ],
		tenWeekMAData: [ ],
		fortyWeekMAData: [ ],
		fiftyTwoWeekRecordData: [ ],
	});
	

	const { t } = useTranslation();
	const { user, getAccessTokenSilently } = useAuth0();

	const getIndexSymbol = () => {
		switch (exchange) {
			case "HKEX":
				return "HKEX:HSI";
			case "US":
				return "US:US500";
			case "JP":
				return "JP:N225";
			case "CN":
				return "CN:CSI300";
			case "TW":
				return "TW:TAIEX";
			default:
				return "HKEX:HSI";
		}
	};

	useEffect(() => {
		if (marketTrendData != null) {
			var marketMovementData = [
				{
					key: t("markettrend.data.stock-down"),
					variant: "danger",
					percent: marketTrendData.downPercentage,
					percentTotal: marketTrendData.downPercentage,
					value: marketTrendData.down,
				},
				{
					key: t("markettrend.data.stock-no-change"),
					variant: "finetic-yellow",
					percent: marketTrendData.noChangePercentage,
					percentTotal: marketTrendData.noChangePercentage,
					value: marketTrendData.noChange,
				},
				{
					key: t("markettrend.data.stock-up"),
					variant: "positive",
					percent: marketTrendData.upPercentage,
					percentTotal: marketTrendData.upPercentage,
					value: marketTrendData.up,
				}
			];

			var tenWeekMAData = [
				{
					key: t("markettrend.data.under-ma"),
					variant: "danger",
					percent: marketTrendData.underTenWeekMaPercentage,
					percentTotal: marketTrendData.underTenWeekMaPercentageTotal,
					value: marketTrendData.underTenWeekMa,
				},
				{
					key: t("markettrend.data.on-ma"),
					variant: "positive",
					percent: marketTrendData.onTenWeekMaPercentage,
					percentTotal: marketTrendData.onTenWeekMaPercentageTotal,
					value: marketTrendData.onTenWeekMa,
				}
			];

			var fortyWeekMAData = [
				{
					key: t("markettrend.data.under-ma"),
					variant: "danger",
					percent: marketTrendData.underFortyWeekMaPercentage,
					percentTotal: marketTrendData.underFortyWeekMaPercentageTotal,
					value: marketTrendData.underFortyWeekMa,
				},
				{
					key: t("markettrend.data.on-ma"),
					variant: "positive",
					percent: marketTrendData.onFortyWeekMaPercentage,
					percentTotal: marketTrendData.onFortyWeekMaPercentageTotal,
					value: marketTrendData.onFortyWeekMa,
				}
			];

			var fiftyTwoWeekRecordData = [
				{
					key: t("markettrend.data.52-week-low"),
					variant: "danger",
					percent: marketTrendData.fiftyTwoWeekLowPercentage,
					percentTotal: marketTrendData.fiftyTwoWeekLowPercentageTotal,
					value: marketTrendData.fiftyTwoWeekLow,
				},
				{
					key: t("markettrend.data.52-week-high"),
					variant: "positive",
					percent: marketTrendData.fiftyTwoWeekHighPercentage,
					percentTotal: marketTrendData.fiftyTwoWeekHighPercentageTotal,
					value: marketTrendData.fiftyTwoWeekHigh,
				}
			];

			marketData.shortTermTrend = marketTrendData.shortTermMarketTrend;
			marketData.shortTermTrendStartDate = marketTrendData.shortTermMarketTrendStartDate;
			marketData.midLongTermTrend = marketTrendData.midLongTermMarketTrend;
			marketData.midLongTermTrendStartDate = marketTrendData.midLongTermMarketTrendStartDate;
			marketData.marketMovementData = marketMovementData;
			marketData.tenWeekMAData = tenWeekMAData;
			marketData.fortyWeekMAData = fortyWeekMAData;
			marketData.fiftyTwoWeekRecordData = fiftyTwoWeekRecordData;

			setMarketData(marketData);
		}
	}, [ marketTrendData]);

	return (
		<>
			<div className="row">
				<div className="col-12 col-xxl-10">
					<div className="d-flex flex-column flex-lg-row mb-2">
						<div className="d-flex flex-column me-lg-2 trends">
							<ShortTermTrend trend={ marketData.shortTermTrend } trendStartDate={ marketData.shortTermTrendStartDate } loading={ loading } />
							<MidLongTermTrend trend={ marketData.midLongTermTrend } trendStartDate={ marketData.midLongTermTrendStartDate } loading={ loading } />
						</div>

						<Card className="shadow-sm snapshot-card">
							<Card.Body>
								<MarketMovement title={ t("markettrend.data.market-movement") } data={ marketData.marketMovementData } loading={ loading } />
								<MarketMovement title={ t("markettrend.data.10-week-ma") } data={ marketData.tenWeekMAData } loading={ loading } />
								<MarketMovement title={ t("markettrend.data.40-week-ma") } data={ marketData.fortyWeekMAData } loading={ loading } />
								<MarketMovement title={ t("markettrend.data.52-week-record") } data={ marketData.fiftyTwoWeekRecordData } loading={ loading } />

								{
									!loading ?
										<span className="trend-since">{ t("markettrend.information-as-at", { date: formatDate(marketTrendData.issueDate) }) }</span> : null
								}
							</Card.Body>
						</Card>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-xxl-10">
					<Card className="p-0 shadow-sm chart-container">
						<Card.Body className="p-0">
							<TVChartContainer
								symbol={ getIndexSymbol() }
								containerId="tv_chart_container"
								autosize={ true }
								className="market-trend-chart"
								disabledFeatures={[
									"header_widget",
									"left_toolbar",
									"control_bar",
									"timeframes_toolbar",
								]}
								getAccessTokenSilently={ getAccessTokenSilently }
								userId={ user.sub }
								hideRSLine
							/>
						</Card.Body>
					</Card>
				</div>
			</div>
		</>
	)
};

export default MarketSnapshot;
