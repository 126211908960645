import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

import Button from './Button';

import { ToastContainer, Toast as ToastBootstrap } from 'react-bootstrap';

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

function Toast({ messageId, message, onDismissSystemMessage })
{
	const { i18n, t } = useTranslation();

	return (
		<ToastBootstrap id={ "system-message-" + messageId } className="toast" role="alert" aria-live="assertive" aria-atomic="true" show={ true }>
			<ToastBootstrap.Header closeButton={ false } >
				<strong className="me-auto">{ t("system-message.title") }</strong>
			</ToastBootstrap.Header>
			<ToastBootstrap.Body>
				{ i18n.languages[0] == "en" ? message.en : message.zh }
				<div className="d-flex justify-content-end">
					<Button buttonType="blue" className="close" data-dismiss="alert" aria-label={ t("util.toast-got-it") } onClick={ () => onDismissSystemMessage(messageId) }>{ t("util.toast-got-it") }</Button>
				</div>
			</ToastBootstrap.Body>
		</ToastBootstrap>
	);
}

function SystemToastsWrapper()
{
	const [ taskId, setTaskId ] = useState(-1);
	const [ toasts, setToasts ] = useState([ ]);
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();

	const getSystemToasts = async () => {
		let deviceId = localStorage.getItem("finetic-app-device-id");

		if (deviceId === null) return;

		let toastsResponse = await Promise.all([
			apiHelper.request(
				getAccessTokenSilently,
				{ pathname: "/" },
				"status/notifications",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				{ pathname: "/" },
				"status/notifications/PAID",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				{ pathname: "/" },
				"status/notifications/SUNDAY_CLUB",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				{ pathname: "/" },
				"status/notifications/HKEX",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				{ pathname: "/" },
				"status/notifications/US",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				{ pathname: "/" },
				"status/notifications/CN",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				{ pathname: "/" },
				"status/notifications/TW",
				"GET"
			),
		]);
	
		var toastsAll = toastsResponse.filter(toast => (toast.status === "success"));
		var toastToAdd = [ ];
		toastsAll.forEach(toast => (toastToAdd = toastToAdd.concat(toast.data)));
	
		var systemToastHistory = localStorage.getItem("system-toasts-history");
	
		if (systemToastHistory) {
			systemToastHistory = JSON.parse(systemToastHistory);
	
			toastToAdd = toastToAdd.filter(toast => (systemToastHistory.indexOf(toast.systemMessageId) === -1));
		}
	
		setToasts(toastToAdd);
	};

	useEffect(async () => {
		if (isAuthenticated) {
			getSystemToasts(getAccessTokenSilently, setToasts);

			var toastTaskId = setInterval(() => {
				getSystemToasts(getAccessTokenSilently, setToasts);
			}, 1000 * 60 * 5);
	
			setTaskId(toastTaskId);
		}
	}, [ isAuthenticated ]);

	const onDismissSystemMessage = (systemMessageId) => {
		var systemToastHistory = localStorage.getItem("system-toasts-history");
	
		if (systemToastHistory) {
			systemToastHistory = JSON.parse(systemToastHistory);
		} else {
			systemToastHistory = [ ];
		}
	
		systemToastHistory.push(systemMessageId);
	
		localStorage.setItem("system-toasts-history", JSON.stringify(systemToastHistory));

		var newToasts = toasts.filter(toast => (systemToastHistory.indexOf(toast.systemMessageId) === -1));

		setToasts(newToasts);
	}

	if (toasts.length > 0) {
		return (
			<ToastContainer className="p-3" position="top-end" >
				{
					toasts.map(toast => <Toast messageId={ toast.systemMessageId } message={ toast.message } onDismissSystemMessage={ onDismissSystemMessage } />)
				}
			</ToastContainer>
		);
	} else {
		return null;
	}
}

export default SystemToastsWrapper;
