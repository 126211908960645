export default class ApiHelper
{
	async request(getAccessTokenSilently, location, path, method = "POST", header = { }, body = JSON.stringify({ }))
	{
		let deviceId = localStorage.getItem("finetic-app-device-id");
		let deviceKey = localStorage.getItem("finetic-app-device-key");
		let isIncognito = localStorage.getItem("finetic-app-device-incognito");
		let deviceData = localStorage.getItem("finetic-app-device-data");
		deviceData = JSON.parse(deviceData);

		var device = "";
		var browserName = "";
		var browserVersion = "";
		var location = "";
		var latitude = -1;
		var longitude = -1;
		var timezone = "";

		if (deviceData) {
			device = deviceData.device;
			browserName = deviceData.browserName;
			browserVersion = deviceData.browserVersion;

			if (deviceData.ipLocation) {
				if (deviceData.ipLocation.city) {
					location = deviceData.ipLocation.city.name + ", " + deviceData.ipLocation.country.name;
				}
	
				latitude = deviceData.ipLocation.latitude;
				longitude = deviceData.ipLocation.longitude;
				timezone = deviceData.ipLocation.timezone;
			}
		}

		var accessToken = await getAccessTokenSilently({
			audience: process.env.REACT_APP_FINETIC_API_URL
		});

		var requestUrl = process.env.REACT_APP_FINETIC_API_URL + path;

		var checkRequest = await fetch(process.env.REACT_APP_FINETIC_API_URL + "check", {
			method: "GET",
			headers: {
				Authorization: "Bearer " + accessToken,
				'Request-Path': window.location.pathname,
				'Target-Path': requestUrl,
				'Device-Id': deviceId,
				'Device-Key': deviceKey,
				'Device': device,
				'Device-BrowserName': browserName,
				'Device-BrowserVersion': browserVersion,
				'Device-Incognito': isIncognito,
				'Device-Location': location,
				'Device-Latitude': latitude,
				'Device-Longitude': longitude,
				'Device-Timezone': timezone,
			}
		});

		if (checkRequest.status === 200) {
			var checkResponse = await checkRequest.json();

			if (!checkResponse.data.status) {
				return { status: "Forbidden" };
			}
		}

		var options = {
			method: method,
			headers: {
				Authorization: "Bearer " + accessToken,
				'Request-Path': window.location.pathname,
				'Device-Id': deviceId,
				'Device-Key': deviceKey,
				'Device': device,
				'Device-BrowserName': browserName,
				'Device-BrowserVersion': browserVersion,
				'Device-Incognito': isIncognito,
				'Device-Location': location,
				'Device-Latitude': latitude,
				'Device-Longitude': longitude,
				'Device-Timezone': timezone,
				...header,
			},
		};

		if (method === "POST") {
			options = {
				...options,
				body: body,
			};
		}

		var request = await fetch(requestUrl, options);
		
		if (request.status === 200) {
			var response = await request.json();

			return response;
		} else if (request.status === 403) {
			return { status: "Forbidden" };
		} else {
			var response = await request.json();
			
			return { status: "Error", errors: response.errors };
		}
	}
}
