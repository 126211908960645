import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loading from "../Loading";

import {
	Header,
	Forbidden,
	Error,
	Section,
} from "../../components";

import { formatDate } from '../../util/dateUtil';

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const Affiliate = () => {
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	const [ affiliateUserDetail, setAffiliateUserDetail ] = useState(null);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t } = useTranslation();

	const getAffiliateUserDetail = async () => {
		try {
			var affiliateInfo = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"affiliate",
				"GET"
			);
	
			if (affiliateInfo.status === "Forbidden") {
				setForbidden(true);
				return;
			}
	
			if (affiliateInfo.status === "Error") {
				setError(true);
				return;
			}
	
			setAffiliateUserDetail(affiliateInfo.data);
		} catch (e) {
			console.log(e.message);
		}
	};

	useEffect(() => {
		getAffiliateUserDetail();
	}, [ ]);

	if (forbidden) {
		return (
			<>
				<Forbidden customMessage={ t("affiliate.no-info")} noButton />
			</>
		);
	}

	if (error) {
		return (
			<>
				<Error />
			</>
		);
	}

	if (affiliateUserDetail === null) {
		return null;
	}

	return (
		<>
			<Section sectionClass="mt-20">
				<div className="col-12 col-md-4">
					<div className="d-flex flex-column align-items-center">
						<span>{ t("affiliate.data.coupon-code") }</span>
						<b className="affiliate-stat">{ affiliateUserDetail.code }</b>
					</div>
				</div>
				<div className="col-12">
					{
						affiliateUserDetail.salesRecord.map(affiliateInfo => (
							<div className="row">
								<div className="col-12">
									<hr className="mx-10" />
								</div>
								<div className="col-12 mx-10">
									<b>{ formatDate(affiliateInfo.month.substring(0, 7)) }</b>
								</div>
								<div className="col-4">
									<div className="d-flex flex-column align-items-center">
										<span>{ t("affiliate.data.plans-sold") }</span>
										<b className="affiliate-stat">{ affiliateInfo.plansSold }</b>
									</div>
								</div>
								<div className="col-8 col-md-4">
									<div className="d-flex flex-column align-items-center">
										<span>{ t("affiliate.data.generated-revenue") }</span>
										<b className="affiliate-stat">${ affiliateInfo.income.toFixed(2) }</b>
									</div>
								</div>
							</div>
						))
					}
				</div>
			</Section>
		</>
	);
};

export default Affiliate;