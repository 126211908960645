import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { formatDate, formatTime } from "../../util/dateUtil";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const ProfitWarningNews = () => {
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState(true);

	const [ news, setNews ] = useState([ ]);

	const [ showPositiveProfitAlertOnly, setShowPositiveProfitAlertOnly ] = useState(false);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { i18n, t } = useTranslation();

	const toggleShowPositiveReportsOnly = () => { setShowPositiveProfitAlertOnly(!showPositiveProfitAlertOnly) };

	const getProfitWarningNews = async () => {
		try {
			var profitWarningNewsResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"stock-news/profit-warning",
				"GET"
			);

			var profitWarningFinListResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"stock-news/profit-warning/finlist",
				"GET"
			);

			var profitWarningNews = profitWarningNewsResponse.data;

			if (profitWarningFinListResponse.status !== "Forbidden") {
				profitWarningNews = profitWarningNewsResponse.data.map(stockNewsList => {
					var stockList = stockNewsList.stockList.map(stockNews => {
						var filtered = profitWarningFinListResponse.data.filter(finlistStock => stockNews.stockCode === finlistStock);
						return { ...stockNews, inFinList: filtered.length > 0 };
					});

					return { ...stockNewsList, stockList: stockList };
				});
			}

			setNews(profitWarningNews);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			setError(true);
		}
	};

	useEffect(() => {
		getProfitWarningNews();
	}, [ ]);

	return (
		<>
			<div className="row stock-news">
				<div className="col-12 col-lg-10 col-xxl-8">
					<div className="row">
						<div className="col-12 col-lg-5">
							{
								loading ?
									<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
										<Skeleton count={ 10 } height="4em" className="my-1" />
									</SkeletonTheme> :
									<>
										<Card className="my-2">
											<Card.Body>
												<div className="form-check form-switch">
													<input className="form-check-input" type="checkbox" role="switch" id="showPositiveReportsOnly" defaultChecked={ showPositiveProfitAlertOnly } onChange={ toggleShowPositiveReportsOnly } />
													<label className="form-check-label" for="showPositiveReportsOnly">{ t("stock-news.show-positive-reports-only") }</label>
												</div>
											</Card.Body>
										</Card>
										<Card className="my-2">
										{
											news.filter((item) => !showPositiveProfitAlertOnly || (showPositiveProfitAlertOnly && item.stockList.filter((stock) => stock.reportType == "POSITIVE").length > 0)).map((item) => (
												<>
													<Card.Header className="sticky-top bg-light">
														<b>{ formatDate(item.date) }</b>
													</Card.Header>
													<ListGroup variant="flush">
														{
															item.stockList.filter((item) => !showPositiveProfitAlertOnly || showPositiveProfitAlertOnly && item.reportType == "POSITIVE").map((stock, i) => (
																<ListGroup.Item action active={ false } href={ i18n.language === "zh" && stock.linkZh != "" ? stock.linkZh : stock.link } target="_blank" className={ stock.inFinList ? "stock-news-in-finlist" : "" } >
																	<div className="d-flex justify-content-between align-items-center">
																		<div className="d-flex align-items-center">
																			<div className={ "stock-news-indicator align-items-center me-3 " + (stock.reportType == "POSITIVE" ? "positive" : "negative") } ></div>
																			<div className="d-flex flex-column">
																				<b>{ stock.stockCode }</b>
																				{ i18n.language === "zh" && stock.stockNameZh != "" ? stock.stockNameZh : stock.stockName }
																			</div>
																		</div>
																		<div className="d-flex align-items-center">
																			<span className="text-muted">{ formatTime(new Date(stock.releaseTime), true) }</span>
																			<FontAwesomeIcon icon={ faChevronRight } className="ms-2" />
																		</div>
																	</div>
																</ListGroup.Item>
															))
														}
													</ListGroup>
												</>
											))
										}
										</Card>
									</>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfitWarningNews;
