import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import {
	Subscriptions,
	Invoices,
	Preferences,
} from '../../components/user';

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { Helmet } from "react-helmet";

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const tabs = [ "SUBSCRIPTIONS", "INVOICES", "PREFERENCES" ];

const Dashboard = () => {
	// General Page States
	const [ loading, setLoading ] = useState(true);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	const [ user, setUser ] = useState({ });
	const [ subscriptions, setSubscriptions ] = useState([ ]);
	const [ invoices, setInvoices ] = useState([ ]);
	const [ cardDetails, setCardDetails ] = useState(null);
	const [ credit, setCredit ] = useState(0);

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t } = useTranslation();

	const getCurrentUser = async () => {
		try {
			var userResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/user",
				"GET"
			);

			if (userResponse.status === "success") {
				var user = userResponse.data;

				setUser(user);
				setError(false);
			} else {
				console.log(userResponse.errors[0].message);
				setError(true);
			}
		} catch (e) {
			console.log(e.message);
			setError(true);
		}
	};

	const getUserSubscriptionDetails = async () => {
		setLoading(true);

		try {
			var userResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/user/subscriptions",
				"GET"
			);

			if (userResponse.status === "success") {
				var data = userResponse.data;
				setCardDetails(data.cardDetails);
				setSubscriptions(data.subscriptions);
				setInvoices(data.invoices);
				setCredit(data.credit);

				setError(false);
			} else {
				console.log(userResponse.errors[0].message);
				setError(true);
			}

			setLoading(false);
		} catch (e) {
			console.log(e.message);
			setError(true);
		}
	}

	useEffect(() => {
		getCurrentUser();
		getUserSubscriptionDetails();
	}, [ ]);

	return (
		<>
			<Helmet>
				<title>Profile - Finetic App</title>
			</Helmet>

			<div className="row">
				<div className="col-12 px-0 overflow-x-hidden">
					<Tabs defaultActiveKey={ tabs[0] } className="mb-3" mountOnEnter>
						<Tab eventKey={ tabs[0] } title={ t("user.subscriptions") } className="px-2">
							<Subscriptions subscriptions={ subscriptions } cardDetails={ cardDetails } credit={ credit } loading={ loading } getSubscriptions={ getUserSubscriptionDetails } />
						</Tab>
						<Tab eventKey={ tabs[1] } title={ t("user.receipts") } className="px-2">
							<Invoices invoices={ invoices } loading={ loading } />
						</Tab>
						<Tab eventKey={ tabs[2] } title={ t("user.preferences.title") } className="px-2">
							<Preferences user={ user } getUser={ getCurrentUser } />
						</Tab>
					</Tabs>
				</div>
			</div>
		</>
	);
}

export default Dashboard;
