import { Trans, useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const TutorialModal = ({ show, toggle }) => {
	const { t } = useTranslation();

	return (
		<Modal show={ show } onHide={ toggle } centered>
			<Modal.Header closeButton>
				<Modal.Title>{ t("game.tutorial.title") }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ol>
					<li><Trans i18nKey="game.tutorial.li1" /></li>
					<li><Trans i18nKey="game.tutorial.li2" /></li>
					<li><Trans i18nKey="game.tutorial.li3" /></li>
					<li><Trans i18nKey="game.tutorial.li4" /></li>
					<li><Trans i18nKey="game.tutorial.li5" /></li>
					<li><Trans i18nKey="game.tutorial.li6" /></li>
					<li><Trans i18nKey="game.tutorial.li7" /></li>
				</ol>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="finetic-blue" onClick={ toggle } >OK</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default TutorialModal;
